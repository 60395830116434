<template>
    <basic-header
        pretitle="Adminstration"
        title="Products"
        buttonText="Add Product"
        @btnClick="showModal({ component: 'addProduct'})"
    />
    <div class="container-fluid">
        <div class="card">
            <Datatable
                tableId="productTable"
                :columns="columns"
                uniqueField ="product_id"
                :data="$store.state.admin.products"
                @deleteRows="showDeleteProducts"
                :deletable="true"
                :compact="true"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:'products/', name: 'Product', identifier:'product_id'}"
            />
        </div>
    </div>
</template>
<script>
import Datatable from '../../components/Datatable.vue';
import BasicHeader from '../../components/BasicHeader.vue';
import { mapActions } from 'vuex';
export default{
    name: "Products",
    components:{ BasicHeader, Datatable},
    data(){
        return {
            columns:[
                { data: "sku"},
                { data: "name"},
                { data: "duration"}
            ]
        };
    },
    beforeMount(){
        this.$store.dispatch("admin/fetchProducts");
    },
    methods: {
        showDeleteProducts(rows){
            this.showModal({ component: "deleteProducts", data:rows });
        },
        ...mapActions({
            showModal:"showModal"
        })
    }
}

</script>
<style></style>