function getDeploymentStatusFields() { 
    return[
        {
           
            name: "status",
            label: "Status",
            type: "text",
            editable: true
        },

        {
           
            name: "version",
            label: "Version",
            type: "text",
            editable: true
        },

        {
           
            name: "activatedAt",
            label: "Activated At",
            type: "text",
            editable: true,
            format:"datetime"
        },

        {
           
            name: "activatedBy",
            label: "Activated By",
            type: "text",
            editable: true
        }
    ]

}

export default getDeploymentStatusFields;