<template>
    <basic-header
        pretitle="aqfer Universal Tag"
        title="Tag Template Library"
        buttonText="Quick Add Tag Template"
        :buttonRoles= "btnRoles"
        @btnClick="showModal({ component: 'addTagTemplate' })"
        buttonText2="Tag Template Wizard"
        @btnClick2="showModal({ component: 'addTagTemplateWizard' })"
        buttonClass2="btn-primary"
        :button2Roles="btnRoles"

    />

    <div class="container-fluid">
        <div class="card">
            <datatable
                tableId="tagList"
                :columns="columns"
                :formatColumn="formatColumn"
                :data="$store.state.aut.tagTemplates"
                @deleteRows="showDeleteTagTemplates"
                :deletable="deletable"
                :compact="true"
                :selectCheckboxes="true"
                :multiselectColumn="multiselectColumn" 
                :detailColumn="{baseUrl:'tag-templates/', name:'Tag Template', identifier:'id'}"
            />
        </div> 
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
    name: "Tags",
    components: { BasicHeader, Datatable },
    data() {
        return {
            columns: [
                { data: "name" },
                { data: "event_type", title: "Event Type" },
                { data: "initiator" },
                { data: "tag_type", title: "Tag Type" },
                { data: "tag", title: "Tags" },
                { data: "owner" },
                { data: "modified_at", title: "Updated" },
            ],
            btnRoles: [1,3],
            formatColumn:[
                {
                    index:8,
                    type:"datetime"
                }
            ],
            multiselectColumn:[ 6 ]
        };
    },
    beforeMount() {
        this.$store.dispatch("aut/fetchTagTemplates");
    },
    methods: {
        showDeleteTagTemplates(rows) {
            this.showModal({ component: "deleteTagTemplates", data: rows });
        },
        ...mapActions({
            showModal: "showModal",
        }),
    },
    computed: {
        deletable() {
            const allowedRoles = [1,3];
            return  (allowedRoles.includes(this.$store.getters.userRoles.userRoleRank));
        }
    }
    
};
</script>

<style scoped>
</style>