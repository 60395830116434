<template>
    <BasicHeader pretitle="aqfer Marketing Data Platform" title="Admin Named Queries" />
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header text-center">
                        <span v-if="!runQuery">
                            <span class="custom-control form-switch me-3" style="display: inline-block">
                                <input type="checkbox" class="form-check-input" v-model="cache" id="cacheSwitch">
                                <label style="padding-left:5px" class="form-check-label" for="cacheSwitch">Cache</label>
                            </span>&nbsp;
                            <button :disabled="runQueryState" type="button" @click="onRunQuery"
                                class="btn btn-primary"><i class='fa fa-play' />&nbsp;Run Query
                            </button>
                        </span>
                        <span v-else>
                            <button @click="onStopClick" class="btn btn-danger"> Stop </button>
                        </span>
                        <span class="p-2">
                            <button type="button" @click="onClickClientView" class="btn btn-primary"
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Switch to client view"><i
                                    class='fa fa-user' />
                            </button>&nbsp;
                        </span>
                    </div>
                    <div class="card-body p-0">
                        <CodemirrorEditor class="codeMirrorHeight" key="adminQuery" editorId="adminQuery" mode="sql"
                            v-model="query" :readOnly="false">
                        </CodemirrorEditor>
                    </div>
                </div>
                <div v-if="error" class="alert alert-danger" role="alert">
                    {{ error }}
                </div>
                <div v-if="showOutput">
                    <div class="form-group row">
                        <label for="output" class="col-sm-8 col-form-label">Output</label>
                        <div class="col-sm-4 text-end">
                            <button class="btn btn-primary" @click="exportCSV()">
                                Export CSV
                            </button>&nbsp;
                            <button type="button" v-if="isPartialResult" @click="exportFullData()" class="btn btn-primary">
                                Export Full Result
                            </button>
                        </div>
                    </div>
                    <div class="card">
                        <Datatable tableId="adminOutputTable" :key="outputLoading" :columns="outputColumns"
                            :data="outputData" :ignoreCache="true" :compact="true" :showLoading="outputLoading" />
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import BasicHeader from '../../components/BasicHeader.vue';
import Datatable from "../../components/Datatable.vue";
import CodemirrorEditor from "../../components/CodemirrorEditor.vue";
import axios from 'axios';
import {mapActions} from 'vuex';
import csv from "../../utils/tools/csv"
export default {
    components: { BasicHeader, Datatable, CodemirrorEditor },
    name: "AdminQueries",
    data() {
        return {
            error: null,
            runQuery: false,
            outputColumns: [""],
            outputData: [],
            showOutput: false,
            outputLoading: false,
            cache: true,
            query: "",
            isPartialResult:null,
            query_id:null
        };
    },
    methods: {
        ...mapActions({
            fetchFullQueryFile: "amdp/fetchFullQueryFile",
            stopQueryExecutions: "amdp/stopQueryExecutions"
        }),
        async onRunQuery() {
            this.outputLoading = true;
            this.runQuery = true;
            let paramsString = "";
            this.error = null;
            this.isPartialResult = null;
            this.query_id = null;
            if (!this.cache) {
                paramsString = "?cache=false"
            }
            this.showOutput = true;
            this.outputColumns = [""];
            var self = this;
            axios.post(self.$store.state.pailUrl + `/v1/cids/${self.$store.state.currentClient.cid}/query_executions`
                + paramsString, { query: this.query }, {
                headers: {
                    Authorization: `Bearer ${self.$store.state.accessToken}`,
                },
            }).then((response) => {
                if (response.data == null) {
                    self.error = response.message
                } else {
                    var query_id = response.data.query_execution_id
                    if (self.$store.state.environment == "test") {
                        query_id = "adReport"
                    }
                    if (query_id != "" && query_id != undefined) {
                        self.query_id= query_id;
                        self.sleep(2000).then(function () {
                            self.getQueryData(query_id, 120);
                        });
                    } else {
                        this.error = "Failed to submit query. Please retry or contact support.";
                        this.outputLoading = false;
                        this.runQuery = false;
                    }
                }
            }).catch(({ response }) => {
                self.outputLoading = false;
                self.runQuery = false;
                if (response.data.error) 
                    self.error = response.data.error;
                else if (response.data.message) 
                    self.error =  response.data.message;
                else 
                    self.error =  response.message;
                                
            })

        },
        async getQueryData(query_id, counter) {
            var self = this;
            return axios.get(self.$store.state.pailUrl + `/v1/cids/${self.$store.state.currentClient.cid}/query_status/${query_id}`, {
                headers: {
                    Authorization: `Bearer ${self.$store.state.accessToken}`,
                },
            }).then(function (response) {
                if (response.data.State && response.data.State == "FAILED") {
                    self.error = response.data.StateChangeReason
                } else if (response.data.State && (response.data.State == "QUEUED" || response.data.State == "RUNNING") && counter > 0) {
                    counter--;
                    self.sleep(5000).then(function () {
                        self.getQueryData(query_id, counter, self.cid);
                    });
                } else {
                    return axios.get(self.$store.state.pailUrl + `/v1/cids/${self.$store.state.currentClient.cid}/query_executions/${query_id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${self.$store.state.accessToken}`,
                            },
                        }).then(function (response) {
                            if (Object.prototype.hasOwnProperty.call(response.data, "message")) {
                                self.error = response.data.message
                            } else {
                                //Show result data
                                self.setOutputColumns(response.data.column_info)
                                self.outputData=self.convertToTableJson(response.data);
                                //If partial data is returned, then get show export option to download full data
                                if (response.data.partial_result) {
                                    self.isPartialResult = true;
                                    self.query_id = query_id
                                }
                            }
                        });
                    }
            }).catch(({ response }) => {
                self.outputLoading = false;
                self.runQuery = false;
                if (response.data.error) 
                    self.error = response.data.error;
                else if (response.data.message) 
                    self.error =  response.data.message;
                else 
                    self.error =  response.message;
            });
        },

        convertToTableJson(obj) {
            var c = [];
            for (var i = 0; i < obj.data.length; i++) {
                var temp = {}
                //huge data getting from query_result_file - data are not seperated so seperating it by ,
                if(obj.data[i].length !== obj.column_info.length){
                    obj.data[i] = obj.data[i].split(",")
                }
                for (var j = 0; j < obj.column_info.length; j++) {
                    temp[obj.column_info[j].name] = obj.data[i][j]
                }
                c.push(temp);
            }
            this.outputLoading = false;
            this.runQuery = false;
            return c;
        },
        sleep(time) {
            return new Promise(function (resolve) {
                setTimeout(resolve, time);
            });
        },
        setOutputColumns(column_info) {
            let outputColumns = column_info.map((column) => {
                return { "data": column.name }
            })
            this.outputColumns = [...outputColumns]
        },
        exportCSV() {
            let header = []
            this.error = null
            if (this.outputData.length > 0) {
                Object.keys(this.outputData[0]).map((value) => header[value] = value)
                csv.exportCSV("AdminQuery", [...header, ... this.outputData])
            } else {
                this.error = "No data to export"
            }

        },
        onStopClick(){
            this.outputLoading = false;
            this.runQuery = false;
            this.error = null;
            this.outputData=[];
            this.isPartialResult = null;
            try{
                if(this.query_id)
                    this.stopQueryExecutions(this.query_id);
            }catch (error) {
              if (error.response.data.error) 
                this.error = error.response.data.error;
              else if (error.response.data.message) 
                this.error =  error.response.data.message;
              else 
                this.error =  error.message;
            }
            this.query_id = null;
        },
        onClickClientView() {
            this.$router.push(`/${this.$store.state.currentClient.cid}/amdp/queries`);
        },
        exportFullData(){
          var self=this;
          this.fetchFullQueryFile(this.query_id).then(function (resp) {
            if(resp.https){
                  var url = resp.https;
                  var a = document.createElement("a");
                  a.href = url;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  a.remove(); 
            }else{
                  self.error = "Unable to download file, Try again !"
            }  
            }).catch(({response})=>{
                if (response.data.error) 
                    self.error = response.data.error
                else if (response.data.message) 
                    self.error = response.data.message
                else 
                    self.error =  response.message
            });
        }
    },
    computed: {
        isAdmin() {
            return this.$store.getters.userRoles.isAqferAdmin;
        },
        runQueryState() {
            return this.query.trim() == ''
        }
    },
}
</script>

<style scoped>
.codeMirrorHeight>>>.CodeMirror {
    height: 310px;
}

.form-check-input:checked {
    background-color: #2C7BE5 !important;
    border-color: #2C7BE5;
}

.form-check-input {
    background-color: #6E84A3;
}
</style>