import axios from 'axios'
import dayjs from "dayjs";

const store = {
    namespaced:true,
    state: {
        jobs: [],
        currentJob: null,
        currentCredential: null,
        currentSchema: null,
        currentMapping: null,
        currentDataChannel: null,
        currentQuery:null,
        currentCredentialType:null,
        credentials: [],
        schemas: [],
        mappings: [],
        dataChannels: [],
        queries:[],
        credentialTypes:[],
        folderPath:"",
        sandboxCredentials:[],
        selectedCredential:null,
        pipelines:[],
        jobKinds: [],
        jobTypes: [],
        usageData: {},
        usageDataStartDate:  dayjs().startOf('month').format("YYYY-MM-DD"),
        usageDataEndDate: dayjs().format("YYYY-MM-DD"),
        usageMonth: dayjs().format('MM'),
        countsMetricReqObj : {
            "amdp-performance-metric": {
            "version": "1.0.1",
            "header": [
                "mode",
                "stage",
                "group_name",
                "count",
                "cpu_allocated",
                "cpu_used",
                "cpu_utilized",
                "cpu_utilized_min",
                "cpu_utilized_avg",
                "cpu_utilized_max",
                "memory_allocated",
                "memory_utilized",
                "memory_utilized_min",
                "memory_utilized_avg",
                "memory_utilized_max",
                "succeeded",
                "pending",
                "duration_min",
                "duration_avg",
                "duration_max",
                "pending_duration_min",
                "pending_duration_avg",
                "pending_duration_max"
            ]
            },
            "amdp-job-run-params": {
            "version": "1.0.1"
            },
            "amdp-metric": {
            "version": "1.0.1",
            "header": [
                "data_channel",
                "data_channel_type",
                "record_schema",
                "schema_version",
                "file_count",
                "error_file_count",
                "record_count",
                "quarantine_record_count",
                "byte_count"
            ]
            }
        }
    },
    mutations: {
        setCurrentJob(state, job) {
            state.currentJob = job
        },
        setCurrentCredential(state, credential) {
            state.currentCredential = credential
        },
        setCurrentSchema(state, schema) {
            state.currentSchema = schema
        },
        setCurrentMapping(state, mapping) {
            state.currentMapping = mapping
        },
        setCurrentDataChannel(state, dataChannel) {
            state.currentDataChannel = dataChannel
        },
        setCurrentQuery(state, query) {
            state.currentQuery = query
        },
        setCurrentCredentialType(state, credentialType) {
            state.currentCredentialType = credentialType
        },
        setJobs(state, jobs) {
            state.jobs = jobs
        },
        setSchemas(state, schemas) {
            state.schemas = schemas
        },
        setMappings(state, mappings) {
            state.mappings = mappings
        },
        setCredentials(state, credentials) {
            state.credentials = credentials
        },
        setDataChannels(state, dataChannels) {
            state.dataChannels = dataChannels
        },
        setQueries(state, queries) {
            state.queries = queries
        },
        setCredentialTypes(state, credentialTypes) {
            state.credentialTypes = credentialTypes
        },
        setFolderPath(state, folderPath) {
            state.folderPath = folderPath
        },
        setSandboxCredentials(state, sandboxCredentials) {
            state.sandboxCredentials = sandboxCredentials
        },
        setSelectedCredential(state, selectedCredential) {
            state.selectedCredential = selectedCredential
        },
        setPipelines(state, pipelines) {
            state.pipelines = pipelines;
        },
        setJobKinds(state, jobKinds) {
            state.jobKinds = jobKinds;
        },
        setJobTypes(state, jobTypes) {
            state.jobTypes = jobTypes;
        },
        setUsageData(state, data) {
            state.usageData = data
        },
        setUsageDataStartDate(state, startDate) {
            state.usageDataStartDate = startDate
        },
        setUsageDataEndDate(state, endDate) {
            state.usageDataEndDate = endDate
        },
        setUsageMonth(state, month) {
            state.usageMonth = month
        },
    },
    actions: {
        setCurrentJob({commit}, job) {
            commit('setCurrentJob', job)
        },
        setCurrentCredential({commit}, credential) {
            commit('setCurrentCredential', credential)
        },
        setCurrentSchema({commit}, schema) {
            commit('setCurrentSchema', schema)
        },
        setCurrentMapping({commit}, mapping) {
            commit('setCurrentMapping', mapping)
        },
        setCurrentDataChannel({commit}, dataChannel) {
            commit('setCurrentDataChannel', dataChannel)
        },
        setCurrentQuery({commit}, query) {
            commit('setCurrentQuery', query)
        },
        setCurrentCredentialType({commit}, credentialType){
            commit('setCurrentCredentialType', credentialType)
        },
        setFolderPath({commit}, folderPath) {
            commit('setFolderPath', folderPath)
        },
        setSandboxCredentials({commit}, sandboxCredentials){
            commit('setSandboxCredentials',sandboxCredentials)
        },
        setSelectedCredential({commit}, selectedCredential){
            commit('setSelectedCredential',selectedCredential)
        },
        setUsageData({commit}, data) {
            commit('setUsageData', data)
        },
        setUsageDataStartDate({commit}, startDate) {
            commit('setUsageDataStartDate', startDate)
        },
        setUsageDataEndDate({commit}, endDate) {
            commit('setUsageDataEndDate', endDate)
        },
        setUsageMonth({commit}, month) {
            commit('setUsageMonth', month)
        },
        onClientChange({commit}) {
            commit('setJobs', [])
            commit('setCredentials', [])
            commit('setQueries', [])
        },
        fetchJobs({rootState, state, commit}, sandboxName) {
            axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}/jobs`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                let jobs = response.data;
                commit('setJobs', jobs)
                if (!state.currentJob)
                    commit('setCurrentJob',jobs[0])
            }).catch((e) => {
                commit('setJobs', [])
            })
        },
        fetchMappings({rootState, state, commit}) {
            axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/config/mappings`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                let data = response.data

                let schemas = data.schemas
                schemas = Object.keys(schemas).map(function (key) {
                    return { 
                        ...schemas[key],
                        'id': key,
                        'name': key.replace(/_/g, ' ')
                    }
                });
                commit('setSchemas', schemas)
                if (!state.currentSchema)
                    commit('setCurrentSchema',schemas[0])

                let mappings = data.schema_mappings
                mappings = Object.keys(mappings).map(function (key) {
                    return { 
                        ...mappings[key],
                        'id': key,
                        'name': key.replace(/_/g, ' ')
                    }
                });
                commit('setMappings', mappings)
                if (!state.currentMapping)
                    commit('setCurrentMapping',mappings[0])
            });
        },
        fetchConfigCredentials({rootState, state, commit}) {
            axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/config/credentials`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                let credentials = response.data[rootState.currentClient.cid];
                credentials = Object.keys(credentials).map(function (key) {
                    return { 
                        ...credentials[key],
                        'id': key,
                        'name': key.replace(/_/g, ' ')
                    }
                });
                commit('setCredentials', credentials)
                if (!state.currentCredential)
                    commit('setCurrentCredential',credentials[0])
            });
        },
        fetchCredentials({rootState, state, commit}){
              axios.get(rootState.mgmtUrl + `/v1/clients/${rootState.currentClient.cid}/credentials`,{
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then(function (response) {
                let credentials = response.data;
               commit('setCredentials', credentials)
                if (!state.currentCredential)
                    commit('setCurrentCredential',credentials[0])
                    
              });             
        },
        fetchDataChannels({rootState, state, commit}) {
            axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/config/data_channels`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                let channels = response.data[rootState.currentClient.cid];
                channels = Object.keys(channels).map(function (key) {
                    return { 
                        ...channels[key],
                        'id': key,
                        'name': key.replace(/_/g, ' ')
                    }
                });
                commit('setDataChannels', channels)
                if (!state.currentDataChannel)
                    commit('setCurrentDataChannel',channels[0])
            });
        },
        fetchQueries({rootState, state, commit}) {
            axios.get(rootState.pailUrl + `/v1/cids/${rootState.currentClient.cid}/named_queries`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                let queries= response.data
                commit('setQueries', queries)
                if (!state.currentQuery)
                    commit('setCurrentQuery', queries[0])
            });
        },
        fetchUsageData({rootState, dispatch, state, commit}, usageType) {
            // Reset only the specific usageType 
            commit('setUsageData', { 
                ...state.usageData, 
                [usageType]: {} 
            });

            let payload = {
                startDate: state.usageDataStartDate,
                endDate: state.usageDataEndDate,
                product: 'amdp',
                usageType: usageType                
            };
            dispatch('usage/getProductUsage', payload, { root: true }).then((data) => {
                if(data && data[payload.product]) {
                    // Update only the specific usageType portion while preserving other data
                    commit('setUsageData', {
                        ...state.usageData,
                        [usageType]: data[payload.product]
                    });
                }
            });  
            
        },
        async readJob({rootState, state}, params) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${params.sandboxName}/jobs/${params.jobName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async fetchJobRuns({rootState, state}, jobName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/default/jobs/${jobName}/executions`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async fetchParamDefs({rootState}){
            return axios.get(rootState.pailUrl+`/v1/cids/${rootState.currentClient.cid}/param_defs`,{
                headers: {
                    Authorization :`Bearer ${rootState.accessToken}`
                }
            }).then((response) =>{
                return response.data;
            })
            
        },
        async fetchFullQueryFile({rootState},query_id){
            return axios.get(rootState.pailUrl+`/v1/cids/${rootState.currentClient.cid}/query_result_link/${query_id}`,{
                headers: {
                    Authorization :`Bearer ${rootState.accessToken}`
                }
            }).then((response) =>{
                return response.data;
            })         
        },
        async createQuery({rootState}, queryPayload){
            return axios.post(rootState.pailUrl+`/v1/cids/${rootState.currentClient.cid}/named_queries`,queryPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            })
        },
        async readQuery({rootState}, id) {
            return axios.get(rootState.pailUrl + `/v1/cids/${rootState.currentClient.cid}/named_queries/` + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        }, 
        async updateQuery({rootState}, queryPayload) {
            return axios.put(rootState.pailUrl + `/v1/cids/${rootState.currentClient.cid}/named_queries/${queryPayload.Name}`, queryPayload,{
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteQuery({rootState}, queryPayload) {
            return axios.delete(rootState.pailUrl + `/v1/cids/${rootState.currentClient.cid}/named_queries/${queryPayload.Name}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async createParam({rootState}, paramPayload){
            return axios.post(rootState.pailUrl+`/v1/cids/${rootState.currentClient.cid}/param_defs`,paramPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            })
        },
        async fetchCredentialTypes({rootState, state, commit}){
            return axios.get(rootState.mgmtUrl+`/v1/credential_types`,{
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            }).then((response)=>{
                let credentialTypes= response.data
                commit('setCredentialTypes', credentialTypes)
                if (!state.currentQuery)
                    commit('setCurrentCredentialType', credentialTypes[0])
            })
        },
        async createCredential({rootState}, credentailPayload){
            return axios.post(rootState.mgmtUrl+`/v1/clients/${rootState.currentClient.cid}/credentials`,credentailPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            })
        },
        async updateCredential({rootState}, {credentialPayload,referenceCredName}) {
            return axios.put(rootState.mgmtUrl + `/v1/clients/${rootState.currentClient.cid}/credentials/` + referenceCredName, credentialPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteCredential({rootState}, credentialName) {
            return axios.delete(rootState.mgmtUrl + `/v1/clients/${rootState.currentClient.cid}/credentials/` + credentialName, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readCredential({rootState}, id) {
            return axios.get(rootState.mgmtUrl + `/v1/clients/${rootState.currentClient.cid}/credentials/` + id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async getFileBrowserList({rootState}, param) {
            return axios.get(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${param.sandboxName}/browser/list?folder=`+param.folderName, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async getBucketList({rootState},param) {
            return axios.get(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${param.sandboxName}/bucketlist/${param.type}?credential=`+param.credential, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async getPresignURL({rootState}, param) {
            return axios.get(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${param.sandboxName}/browser/${param.operation}?filename=`+param.filePath, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteFiles({rootState}, filepath){
            return axios.delete(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/file/`+filepath, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async getSandboxCredentials({rootState, commit},sandboxName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}/credentials`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response)=>{
                commit('setSandboxCredentials',response.data);
                return response
            })
        },
        async upsertSandboxCredential({rootState}, {sandboxName, payload}) {
            return axios.put(rootState.lakeviewUrl+`/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}/credentials`, payload,{
                headers:{
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            })
        },
        async uploadPresignURLFile({rootState},param){
            await axios.put(param.signedUrl, param.file, {
                headers: {
                'Content-Type': param.file.type
                }
            });
        },
        async fetchOutputParser({rootState}, payload){
            return axios.get(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${payload.sandboxName}/output_parser?path=${payload.path}&limit=${payload.limit}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async fetchPipelines({rootState, commit}, sandboxName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}/pipelines-v0`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response)=>{
                commit('setPipelines',response.data);
                return response;
            })  
        }, 
        
        async fetchPipeline({rootState, commit}, param) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${param.sandboxName}/pipelines-v0/${param.pipelineName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response)=>{
                commit('setPipelines',response.data);
                return response;
            })  
        },
        
        async stopQueryExecutions({rootState}, execution_id){
            return axios.post(rootState.pailUrl +`/v1/cids/${rootState.currentClient.cid}/query_executions/${execution_id}`,{},{
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                }
            });
        },

        async fetchJobKinds({rootState, commit }){
            return axios.get(rootState.lakeviewUrl +`/v1/job_kinds`,{
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                }
            }).then((response)=>{
                commit('setJobKinds',response.data.job_kinds);
                return response;
            })  
        },

        async fetchJobTypes({rootState, commit}) {
            return axios.get(rootState.lakeviewUrl + `/v1/job_types`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                }
            }).then((response) => {
                commit('setJobTypes',response.data);
                return response;
            })
        }

        
    }
}

export default store