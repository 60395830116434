<template>
    <basic-header pretitle="Aqfer Marketing Data Platform" title="Sandbox User Management" buttonText="Add User"
        @btnClick="showModal({ component: 'addSandboxUser', data: { sandbox: this.selectedSandbox, fetchUsers: fetchSandboxUserList } })" />

    <div class="container-fluid">
        <div v-if="selectedSandbox">
            <input-field label="Choose a sandbox" type="select" :options="sandBoxListOptions" v-model="selectedSandbox" />
            <label class="form-label">List of authorized users for sandbox - <b>{{ this.selectedSandbox }}</b></label>
            <div class="card">
                <datatable 
                    tableId="sandboxUserList" 
                    :columns="columns" 
                    uniqueField="name"
                    :data="userList" :showLoading="userList === null"
                    :formatColumn="formatColumn" 
                    :deletable="true" @deleteRows="onDeleteSandboxUser" 
                    :compact="true" :selectCheckboxes="true"
                    @viewModal="(data)=>showModal({ component: 'addSandboxUser',data:{ sandbox: this.selectedSandbox, fetchUsers: fetchSandboxUserList, data:data}})"
                    :detailColumn="{openModal:true}" 
                />
            </div>
        </div>
        <div v-else-if="!sandboxLoading && !sandBoxListOptions.length">
            <div class="alert alert-secondary" role="alert">
                No Sandboxes available!
            </div>
        </div>
        <div v-else class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>
<script>
import InputField from "../../components/InputField.vue";
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions } from "vuex";

export default {
    components: { BasicHeader, Datatable, InputField },
    data() {
        return {
            columns: [
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "email",
                    title: "Email"
                },
                {
                    data: "access_level",
                    title: "Access Level"
                },
                {
                    data: "updated_at",
                    title: "Updated At"
                }
            ],
            userList: null,
            sandboxLoading: true,
            formatColumn:[
                {
                    index: 5,
                    type: "datetime"
                }
            ],
        };
    },
    beforeMount() {
        this.fetchSandboxes();
        this.$store.dispatch("admin/fetchUsers");
    },

    computed: {
        selectedSandbox: {
            get() {
                let selectedSandbox = null
                let defaultSandbox = this.$store.state.sandbox.sandboxes[0] ? this.$store.state.sandbox.sandboxes[0].name : null;
                if (this.$store.state.sandbox.currentSandbox) {
                    const currentSandbox = this.$store.state.sandbox.currentSandbox;
                    const ifExists = this.$store.state.sandbox.sandboxes.filter((sandbox) => {
                        return sandbox.name == currentSandbox
                    })
                    selectedSandbox = ifExists.length ? currentSandbox : defaultSandbox;
                } else {
                    selectedSandbox = defaultSandbox;
                }
                this.$store.dispatch('sandbox/setCurrentSandbox', selectedSandbox);
                this.fetchSandboxUserList(selectedSandbox);
                return selectedSandbox;
            },
            set(value) {
                this.fetchSandboxUserList(value);
                this.$store.dispatch('sandbox/setCurrentSandbox', value);
            }
        },
        sandBoxListOptions() {
            return this.$store.state.sandbox.sandboxes ? this.$store.state.sandbox.sandboxes.map((sandbox) => {
                return { "label": sandbox.name+" - "+sandbox.env, "value": sandbox.name };
            }) : [];
        },
    },
    methods: {
        showDeleteSandboxes(rows) {
            this.showModal({ component: "deleteSandboxes", data: rows });
        },

        ...mapActions({
            readSandboxUserList: "sandbox/fetchSandboxUsers",
            addSandboxUser: "sandbox/addSandboxUser",
            deleteSandboxUser: "sandbox/deleteSandboxUser",
            showModal: "showModal",
        }),
        setLoading(bool) {
            this.sandboxLoading = bool;
        },
        async fetchSandboxes() {
            this.setLoading(true);
            try {
                await this.$store.dispatch("sandbox/fetchSandboxes");
            } catch (response) {
                this.error = response.data.error
            } finally {
                this.setLoading(false);
            }
        },
        async fetchSandboxUserList(selectedSandbox) {
            if (selectedSandbox) {
                let response;
                try {
                    response = await this.readSandboxUserList(selectedSandbox);
                    let self = this;
                    const userList = response.data;
                    if (userList) {
                        this.userList = userList.map((sandboxUser) => {
                            const filteredUser = self.$store.state.admin.users.find(user => user.email === sandboxUser.email);
                            let user ={email: sandboxUser.email, access_level:sandboxUser.access_level, updated_at: sandboxUser.updated_at}
                            if (filteredUser)
                                user["name"]= filteredUser.name;
                            return user
                        });
                    } else {
                        this.userList = userList || [];
                    }
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
            } else {
                this.userList = null;
            }


        },
        async onDeleteSandboxUser(rows) {
            this.showModal({ component: "deleteSandboxUsers", data: { deleteUserRows: rows, sandbox: this.selectedSandbox, fetchUsers: this.fetchSandboxUserList } });
        },

    }

}
</script>