<template>
	<div class="row left-nav-collapsible" v-if="!loading">
		<div v-if="detailPageOpen" id="detail-page-open">   
            <button class="btn details-open-btn" type="button" @click="onDetailPageOpenClose(false)">
                <span> <i class="fa fa-caret-right"></i></span>
            </button>
        </div> 
        <TransitionGroup :name="promotionConfig?'slide':''" key="detail">
            <div v-if="showCloseBtn && !detailPageOpen" id="detail-page-close" key="close">
                <button class="btn text-start details-open-btn" type="button" @click="onDetailPageOpenClose(true)">
                    <span> <i class="fa fa-caret-left"></i></span>
                </button>
            </div>
			<div
				v-if="
					!promotionConfig ||(promotionConfig && !promotionConfig.system_files &&
					!promotionConfig.user_files)
				"
			>
				<div class="alert alert-secondary" role="alert">
					No configuration files found.
				</div>  
				
			</div>
			<div id="details-col" v-if="!detailPageOpen"  @mouseover="onDetailPageHover(true)" key="details-col">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Files</h4>
					</div>
					<div class="card-body">					
						<list-group
							:title="'File List'"
							:list="promotionConfig.system_files"
							:activeListItem="activeTab"
							listItemKey="relative_path"
							@selectBtnclick="showFile"
						></list-group>
						<list-group
							:editMode="editMode"
							:title="'File List'"
							:list="promotionConfig.user_files"
							@selectBtnclick="showFile"
							:activeListItem="activeTab"
							listItemKey="relative_path"
						></list-group>
					</div>
				</div>
			</div>
			<div class="col" id="main-col" v-if="promotionConfig"  @mouseover="onDetailPageHover(false)" key="main-col">
				<job-editor
					v-on:tabChange="showTab"
					:editMode="editMode"
					v-model="yamlContent"
					:activeTab="activeTab"
				></job-editor>
			</div>
		</TransitionGroup>
	</div>
	<div v-else class="text-center">
		<div class="spinner-border" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>	
</template>

<script>
	import { mapState } from "vuex";
	import ListGroup from "../../../components/ListGroup.vue";
	import JobEditor from "../../../components/sandbox/JobEditor.vue";
	export default {
		components: { ListGroup, JobEditor },
		props: ["promotion"],
		data() {
			return {
				activeTab: "",
				yamlContent: {},
				loading: false,
				editMode: false,
				detailPageOpen: false,
				showCloseBtn: false
			};
		},
		methods: {
			showTab(tab) {
				this.activeTab = tab;
			},
			showFile(file) {
				this.activeTab = file.relative_path;
			},
			initateYamlContent() {
				this.jobObj = this.job;
				let config = this.promotionConfig;

				let yamlContent = {};
				if(config) {
					if (config.system_files) {
						for (const i of config.system_files) {
							yamlContent[i.relative_path] = i.content;
						}
					}
					if (config.user_files) {
						for (const i of config.user_files) {
							yamlContent[i.relative_path] = i.content;
						}
					}
					//Initially activeTab value is empty or undefined otherwise no need to reset to first value
					if (!this.activeTab || this.activeTab == "")
						this.activeTab = Object.keys(yamlContent)[0];
					this.yamlContent = JSON.parse(JSON.stringify(yamlContent));

				}
				
			},
			onDetailPageOpenClose(value){
				this.showCloseBtn = false;
				this.detailPageOpen = value;
			},
			onDetailPageHover(value){
				this.showCloseBtn = value;
			}
		},

		computed: {
			...mapState({
				promotionConfig: (state) => state.sandbox.promotionConfig,
			}),
		},
		watch: {
			promotionConfig() {
				this.initateYamlContent();
			},
		},
		async beforeMount() {
			this.loading = true;
			await this.$store.dispatch("sandbox/fetchPromotionConfig", {promotionId: this.promotion.id});
			this.loading = false;
			this.initateYamlContent();
		},
		mounted() {
			this.$emit('hideTestBtn', true);
		},
		emits: ['hideTestBtn']
	};
</script>

<style></style>
