<template>
    <div>         
        <div class="card">
            <div class="row justify-content-end">                                    
                <div  class="col-sm-3 d-flex position-absolute mt-3 me-3 justify-content-end" >
                    <div class="input-group input-group-sm">
                        <label class="input-group-text">Promotions Limit</label>
                        <input type="number" class="form-control" aria-label="limit" v-model="$store.state.sandbox.promotionsListLimit">
                        <button :disabled="promotionList===null" class="btn btn-outline-secondary" type="button" @click="readPromotionsList"><i class="fa fa-refresh" aria-hidden="true"></i></button>                    
                    </div>
                </div>           
            </div>                     
            <datatable             
                :tableId="`${job.name}-promotions`" 
                :columns="columns"
                :formatColumn="formatColumn"
                :orderColumn="orderColumn"
                :data="promotionList"  
                :showLoading="promotionList===null"               
                :compact="true"           
                :detailColumn="{renderBaseUrl: (data) => {
                                                        return `/${$route.params.cid}/amdp/sandbox/${data.dst_sandbox_name}/promotions/${data.id}/overview`;                                                   
                                                    },}"

            />
        </div>    
    </div>  
</template>
<script>
import Datatable from "../../../components/Datatable.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
    name: "Promotions",
    components: { Datatable },
    data() {
        return {
            columns: [            
                { data: "id" },
                { data: "src_job_name", title: "Src Job"},
                { data: "dst_sandbox_name", title: "Dst Sandbox" },
                { data: "dst_job_name", title: "Dst Job"},
                { data: "status" },
                { data: "created_by" },
                { data: "created_at" }       
            ],
            orderColumn: [7, "desc"],
            formatColumn: [
                {
                    index: 7,
                    type: "datetime",
                },
            ],        
            promotionList: null
        };
    },
    props:[
        "job",
        "isSandbox",
    ],    
    watch: {
        job() {
            this.readPromotionsList();
        }       
    },
    methods: {     
        async readPromotionsList() {
            if (!this.job.name)
                return; 
            let self = this;   
            let response; 
            await axios.get(`${self.$store.state.lakeviewUrl}/v1/cids/${self.$store.state.currentClient.cid}/sandboxes/${this.$route.params.sandboxId}/jobs/${this.$route.params.jobId}/promotions?limit=${this.$store.state.sandbox.promotionsListLimit}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    }
            }).then((response)=>{
                let modifiedList = [];          
                const promotionList = response.data; 
                promotionList.map((promotion)=>{
                    let status ="";
                    if(promotion.status.toLowerCase()==="succeeded"){
                        status = "<span class='text-success'>●</span> ";
                    } else if(promotion.status.toLowerCase()==="failed"){
                        status = "<span class='text-danger'>●</span> ";
                    } else if(promotion.status.toLowerCase()==="in_progress"){
                        status = "<span class='text-primary'>●</span> ";
                    } else if(promotion.status.toLowerCase()==="submitted"){
                        status = "<span class='text-warning'>●</span> ";
                    } else if(promotion.status.toLowerCase()==="cancelled"){
                        status = "<span class='text-secondary'>●</span> ";
                    }
                    promotion["status"] = status + promotion.status;
                    modifiedList.push(promotion);
                })
            this.promotionList = modifiedList;  
            }).catch((error) => {
                this.loading=false
                if (error.response.data.error) 
                    return error.response.data.error
                else if (error.response.data.message) 
                    return error.response.data.message
                else 
                    return error.message
            });           
        }      
    },
    beforeMount() {
        this.readPromotionsList();
    },
    mounted() {  
        this.$emit('hideEdit', true);
        this.$emit('hideRunJob', true);
        this.$emit('showOverrideEdit', false);
        this.$emit('setOverrideEditMode', false);
    },
    emits: ['hideEdit', 'hideRunJob', 'showOverrideEdit', 'setOverrideEditMode']  

}
</script>

<style>

</style>