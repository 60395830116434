<template>	
	<basic-header
        pretitle="aqfer Universal Tag"
        title="Configure Hosts"
        buttonText="Add Entry"
		:buttonRoles= "btnRoles"
        @btnClick="showModal({ component: 'addEdgeHost' })"
    />
	<div class="container-fluid">		
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<datatable
						tableId="hostList"
						:columns="columns"
						uniqueField="edgeHostname"
						:data="$store.state.aut.domainList"
						:compact="true"
						:detailColumn="{
							baseUrl: 'configure-hosts/',
							name: 'Configure CNames',
							identifier: 'edgeHostname',
							identifierName: 'hostName'
						}"
					/>
				</div>
			</div>
		</div>
	</div>	
</template>

<script>
	import BasicHeader from '../../components/BasicHeader.vue';
	import Datatable from "../../components/Datatable.vue";
	import { mapActions, mapState } from "vuex";
	export default {
		components: { Datatable , BasicHeader},
		data() {
			return {
				columns: [
					{ data: "edgeHostname" },
					{
						data: "domains",
						render: function (data, type, row) {
							if (!data) return "";
							return Array.isArray(data.items)
								? data.items.length
								: 0;
						},
					},
					{
						data: "ipType",
						render: function(data, type, row) {
							return data == "DUAL" ? "IPV4, IPV6" : "IPV4" ;
						}
					},
				],
				btnRoles: [1]
			};
		},
		beforeMount() {
			this.fetchDomainList();
		},
		computed: {			
			userRoleRank() {
				return this.$store.getters.userRoles.userRoleRank;
			}  
		},  
		methods: {
			...mapActions({
				showModal: "showModal",
			}),
			fetchDomainList() {
				this.$store.dispatch("aut/getDomainLists");
			}
		}		
	};
</script>

<style></style>
