<template>
    <DetailHeader pretitle="Named Query" :title="query ? query.Name : '&nbsp;'" :editable="true" :editMode="editMode"
        @deleteClick="onDeleteClick" @saveClick="onSaveClick" @cancelClick="onCancelClick"
        @closeClick="$router.push(`/${$store.state.currentClient.cid}/amdp/queries`)" />

    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div v-if="error" class="alert alert-danger" role="alert">
                    {{ error }}
                </div>
                <div v-if="successMessage" class="alert alert-success" role="alert">
                    {{ successMessage }}
                </div>
                <div v-if="query">
                    <div class="card">
                        <div class="card-header">
                                <h4 style="display: inline-block">Query String</h4>
                        </div>
                        <div class="card-body p-0">
                            <CodemirrorEditor class="codeMirrorHeight" :key="query.Name" :editorId="query.Name"
                                    mode="sql" v-model="query.QueryString" :readOnly="!editMode">
                            </CodemirrorEditor>
                        </div>
                    </div>
                    <div class="card">
                            <div class="card-header">
                                <h4 style="display: inline-block">Query Parameter</h4>
                                <span class="float-end">
                                    <button type="button" @click="showModal({component:'AddParam', data:{ resetParamDef:getParamDefs}})"
                                        class="btn btn-primary">Add Param Def</button>&nbsp;
                                </span>
                            </div>
                            <div class="card-body p-0">
                                <div v-if="query.Params" class="card-body">
                                    <EditableTable tableId="paramTableedit" :columns="paramColumns" :data="query.Params"
                                        :fields="tableParamFields" @addBtnClick="onAddParam"
                                        @deleteBtnClick="onDeleteParam" @selectBtnChange="onSelectParam"
                                        :compact="true" />
                                </div>
                                <div v-else>
                                    No Params
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DetailHeader from "../../components/DetailHeader.vue";
import Fields from "../../utils/field-constants/paramFields";
import CodemirrorEditor from "../../components/CodemirrorEditor.vue";
import { mapActions } from "vuex";
import EditableTable from "../../components/admp/EditableTable.vue";
export default {
    name: "Query",
    components: { DetailHeader, CodemirrorEditor, EditableTable },

    data() {
        return {
            editMode: true,
            query: null,
            error: "",
            successMessage: "",
            paramColumns: [
                { data: "name" },
                { data: "value", title: "Value" },
                { data: "datatype", title: "Datatype" },
                { data: "rule", title: "Rule" },
            ],
            paramDefs: [],
            tableParamFields: [],
            loading: false,
            paramAddError:null,
            operation:"Edit"
        };
    },
    beforeMount() {
        this.fetchQuery();
        this.getParamDefs().then(()=>{
            if(this.$route.params.data)
                this.validateParamDef(this.query)
        });

    },
    methods: {
        ...mapActions({
            readQuery: "amdp/readQuery",
            updateQuery: "amdp/updateQuery",
            deleteQuery: "amdp/deleteQuery",
            fetchParamDefs: "amdp/fetchParamDefs",
            showModal: "showModal",
            createParam: 'amdp/createParam',
            createQuery: 'amdp/createQuery'
        }),
        async fetchQuery() {
            let response={};
            this.loading=true;
            //if page is opened via import option then id is import_new, so perform Add operation
            if(!this.$route.params.data && this.$route.params.id!=="import_new"){
                try {
                    response = await this.readQuery(this.$route.params.id);
                } catch (error) {
                    if (error.response.data.error) 
                        this.error= error.response.data.error;
                    else if (error.response.data.message) 
                        this.error=  error.response.data.message;
                    else 
                        this.error=  error.message;
                    return;
                }
            }else if(this.$route.params.data){
                response["data"]=JSON.parse(this.$route.params.data)     
            }
            this.query = await response.data;
            this.loading = false;
        },
        async getParamDefs() {
            this.tableParamFields = JSON.parse(JSON.stringify(Fields));
            await this.fetchParamDefs().then((result) => {
                 //In Editable Table Param name is Select type
                this.tableParamFields[0].type = "select";
                //value is not editable in edittable
                this.tableParamFields[1].editable=false;
                this.paramDefs = result;
                let nameOptions = result.map((item) => {
                    let options = {}
                    options["label"] = item.name;
                    options["value"] = item.name;
                    return options
                })
                this.tableParamFields[0].options = nameOptions;
            }).catch((error)=>{
                if (error.response.data.error) 
                    this.error = error.response.data.error;
                else if (error.response.data.message) 
                    this.error = error.response.data.message;
                else 
                    this.error =  error.message;
            });
        },
        onCancelClick() {
            this.$router.push(`/${this.$store.state.currentClient.cid}/amdp/queries`);
        },
        onDeleteClick() {
            const self = this;
            //In edit mode only user can delete the query else move to main page
            if(this.operation=="Edit"){
                this.showModal({
                    component: "confirm",
                    data: {
                        title: "Delete Named Query",
                        message: `Are you sure you want to delete ${self.query.Name}?`,
                        async confirm() {
                            try {
                                self.loading=true
                                const response = await self.deleteQuery(
                                    self.query
                                );
                                self.$store.dispatch("amdp/fetchQueries");
                                self.$store.dispatch("amdp/setCurrentQuery", self.$store.state.amdp.queries[0]);
                                self.$router.push(`/${self.$store.state.currentClient.cid}/amdp/queries`);
                                self.loading=false;
                                return response;
                            } catch (error) {
                                if (error.response.data.error)
                                    self.error = error.response.data.error;
                                else if (error.response.data.message) 
                                    self.error =  error.response.data.message;
                                else self.error = error.message;
                                return;
                            }
                        },
                    },
                });
            }else{
                self.$router.push(`/${self.$store.state.currentClient.cid}/amdp/queries`);                   
            }
        },
        async onSaveClick() {
            var self = this;
            try {
                if (this.query.Params.some(param => Object.keys(param).length < 2)) {
                    this.error = "Param is empty"
                } else if(this.query.QueryString.trim().length == 0){
                    this.error = "Query String is empty";
                }else if (!this.$store.getters.userRoles.isAqferAdmin && this.isDDLQuery(this.query.QueryString)) {
                    this.error = "CREATE|ALTER|DROP named querries can't be created. Please contact support.";
                }else {
                    this.loading=true;
                    //If opened via import option perform add else default edit option
                    if(this.operation=="Edit"){
                        await this.updateQuery(this.query);
                        this.successMessage = "Query updated successfully.";              
                    }else{
                        this.query.Owner = this.query.Internal ? 'SYSTEM' : 'USER';
                        await this.createQuery(this.query);
                        this.successMessage = "Query created successfully.";
                    }
                    let query = await self.readQuery(this.query.Name);
                    self.$store.dispatch("amdp/setCurrentQuery", query.data);
                    this.loading=false;
                    this.$router.push(`/${self.$store.state.currentClient.cid}/amdp/queries`);
                }
            } catch (error) {
                this.loading = false;
                if (error.response.data.error) 
                    this.error = error.response.data.error;
                else if (error.response.data.message) 
                    this.error = error.response.data.message;
                else 
                    this.error =  error.message;
            }
        },
        onAddParam() {
            this.error =null;
            this.query.Params.push({})
        },
        onDeleteParam(paramkey) {
            this.query.Params.splice(paramkey, 1)

        },
        onSelectParam(param, index) {
            //Copy list else it is affecting in original value
            const paramdef=JSON.parse(JSON.stringify(this.paramDefs))
            let defIndex = paramdef.findIndex(def => def.name === param);
            //index is >0 when select is param
            if (defIndex>0){
                this.query.Params[index] = paramdef[defIndex]
            }
        },
        isDDLQuery(qs) {
            qs = qs.replace(/[\n\r\s]/g, '').toLowerCase()
            if (qs.startsWith("alter") || qs.startsWith("create") || qs.startsWith("drop")) {
                return true
            }
            return false
        },
        //If the Import query has param which is not in paramDef, add new param after validating else remove from parm def
        async validateParamDef(){
            let param = this.query.Params;
            let queryParam =[];
            this.paramAdded=false;
            this.operation="Add"
            if(!this.query.Internal){
                this.query.Internal = false
            }
            for (const item of param) {
                let exits=this.paramDefs.find((def)=>item.name==def.name)
                if(this.validateParam(item)){
                        if(!exits){
                            try {
                                await this.createParam(item)
                                queryParam.push(item)
                                this.paramAdded= true
                            }catch (error) {
                                if (error.response.data.error) 
                                    this.error = error.response.data.error;
                                else if (error.response.data.message) 
                                    this.error =  error.response.data.message;
                                else 
                                    this.error = error.message;
                                return
                            }
                        }else{
                            queryParam.push(item);
                        }
                    }else{
                        this.paramAddError =this.paramAddError? this.paramAddError+ ", '"+item.name+"'": "'"+item.name+"'";
                    }
          
            }
            this.query.Params= await [...queryParam];
            if(this.paramAddError){
                let toastOptions = {
                    message:  this.paramAddError + " Param Def is invalid so removed from param list.",
                    type: "warning",
                };
                this.$store.dispatch("addToast", toastOptions);
            }
            await this.getParamDefs();
            
        },
        validateParam(value){
            if(value["name"] && value["name"]!=="" && value["datatype"] && value["datatype"]!=""){
                const regex= new RegExp("^[a-zA-Z0-9_]+$");
                let name =regex.test(value["name"]);
                const types=["string","int","bool"];
                let datatype = types.indexOf(value["datatype"])!==-1? true: false
                return name && datatype
            }
            return false
        }
    },
};
</script>

<style scoped>
.codeMirrorHeight>>>.CodeMirror {
    height: 460px;
}
</style>