import axios from 'axios'
import dayjs from "dayjs";

const store = {
    namespaced:true,
    state: {
        currentHandlerJob: null,
        currentRequest: null,
        handlerRequests: [],
        handlerLastDeployDetails:{},
        usageData: {},
        usageDataStartDate:  dayjs().startOf('month').format("YYYY-MM-DD"),
        usageDataEndDate: dayjs().format("YYYY-MM-DD"),
        usageMonth: dayjs().format('MM'),
        handlerLogs:{},
    },
    mutations: {
        setCurrentHandlerJob(state, handlerJob) {
            state.currentHandlerJob = handlerJob
        },
        setCurrentRequest(state, request) {
            state.currentRequest = request
        },
        setHandlerRequests(state, requests) {
            state.handlerRequests = requests
        },
        setHandlerLastDeployDetails(state, DeployDetailsObj) {
            state.handlerLastDeployDetails[DeployDetailsObj.handlerId] = DeployDetailsObj.deployDetails;
        },
        setUsageData(state, data) {
            state.usageData = data
        },
        setUsageDataStartDate(state, startDate) {
            state.usageDataStartDate = startDate
        },
        setUsageDataEndDate(state, endDate) {
            state.usageDataEndDate = endDate
        },
        setUsageMonth(state, month) {
            state.usageMonth = month
        },
        setHandlerLog(state, HandlerObj) {
            state.handlerLogs[HandlerObj.handlerId] = HandlerObj.log;
        },
    },
    actions: {
        onClientChange({commit}) {
            commit('setCurrentHandlerJob', null)
            commit('setHandlerRequests', []);
        },
        async setCurrentHandlerJobId({state, dispatch, commit}, id) {
            //don't refetch the same job if it's current
            if (state.currentHandlerJob && id == state.currentHandlerJob.name )
                return;
 
            commit('setCurrentHandlerJob', null)
            commit('setHandlerRequests', []);
            dispatch('fetchHandlerRequests', id); 

            let response;
            try {
                response = await dispatch('sandbox/readSandboxJob', id, {root:true}); 
                let jobData = await response.data;
                commit('setCurrentHandlerJob', jobData)
            } catch (error) {
                console.log(error)
            }
        },
        setCurrentHandlerJob({commit}, handlerJob) {
            commit('setCurrentHandlerJob', handlerJob)
        },
        setCurrentRequest({commit}, request) {
            commit('setCurrentRequest', request)
        },
        setHandlerLastDeployDetails({commit}, {handlerId, deployDetails}){
            commit('setHandlerLastDeployDetails', {handlerId, deployDetails});

        },
        setUsageData({commit}, data) {
            commit('setUsageData', data)
        },
        setUsageDataStartDate({commit}, startDate) {
            commit('setUsageDataStartDate', startDate)
        },
        setUsageDataEndDate({commit}, endDate) {
            commit('setUsageDataEndDate', endDate)
        },
        setUsageMonth({commit}, month) {
            commit('setUsageMonth', month)
        },
        setHandlerLog({commit}, {handlerId, log}) {
            commit('setHandlerLog', {handlerId, log});
        },
        fetchHandlerRequests({rootState, state, commit}, handlerId) {
            commit('setCurrentRequest', null);
            commit('setHandlerRequests', []);
            let handlerUrl = rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/aio_handler_requests/sandboxes/${rootState.sandbox.currentSandbox}/${handlerId}`
            return axios.get(handlerUrl, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                if (response.data && response.data[0]) {
                    commit('setHandlerRequests', response.data);
                    commit('setCurrentRequest', response.data[0]);
                }
                return response;
            }).catch((e) => {
                return e;
            })
        },
        async fetchUsageData({rootState, dispatch, state, commit},usageType) {            
            commit('setUsageData', {
                ...state.usageData,
                [usageType]: {}
            });

            let payload = {
                startDate: state.usageDataStartDate,
                endDate: state.usageDataEndDate,
                product: 'aio',
                usageType: usageType
            };

            const data = await dispatch('usage/getProductUsage', payload, { root: true });

            if (data && data[payload.product]) {
                commit('setUsageData', {
                    ...state.usageData,
                    [usageType]: data[payload.product]
                });
            }

            return data;            
        },
        async createHandlerRequest({rootState, state}, payload){
            return axios.post(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/aio_handler_requests/sandboxes/${rootState.sandbox.currentSandbox}/${state.currentHandlerJob.name}`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            })
        },
        async readHandlerRequest({rootState, state}, id) {
            return axios.get(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/aio_handler_requests/sandboxes/${rootState.sandbox.currentSandbox}/${state.currentHandlerJob.name}/${id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        }, 
        async updateHandlerRequest({rootState, state}, payload) {
            return axios.put(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/aio_handler_requests/sandboxes/${rootState.sandbox.currentSandbox}/${state.currentHandlerJob.name}/${payload.id}`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteHandlerRequest({rootState, state}, payload) {
            return axios.delete(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/aio_handler_requests/sandboxes/${rootState.sandbox.currentSandbox}/${state.currentHandlerJob.name}/${payload.id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async performLint({rootState}, payload) {
            return axios.post(rootState.surfaceUrl + "/v1/tools/golint", payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.accessToken}`,
                    },
            })
        }, 
    }
}

export default store