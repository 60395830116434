<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddInitiatorField()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add Initiator Field
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields v-if="!message" :fields="fields" v-model="initiatorField" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
                <div v-if="message">
                    {{ message }}
                </div>
        </div>
        <div class="card-footer" v-if="!message">
            <div class="text-end">
                <submit-button :fields="fields" v-model="initiatorField" :loading="loading" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/initiatorFields";
export default {
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            message: "",
            initiatorField: {},            
            fields: Fields,
            loading:false
        }
    },
    name: "AddInitiatorField", 
    beforeMount(){
        var self=this;
        this.$store.dispatch("aut/fetchInitiators").then(()=>{
            this.fields.forEach((field)=>{
                if(field.name=="initiator"){
                    field.options=self.$store.state.aut.initiators.map((initiator)=>{
                        return {label: initiator.name, value: initiator.value }
                    })
                }
            })
        });
    },   
    methods: {
        ...mapActions('aut', {
            createInitiatorField: 'createInitiatorField'
        }),
        async onAddInitiatorField() {
            this.loading = true;
            try {
                await this.createInitiatorField(this.initiatorField)
                this.message = "Successfully created new Initiator Field" + this.initiatorField.name + ".";
            } catch (error) {                
                this.error = "Failed to create initiator field " + this.initiatorField.name + ". Please contact support.";
                
                return
            }

            this.loading = false;

            this.$store.dispatch('aut/fetchInitiatorFields')
        }
    }
};
</script>

<style>
</style>