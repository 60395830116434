<template>
    <div class="modal-card card">
      <div class="card-body">
        <div class="alert alert-warning mb-0" role="alert">
            <h3>Warning!</h3>
            <h4>You will be logged out due to inactivity in 1 minute.</h4>
        </div>        
      </div>
        <div class="card-footer">
          <button class="btn btn-primary" @click="stayLoggedIn()">Stay Logged In</button>
          <button class="ms-3 btn btn-secondary" @click="logout()">Log Out</button>
        </div>
      
    </div>
  </template>
  
  <script>
  export default {
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    methods: {
        stayLoggedIn(){
            this.$store.dispatch('hideModal'); 
            this.modalData.onConfirm();
        },
        logout() {
            this.$store.dispatch('hideModal'); 
            this.modalData.onCancel();
        }
    }
  };
  </script>
  
  <style scoped>
  
  </style>