<template>
    <detail-header pretitle="Products" :title="product ? product.sku : '&nbsp;'" :editable="true" :editMode="editMode"
        :fields="fields" v-model="product" @editClick="onEditClick" @deleteClick="onDeleteClick" @saveClick="onSaveClick"
        @cancelClick="onCancelClick" @closeClick="$router.push('/admin/products')">
    </detail-header>
    <div class="container-fluid">
        <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div v-if="successMessage" class="alert alert-success" role="alert">
            {{ successMessage }}
        </div>
        <div v-if="product">
            <div class="row">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <form-fields :fields="fields" v-model="product" :editMode="editMode" :inlineField="true"
                                colField="col-6" />
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            Entitlements for {{ product.sku }}
                        </h4>
                        <button class="btn btn-primary float-right"
                            @click="showModal({ component: 'addProductEntitlement', data: { product: product, list: productEntitlementList } })"><i class="fa fa-plus"></i></button>&nbsp;
                        <button class="btn btn-primary float-right" :disabled="editDisable"
                            @click="showModal({ component: 'editProductEntitlement', data: { product: product, list: productEntitlementList } })"><i class="fa fa-edit"></i></button>
                    </div>
                    <div class="card-body">
                        <Datatable :tableId="`${product.sku}EntilementTable`" :columns="columns" uniqueField="entitlement_id"
                            :data="productEntitlementList" :deletable="true" @deleteRows="showDelete" :compact="true"
                            :selectCheckboxes="true"
                            :formatColumn="formatColumn"
                            :detailColumn="{ baseUrl: 'entitlements/', name: 'Entitlement', identifier: 'entitlement_id' }" />
                    </div>

                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>
<script>
import DetailHeader from '../../components/DetailHeader.vue';
import Datatable from '../../components/Datatable.vue';
import Fields from "../../utils/field-constants/productFields";
import FormFields from '../../components/FormFields.vue';
import { mapActions } from 'vuex';
export default {
    name: "Product",
    components: { DetailHeader, FormFields, Datatable },
    data() {
        return {
            editMode: false,
            product: null,
            snapshot: null,
            error: null,
            successMessage: null,
            fields: Fields,
            columns: [
                { data: "name" },
                { data: "quantity" },
                { data: "effective_date", title: "Effective Date" },

            ],
            formatColumn: [
                {
                    index: 4,
                    type: "date",
                }
            ], 
        }
    },
    beforeMount() {
        this.fetchProduct();
        this.fetchProductEntitlementList(this.$route.params.id)
    },
    computed:{
        productEntitlementList(){
            return this.$store.state.admin.productEntitlementList
        },
        editDisable(){
            return this.productEntitlementList.length>0?false: true
        }
    },
    methods: {
        ...mapActions({
            readProduct: "admin/readProduct",
            updateProduct: "admin/updateProduct",
            deleteProduct: "admin/deleteProduct",
            showModal: "showModal",
            getProductEntitlementList: "admin/getProductEntitlementList"
        }),
        setEditMode(mode) {
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        async fetchProduct() {
            let response = {};
            if (!this.$route.params.data) {
                try {
                    response = await this.readProduct(this.$route.params.id);
                } catch (error) {
                    if (error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                }
            } else {
                response["data"] = JSON.parse(this.$route.params.data)
            }
            this.product = await response.data;
            this.snapshot = JSON.parse(JSON.stringify(this.product));
        },
        async fetchProductEntitlementList(productId) {
            let response;
            try {
                response = await this.getProductEntitlementList("product=" + productId);
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
            }
        },
        onCancelClick() {
            this.setEditMode(false);
            this.product = JSON.parse(JSON.stringify(this.snapshot));
            this.error = null;
        },
        onEditClick() {
            this.setEditMode(true);
        },
        onDeleteClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete Product",
                    message: `Are you sure you want to delete ${this.product.name}`,
                    async confirm() {
                        try {
                            const response = await self.deleteProduct(self.product.product_id);
                            self.$router.push("/admin/products");
                            return response;
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            return;
                        }
                    }
                }
            })
        },
        async onSaveClick() {
            let self = this;
            let productObj = this.product;
            try {
                const response = await this.updateProduct(productObj);
                this.setEditMode(false);
                this.snapshot = JSON.parse(JSON.stringify(productObj));
                this.successMessage = "Product updated successfully.";
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            }
        },
        showDelete(rows) {
            this.showModal({ component: "deleteProductEntitlements", data: { product: this.product, rows: rows } });
        }
    }
}
</script>
<style></style>