<template>
    <div class="modal-card card">
        <form @submit.prevent="onFormSubmit()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Delete Class</h4>
                <!-- Close -->
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="card-body">
                <p>Are you sure you want to delete the <b>{{clientClass.cls}}</b> class?</p>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger">
                    <div
                        v-if="deleteInProgress"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else><slot></slot>Delete</div>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            error: "",
            clientClass: this.modalData,
            deleteInProgress: false
        };
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    name: "DeleteClass",
    methods: {
        ...mapActions("aut", {
            deleteClass: "deleteAutClientClass",
        }),
        async onFormSubmit() {
            this.deleteInProgress = true;
            try {
                await this.deleteClass(this.clientClass)

            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            } finally {
                this.deleteInProgress = false;
            }

            this.$store.dispatch('hideModal')            
            this.$store.dispatch('setCurrentClient',this.$store.state.currentClient.cid); 
            this.$store.dispatch('aut/setCurrentClass',null )           
        },
    },
};
</script>

<style>
</style>