<template>
    <div class="modal-card card">
        <form @submit.prevent="onFormSubmit()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">Delete Sandbox</h4>
                <!-- Close -->
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="card-body">
                <p>Are you sure you want to delete the following sandboxes?</p>
                <ul class="list-group list-group-sm">
                    <li v-for="item in modalData" :key="item.index" class="list-group-item">
                        {{item.name}} 
                    </li>
                </ul>
                <p v-if="error" class="mt-2"><code>{{error}}</code></p>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger">
                    <div
                        v-if="deleteInProgress"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else><slot></slot>Delete</div>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            error: "",
            sandbox: {},
            deleteInProgress: false
        };
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    name: "DeleteSandboxes",
    methods: {
        ...mapActions("sandbox", {
            deleteSandbox: "deleteSandbox",
        }),
        async onFormSubmit() {
            this.deleteInProgress = true;
            let deletePromiseArray = [];    
            for (const sandbox of this.modalData) {        
                deletePromiseArray.push( this.deleteSandbox(sandbox.name));
            }        
            try {
                await Promise.all(deletePromiseArray);               
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            } finally {
                this.deleteInProgress = false;
            }

            this.$store.dispatch("hideModal");            
            this.$store.dispatch("sandbox/fetchSandboxes");
        },
    },
};
</script>

<style>
</style>