<template>
  <div v-if="formattedMetricData">
    <div
      v-for="(metricDataObj, key) in formattedMetricData"
      :key="key"
      class=""
    >
      <div class="card mb-4" v-if="metricDataObj.data">
        <div class="card-body" >
            <div
            class="row align-items-center mb-2 py-3 flex-nowrap custom-flex-row justify-content-between"
            >
            <div class="col-auto flex-grow-1">
                <h3 v-if="key && metricDataObj.data" class="mb-0">
                {{ key.replace(/-/g, " ").replace(/amdp/gi, "aMDP") }}
                </h3>
            </div>
            <div
                class="col-auto"
                v-if="jobKind == 'MAPPER-ΜBATCH' && key == 'amdp-metric'"
            >
                <div class="input-group">
                <span class="input-group-text">Time Range</span>
                <InputField
                    colField="mb-0"
                    :editable="true"
                    :label="null"
                    :showLabel="false"
                    :options="timeRangeOptions"
                    type="select"
                    :modelValue="timeRange"
                    @change="timeRangeChange($event)"
                />
                </div>
            </div>
            <div
                v-if="timeRange === 'custom_range' && key == 'amdp-metric'"
                class="col-auto d-flex align-items-center"
            >
                <div class="input-group me-2">
                <span class="input-group-text">
                    <i class="fas fa-calendar"></i
                ></span>

                <InputField
                    colField="mb-0"
                    :modelValue="customStartTime"
                    :showLabel="false"
                    type="calendar"
                    :input-config="dateConfig"
                    @update:modelValue="startTimeChange($event)"
                />
                </div>
                <span class="mx-2">-</span>
                <div class="input-group me-3">
                <span class="input-group-text">
                    <i class="fas fa-calendar"></i
                ></span>

                <InputField
                    colField="mb-0"
                    :modelValue="customEndTime"
                    :showLabel="false"
                    type="calendar"
                    :input-config="dateConfig"
                    @update:modelValue="endTimeChange($event)"
                />
                </div>
                <button class="btn btn-primary" @click="getCountV2Metrics">
                Apply
                </button>
            </div>
            </div>
            <div v-if="metricDataObj.data" class="position-relative">
            <div class="position-absolute top-2 end-0 mt-4 me-2">
                <div class="custom-control form-check form-switch">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    :id="`customSwitch_${key}`"
                    :checked="showDataInUnits[key]"
                    @change="updateShowDataInUnits(key, $event.target.value)"
                />
                <label class="form-check-label" :for="`customSwitch_${key}`"
                    >Show Abbreviated Data</label
                >
                </div>
            </div>

            <Datatable
                class="mb-3"
                v-if="metricDataObj.data"
                :tableId="`${executionId}-${key}`"
                :showLoading="metricDataObj === null"
                :compact="true"
                :columns="metricDataObj.headers"
                :data="metricDataObj.data"
                :paging="metricDataObj.data.length > 10"
            ></Datatable>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h4>No metric data found!</h4>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Datatable from "../Datatable.vue";
import InputField from "../../components/InputField.vue";
import { formatBytes, DateTimeFormat } from "../../utils/commonFunction";
dayjs.extend(utc);
export default {
  components: { Datatable, InputField },
  data() {
    return {
      metricColumns: [],
      showDataInUnits: {},
      formattedMetricData: null,
      timeRangeOptions: [
        { value: "last_hour", label: "Last Hour" },
        { value: "overall", label: "Overall" },
        { value: "custom_range", label: "Custom" },
      ],
      startTimeRange: null,
      endTimeRange: null,
      dateConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i:S",
        altInput: true,
        altFormat: "F j, Y at h:i K", // This format is for display
        formatDate: (date, format) => {
          if (format === "Y-m-d H:i:S") {
            // This is for the hidden input (UTC)
            return this.formatUTCDate(date);
          }
          // This is for the visible input (local time)
          return this.formatLocalDate(date);
        },
        onChange: (selectedDates, dateStr, instance) => {
          if (selectedDates[0]) {
            this.utcDate = this.formatUTCDate(selectedDates[0]);
            this.$emit("update:modelValue", this.utcDate);
          }
        },
      },
    };
  },
  methods: {
    formatData() {
      this.formattedMetricData = Object.fromEntries(
        Object.entries(this.metricData)
          .sort(([titleA], [titleB]) => {
            const order = {
              "amdp-metric": 1,
              "amdp-performance-metric": 2,
              "amdp-job-run-params": 3,
            };
            return (order[titleA] || Infinity) - (order[titleB] || Infinity);
          })
          .map(([title, value]) => {
            if (typeof value === "object" && value.data && value.headers) {
              return [
                title,
                {
                  ...value,
                  headers: value.headers.map((key) => {
                    let self = this;
                    const tempKey = key;
                    let obj = {};
                    if (key == "byte_count" || key.includes("memory")) {
                      obj = {
                        data: key,
                        title: tempKey.replace(/-/g, ""),
                        render: function (data, type, row) {
                          if (!data) return "";
                          if (!self.showDataInUnits[title]) return data;
                          return formatBytes(data);
                        },
                      };
                    } else if (key == "time") {
                      obj = {
                        data: key,
                        title: tempKey.replace(/-/g, ""),
                        render: function (data, type, row) {
                          if (!data) return "";
                          return DateTimeFormat(data);
                        },
                      };
                    } else if (key == "record_count") {
                      obj = {
                        data: key,
                        title: tempKey.replace(/-/g, ""),
                        render: function (data, type, row) {
                          if (!data) return "";
                          if (!self.showDataInUnits[title]) return data;
                          return Intl.NumberFormat("en", {
                            notation: "compact",
                            roundingMode: "floor",
                            maximumFractionDigits: 2,
                          }).format(data);
                        },
                      };
                    } else {
                      obj = {
                        data: key,
                        title: tempKey.replace(/-/g, ""),
                      };
                    }

                    return obj;
                  }),
                  data: value.data.map((row) => {
                    const obj = {};
                    row.forEach((rowValue, index) => {
                      obj[value.headers[index]] = rowValue;
                    });
                    return obj;
                  }),
                },
              ];
            }
            return [title, value];
          })
      );
    },
    updateShowDataInUnits(key) {
      this.showDataInUnits[key] = !this.showDataInUnits[key];
      this.formatData();
    },
    getCountV2Metrics() {
      this.$emit(
        "callGetCountV2Metrics",
        this.timeRange,
        this.startTimeRange,
        this.endTimeRange
      );
    },
    timeRangeChange(timeRange) {
      this.$emit("onTimeRangeChange", timeRange);
      this.dateConfig.minDate = this.startedAt;
      this.dateConfig.maxDate = this.completedAt;
      if (timeRange == "last_hour") {
        this.startTimeRange = dayjs
          .utc(this.completedAt)
          .startOf("hour")
          .format("YYYY-MM-DD HH:mm:ss");
        this.endTimeRange = dayjs
          .utc(this.completedAt)
          .add(1, "hour")
          .startOf("hour")
          .format("YYYY-MM-DD HH:mm:ss");
        this.getCountV2Metrics();
      } else if (timeRange == "overall") {
        this.getCountV2Metrics();
      } else {
        this.startTimeRange = dayjs
          .utc(this.startedAt)
          .format("YYYY-MM-DD HH:mm:ss");
        this.endTimeRange = dayjs
          .utc(this.completedAt)
          .format("YYYY-MM-DD HH:mm:ss");
        this.$emit("apply-custom-range", {
          startTime: this.startedAt,
          endTime: this.completedAt,
        });
      }
    },
    startTimeChange(time) {
      this.startTimeRange = time;
      this.$emit("apply-custom-range", {
        startTime: this.formatUTCString(time),
        endTime: this.customEndTime,
      });
    },
    endTimeChange(time) {
      this.endTimeRange = time;
      this.$emit("apply-custom-range", {
        startTime: this.customStartTime,
        endTime: this.formatUTCString(time),
      });
    },
    formatUTCDate(date) {
      return (
        date.getUTCFullYear() +
        "-" +
        String(date.getUTCMonth() + 1).padStart(2, "0") +
        "-" +
        String(date.getUTCDate()).padStart(2, "0") +
        " " +
        String(date.getUTCHours()).padStart(2, "0") +
        ":" +
        String(date.getUTCMinutes()).padStart(2, "0") +
        ":" +
        String(date.getUTCSeconds()).padStart(2, "0")
      );
    },
    formatLocalDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day}  ${hours}:${minutes}:${seconds}`;
    },
    formatUTCString(utcString) {
      // Parse the input UTC string
      const date = new Date(utcString + "Z"); // Appending 'Z' to ensure it's treated as UTC

      // Format to ISO 8601 UTC string
      return date.toISOString();
    },
  },

  beforeMount() {
    // Initialize showDataInUnits with true for each key in metricData
    Object.keys(this.metricData).forEach((key) => {
      this.showDataInUnits[key] = true;
    });
    this.formatData();
  },
  props: [
    "metricData",
    "jobKind",
    "startedAt",
    "completedAt",
    "timeRange",
    "customEndTime",
    "customStartTime",
    "executionId"
  ],
};
</script>

<style>
.custom-flex-row {
  flex-wrap: nowrap;
  overflow-x: auto;
}
</style>