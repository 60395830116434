<template>
    <div v-if="$store.state.currentClient">
        <BasicHeader pretitle="Client Dashboard"
            :title="`${$store.state.currentClient.name} <code class='badge bg-secondary'>${$store.state.currentClient.cid}</code>`"
            :sideTitle="`Welcome, ${$store.state.currentUser.name}!`" />

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8">

                    <div class="row">

                        <div v-if="hasAUT" class="col-6">
                            <div class="card pe-none">
                                <div class="card-body">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <i class="fa fa-tag display-4"></i>
                                        </div>
                                        <div class="col ms-n2">
                                            <h4 class="mb-1">
                                                aUT
                                            </h4>
                                            <p class="small text-muted mb-0">
                                                <time datetime="2018-06-21">aqfer Universal Tag</time>
                                            </p>
                                        </div>
                                        <div class="col-auto">
                                            <p class="card-text">
                                                <span v-if="isEnabled"><span class="text-success">●</span> Enabled</span>
                                                <span v-else><span class="text-danger">●</span> Disabled</span>
                                            </p>
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div>

                        <div v-if="hasAMDP" class="col-6">
                            <div class="card pe-none">
                                <div class="card-body">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <i class="fa fa-database display-4"></i>
                                        </div>
                                        <div class="col ms-n2">
                                            <h4 class="mb-1">
                                                aMDP
                                            </h4>
                                            <p class="small text-muted mb-0">
                                                <time datetime="2018-06-21">aqfer Marketing Data Platform</time>
                                            </p>
                                        </div>
                                        <div class="col-auto">
                                            <p class="card-text">
                                                <span v-if="isEnabled"><span class="text-success">●</span> Enabled</span>
                                                <span v-else><span class="text-danger">●</span> Disabled</span>
                                            </p>
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>

                    <!-- Tag Chart -->
                    <div class="row" v-if="hasAUT">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <span class="card-header-title">
                                         <strong>Overall Hourly Tag Counts (UTC)</strong>
                                        <router-link class="small ms-1" :to="`/${cid}/aut/dashboards`">View More</router-link>
                                    </span>                                    
                                    
                                    <span class="text-secondary me-3">
                                        Display Current Hour:
                                    </span>
                                    <div class="custom-control form-switch">
                                        <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="showCurrentHour">
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div v-if="errMsg">
                                        <p>{{errMsg}}</p>
                                    </div>                                   
                                    <!-- Chart -->
                                    <div v-if="!graphLoading" class="chart-view" >
                                        <canvas  id="countChart" 
                                                class="chart-canvas"
                                                width="843"
                                                height="300"
                                                style="
                                                    display: block;
                                                    box-sizing: border-box;
                                                    height: 300px;
                                                    width: 843px;
                                                "
                                        >
                                        </canvas>
                                    </div>
                                    <div v-else class="text-center">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>      
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-4">

                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-header-title">Insights Blog</h4>
                            <a href="https://aqfer.com/blog/" target="_blank" class="small">View All</a>
                        </div>
                        <div class="card-body"> 
                            <div v-if="!blogLoading">
                                <BlogList :blogList="blogList.slice(0, 3)"/>
                            </div>
                            <div v-else class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-header-title">Recent Changelog</h4>
                            <router-link class="small" to="/changelog">View More</router-link>
                        </div>
                        <div class="card-body">
                            <ChangeLogList :logList="logList.slice(0,3)"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div v-else>
        <p class="text-center mt-5">No clients available for <b>{{$store.state.currentUser.email}}</b></p>
    </div>
</template>

<script>

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);


import BasicHeader from "../components/BasicHeader.vue";
import ChangeLogList from "../components/ChangeLogList.vue";
import BlogList from "../components/BlogList.vue";
import dayjs from "dayjs";
import axios from 'axios';
import {mapState} from "vuex";
import data from "../../changelog.yaml"
export default {
    components: { BasicHeader,ChangeLogList,BlogList },
    name: "Dashboard",
    data() {
        return {
            graphLoading: true,
            errMsg: null,
            logList:data.changelog,
            blogList:[],
            blogLoading: true,
            showCurrentHour: false
        }

    },
    computed: {
     ...mapState({    
            cid: state => state.currentClient.cid,    
        }),
        thisDay() {
            return dayjs().format('YYYYMMDD');    
        },
        previousDay() {
             return dayjs().subtract(1, "day").format('YYYYMMDD');
        },
        lastweek_thisday() {
            return dayjs().subtract(7, "day").format('YYYYMMDD');
        },
        hasAUT() {
            let features = this.$store.state.currentClient.config.features;
            if (features.some(e => e.name === 'TAG_MANAGER')) {
                return true
            }
            return false
        },
        hasAMDP() {
            let features = this.$store.state.currentClient.config.features;
            if (features.some(e => e.name === 'DATA_LAKE')) {
                return true
            }
            return false;
        },
        isEnabled() {
            return this.$store.state.currentClient.enabled;
        },
    },
    watch: {
        showCurrentHour() {
            this.startDrawHourlyGraph('countChart',this.tempObjT, this.tempObjY, this.tempObjLWT );
        },
    },
    methods: {
        async generateDashboard() {
            this.graphLoading = true;
            this.tempObjLWT= [];
            this.tempObjT= [];
            this.tempObjY= []; 

            const dates = `${this.lastweek_thisday},${this.previousDay},${this.thisDay}`;
            let response;
            try {
                response = await this.countsByDates(
                            "",
                            dates
                            )
            } catch(error) {
                this.graphLoading = false;
                if (error.response && error.response.data.error)
                    this.errMsg = error.response.data.error;
                else this.errMsg = error.message;
                return;
            }
            
            this.graphLoading = false;            
            if(!response.data || Object.keys(response.data).length === 0) {                    
                this.errMsg = "No Data Found";
            } else {
                
                let countsData = await response.data;

                //remove most recent hour to not skew the graph (deleting last key in object)
                //delete countsData[Object.keys(countsData)[Object.keys(countsData).length-1]];

                for (var hr in countsData) {                    
                    if (this.$store.state.environment !== "test" || hr.length > 8) {
                        switch (hr.substring(0, 8)) {
                        case this.lastweek_thisday:
                            this.tempObjLWT.push({hour:hr.substr(-2), count: countsData[hr].count });
                            break;
                        case this.thisDay:
                            this.tempObjT.push({hour:hr.substr(-2), count: countsData[hr].count });
                            break;
                        case this.previousDay:
                            this.tempObjY.push({hour:hr.substr(-2), count: countsData[hr].count });
                            break;
                        default:      
                            this.tempObjY.push({hour:hr.substr(-2), count: countsData[hr].count }); 
                            break;
                        }
                    }                    
                } 
                this.startDrawHourlyGraph('countChart',this.tempObjT, this.tempObjY, this.tempObjLWT );
            }
                 
        },
        startDrawHourlyGraph(elementId, thisDayData, previousDayData, prevWeekThisDayData ) {
            if(!thisDayData || !previousDayData ||  !prevWeekThisDayData)
                return;

            const countChart = document.getElementById(elementId);
            const chart = Chart.getChart(elementId);            
            let currentDayData = JSON.parse(JSON.stringify(thisDayData));

            if(chart) {
                chart.destroy();
            }

            //remove most recent hour to not skew the graph 
            if(!this.showCurrentHour) {
                currentDayData.pop();
            } 
            
            const currentDayDataSetLength = currentDayData.length?currentDayData.length:0;
     

            const labels = ["00","01","02","03","04","05","06", "07", "08", "09", "10", "11","12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
            const gData = {
                labels: labels,
                datasets: [{
                    label: 'Previous Week This Day',
                    backgroundColor: 'rgb(255, 99, 132)', 
                    borderColor: 'rgb(255, 99, 132)',
                    data: prevWeekThisDayData? prevWeekThisDayData.map((obj) => { 
                        return {'x':obj.hour, 'y':obj.count}
                    }) : 0,
                    pointStyle: 'circle',
                    pointRadius: 3
                },{
                    label: 'Previous Day',
                    backgroundColor: 'rgb(12, 196, 2)',
                    borderColor: 'rgb(12, 196, 2)',
                    data: previousDayData? previousDayData.map((obj) => { 
                        return {'x':obj.hour, 'y':obj.count}
                    }): 0,
                    pointStyle: 'circle',
                    pointRadius: 3
                },{
                    label: 'This Day',
                    backgroundColor: 'rgb(1, 94, 255)',
                    borderColor: 'rgb(1, 94, 255)',
                    data: currentDayData? currentDayData.map((obj) => { 
                        return {'x':obj.hour, 'y':obj.count}
                    }): 0,
                    pointStyle: 'circle',
                    segment: { 
                        borderDash: ctx =>  ((currentDayDataSetLength - 1 === ctx.p1DataIndex) && this.showCurrentHour)?[3,3]: []  
                    },
                    animations: {
                        radius: {
                            duration: 1000,
                            easing: 'linear',                            
                            to: 3,
                            loop: true
                        }
                    }
                }                   

                ]
            };

            const options= {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                    },
                    title: {
                        display: false,
                        text: 'Hourly Chart'
                    },
                    tooltip: {
                        usePointStyle: true,
                    }
                },
                elements: {
                    point: {
                        radius: (ctx) => (((ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.index)&& this.showCurrentHour) ? 6 : 3)
                    },                    
                },       
            }

            const config = {
                type: 'line',
                data: gData,
                options: options
            }

            if (countChart) {
                new Chart(countChart, config);
            }            

        },
        async countsByDates(clsString, dates) {
            return axios.get(
                `${this.$store.state.countsUrl}/v1/cids/${this.cid}${clsString}/dates/${dates}?hourly=true&skipCountry=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
        }, 
        async getBlogList() {
            this.blogLoading=true;
            this.$store.dispatch("getBlogData").then(response=>{
                this.blogLoading = false;   
                this.blogList=response;
            }).catch((e) => {
                this.blogLoading = false; 
                this.blogList = [];
            })
        }   

    },
    mounted() {
        this.generateDashboard();
        this.getBlogList();
    },
};
</script>

<style>

</style>