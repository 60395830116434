<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddjobTemplateGroup()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Add Job Template to Group
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">
                <InputField label="Job Template Name" type="select" v-model="jobTemplateId" :editable="true"
                    :options="jobTemplateOptions" :validate="[{ type: 'required', errMsg: 'select job template' }]" />

                <InputField label="Execution Order" type="number" v-model="executionOrder" :editable="false"
                    />

            </div>
            <div class="card-footer">
                <div class="text-end">
                    <button class="btn btn-primary" :disabled="btnDisable" type="submit">Add</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import InputField from "../InputField.vue";
import { mapActions } from 'vuex';
import { isDuplicate } from '../../utils/commonFunction';
export default {
    components: { InputField },
    name: "AddjobTemplateMap",
    data() {
        return {
            jobTemplateId: 0,
            jobTemplateOptions: []
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    computed: {
        jobTemplateGroup() {
            return this.modalData.jobTemplateGroup ? this.modalData.jobTemplateGroup : { job_template_id: 0, id: 0 }
        },
        jobTemplates() {
            return this.$store.state.admin.jobTemplates
        },
        btnDisable(){
            return !(this.jobTemplateId)
        }
    },
    beforeMount() {
        if (!this.jobTemplates.length) {
            this.$store.dispatch("admin/fetchJobTemplates");
        }
        this.jobTemplateOptions = this.jobTemplates.map((item) => {
                return { label: item.name, value: item.id }
        })
        let list= this.modalData.list
        if(list)
            list.sort((a, b) => a["execution_order"]- b["execution_order"]);
        this.executionOrder = list?Number(list[list.length-1]["execution_order"])+1:1
    },
    watch:{
        jobTemplates(newVal){
            this.jobTemplateOptions = newVal.map((item) => {
                return { label: item.name, value: item.id }
            })
        }
    },
    methods: {
        ...mapActions('admin', {
            createJobTemplateGroupMapping: 'createJobTemplateGroupMapping'
        }),
        async onAddjobTemplateGroup() {
            let valid = false;
            let toastoptions;
            let data = {
                job_template_id: this.jobTemplateId,
                job_template_group_id: this.jobTemplateGroup.id,
                execution_order: this.executionOrder
            }
            try {
                //checking whether this job template  is already mapped to this job template group
                if(this.modalData.list)
                    valid = !isDuplicate(this.jobTemplateId,this.modalData.list, "job_template_id", null)
                else valid =true
                if (valid) {
                    await this.createJobTemplateGroupMapping(data)
                    toastoptions = {
                        message: "Job template added to template group.",
                        type: "success",
                    };
                } else {
                    if (!valid) {
                        toastoptions = {
                            message: "Already job template exits in template group.'",
                            type: "error",
                        };
                    }
                }
            } catch (error) {
                toastoptions = {
                    message: "Something went wrong when adding the tag. Please contact support.",
                    type: "error"
                }
            }
            this.$store.dispatch('addToast', toastoptions);
            if (valid) {
                this.$store.dispatch('hideModal')
                let response =await this.$store.dispatch("admin/getJobTemplateGroupMappings", this.jobTemplateGroup.id );
                this.modalData.onSaveChange(response.data);
            }
        }
    }
};
</script>

<style></style>