<template>
    <BasicHeader 
        pretitle="aqfer Marketing Data Platform" 
        title="Credentials" 
        buttonText="Add Credential"
        @btnClick="showModal({ component: 'AddCredential' })" />
    <div class="container-fluid">
        <div class="card">
            <Datatable 
                tableId="credentials" 
                :columns="columns"
                uniqueField="name"
                :data="this.$store.state.amdp.credentials"
                @deleteRows="showDeleteCredentials"
                :deletable = "isCredentialDelete"
                :compact="true"
                :selectCheckboxes="isCredentialDelete"
                :detailColumn="{baseUrl:'credentials/', name:'Credential', identifier:'name'}"
                 />
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions } from "vuex";
export default {
    components: { Datatable, BasicHeader },
    name: "Credentials",
    data() {
        return {
            columns: [
                {
                    data: "name",
                    title: "Credential Name",
                },
                {
                    data: "type",
                    title: "Credential Type",
                },
            ],
            allowedUserRoles:[1,3]
        };
    },
    computed: {
        isCredentialDelete() {
            return this.isAccessByUserRole(this.allowedUserRoles);
        }

    },
    methods: {
        ...mapActions({
            showModal: "showModal",
        }),
        handleSelect(data) {
            this.$store.dispatch("amdp/setCurrentCredential", data);
        },
        showDeleteCredentials(rows) {
            this.showModal({ component: "DeleteCredentials", data: rows });
        },
        isAccessByUserRole(roles) {
            return this.$store.getters.userRoles.isAccessByRole(roles);
        } 
    },
    beforeMount() {
        this.$store.dispatch("amdp/fetchCredentials");
        this.$store.dispatch("amdp/fetchCredentialTypes");
    },
};
</script>

<style>

</style>