<template>
    <basic-header pretitle="aqfer Universal Tag" title="Classes" />
    <div v-if="currentClient" class="container-fluid">
        <div>
            <div id="classes-div">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-2">Choose a Class</div>
                        <button
                            v-for="classItem in classItems"
                            :key="classItem.cls == 'NA' ? 'default' : classItem.cls"
                            @click="getCurrentClassData(classItem.cls)"
                            class="btn class-btn card-header-title"
                            :class="[currentCls == classItem.cls ? 'active btn-primary' : 'btn-secondary']"
                        >
                            {{ classItem.cls == "NA" ? "default" : classItem.cls }}
                        </button>
                        <button  v-if=isAccessByUserRole(classAddAdminRoles) @click="onAddClassClick" class="btn class-btn btn-outline-secondary "><i class="fa fa-plus" /></button>
                    </div>
                </div>
                <Tabs :tabs="tabItems" v-if="classItems.length">
                    <template v-slot:tab-action-buttons>
                        <span v-if="editMode">
                            <button
                                @click.prevent="onSaveClick();"
                                class="btn btn-success"
                            >
                                <i class="fa fa-floppy-disk"></i></button
                            >&nbsp;                            
                            <button
                                v-if=isAccessByUserRole(classDeleteAdminRoles)
                                @click.prevent="onDeleteClick();"
                                class="btn btn-danger"
                            >
                                <i class="fa fa-trash-can"></i></button
                            >&nbsp;
                            <button
                                @click.prevent="onCancelClick()"
                                class="btn btn-secondary"
                            >
                                <i class="fa fa-circle-xmark"></i></button
                            >&nbsp;
                        </span>
                        <span v-else>
                            <button :disabled ="userRoleRank < 4 ? false:true"
                                type="button"
                                @click.prevent="onEditClick();"
                                class="btn btn-primary"
                            >
                                <i class="fa fa-pen-to-square"></i>&nbsp;
                                <i v-if="userRoleRank >= 4" class="fa fa-circle-exclamation" title="Your user do not have permission .Please contact support" data-bs-toggle="tooltip" data-bs-placement="right">                                    
                                    
                                </i>
                            </button
                            >&nbsp;
                        </span>
                    </template>
                    <template v-slot:settings>
                        <div class="row" v-if="!loading">
                            <div class="col-md-12">                                                           
                                <div class="row"  id="cls-dtl-div">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-body" v-if="classObject">                                                
                                                <form-fields
                                                    :fields="classFields"
                                                    v-model="classObject"
                                                    :inlineField="true"
                                                    :editMode="editMode"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>	
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-md-12">
                                <div  class="text-center mt-5">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </template>
                    <template  v-slot:pixels>
                        <div id="pixel-div">                        
                            <div id="pixel-dtl-div" class="mt-3" v-if="classObject && classObject.baseClass === null">                                
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="mb-3" v-if="pixels.length">
                                            <label class="input-label"
                                                >Filter by Country</label
                                            >
                                            <select
                                                class="form-select"
                                                v-model="selectedCountry"
                                            >
                                                <option value="ALL">All</option>
                                                <option
                                                    v-for="option in countries"
                                                    :key="option.id"
                                                    :value="option.id"
                                                >
                                                    {{ option.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="" v-if="pixels.length">
                                            <list-group
                                                id="pixel-list"
                                                :editMode="editMode"
                                                :title="'Pixel List'"
                                                :list="pixels"
                                                :activeListItem="currentPixel.beaconId"
                                                listItemKey="beaconId"
                                                :listItemTitle= "getPixelListItemTitle"
                                                :orderList="true"
                                                :actionDropdown="true"
                                                @deleteBtnClick="onPixelDelete"
                                                @selectBtnclick="showPixel"
                                                @reOrderList="reOrderPixel"
                                            ></list-group>
                                        </div>
                                        <div v-if="editMode" >
                                            <div :class="[pixels.length?'text-center':'']">
                                                <button
                                                    @click="addPixel"
                                                    class="btn btn-primary"
                                                    type="button"
                                                >
                                                    Add Pixel
                                                </button>&nbsp;
                                                <button
                                                    v-if="pixels.length"
                                                    @click.prevent="onCopyClick();"
                                                    class="btn btn-primary"
                                                    type="button"
                                                >
                                                Copy Pixel</button>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="card" v-if="pixels.length && pixelData">
                                            <div class="card-body">
                                                <form-fields
                                                    :fields="pixelFields"
                                                    v-model="pixelData"
                                                    :inlineField="true"
                                                    :editMode="editMode"
                                                    :key="newPixelKey"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="classObject&&classObject.baseClass !== null" class="row">
                                <div class="col-md-12 mt-3"><p>This class is inheriting {{classObject.maxPixels }} {{ classObject.maxPixels == 1? "pixel":"pixels" }} from the "{{ classObject.baseClass }}" class</p></div>
                            </div>
                            <div v-else-if="!pixels.length && !editMode" class="row"> <div class="col-md-12 mt-3"><h3>Click edit to add a pixel</h3></div></div>
                        </div>
                                
                    </template>
                    <template  v-slot:maps>
                        <div id="map-div">
                            <div id="map-dtl-div" class="mt-3">
                                <div class="row">
                                    <div class="col-md-3">
                                        <list-group
                                            id="map-list"
                                            v-if="mapData && maps"
                                            :editMode="editMode"
                                            :title="'Maps List'"
                                            :list="maps"
                                            listItemKey="name"
                                            :activeListItem="mapData.name"
                                            @deleteBtnClick="onMapDelete"
                                            @selectBtnclick="showMap"
                                        ></list-group>
                                        <div v-if="editMode">
                                            <button
                                                @click="addMap"
                                                class="btn btn-primary"
                                                type="button"
                                            >
                                                Add Map
                                            </button>
                                        </div>                            
                                    </div>                        
                                    <div class="col-md-9">
                                        <div class="card" v-if="mapData" >
                                            <div class="card-body" >
                                                <form-fields
                                                    :fields="mapFields"
                                                    v-model="mapData"
                                                    :inlineField="true"
                                                    :editMode="editMode"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!mapData && !editMode" class="row"> <div class="col-md-12"><h3>Click edit to add a map</h3></div></div>
                            </div>
                        </div>                    
                    </template>
                </Tabs>
                
            </div>
            <div v-if="!loading"> 
            </div>
        </div>
        <!-- <div v-else>
            <h3>There are no classes available for this deployment</h3>
        </div> -->
    </div>
    <div v-else class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script>
import Tabs from "../../components/Tabs.vue";
import BasicHeader from '../../components/BasicHeader.vue';
import getclassFields from "../../utils/field-constants/autClassFields";
import getMapFields from "../../utils/field-constants/autMapFields";
import getPixelFields from "../../utils/field-constants/autPixelFields";
import FormFields from "../../components/FormFields.vue";
import ListGroup from "../../components/ListGroup.vue";
import { mapActions, mapState } from "vuex";
export default {
    components: { FormFields, ListGroup, BasicHeader, Tabs },
    data() {        
        return {              
            editMode: false, 
            selectedCountry:'ALL',
            classFields: [], 
            currentCls: null,
            currentClassObj:{},    
            currentPixel:{},
            currentMap:{},
            newMapKey: 1,
            newPixelKey: 0,
            snapshot: null,
            error: "",
            successMessage: "",
            mapFields: [],
            pixelFields: [],
            mapData: null,
            pixelData: null,
            pixels: [],
            maps: [],
            loading: true,
            classAddAdminRoles: [1,3],
            classDeleteAdminRoles: [1],
            deprecatedFields: ['gpp.enabled', 'gvlId', 'us_privacy_enabled',],
        };
    },
    watch: {              
        "mapData": {
            handler: function(newMapData) {
                if(newMapData) {
                    let self = this;
                    const mapJSON = this.toMapJSON(newMapData);

                   this.maps = this.maps.map((map) => {
                       if(map.name == self.currentMap.name) 
                           return mapJSON;
                        else 
                           return map;
                   });
                  this.currentMap = JSON.parse(JSON.stringify(newMapData));
                }                
            },
            deep: true       
        },  
        "pixelData": {
            handler: function(newPixelData) {
                if(newPixelData && Object.keys(newPixelData).length) { 
                    if(newPixelData.gpp && newPixelData.gpp.tcf) {
                        newPixelData.gpp.tcf.gvlId =   (newPixelData.gpp.tcf?.status == "DISABLED"||newPixelData.gpp.tcf?.gvlId == '')? null:Number(newPixelData.gpp.tcf?.gvlId);                                      
                    }                    
                    this.currentPixel = newPixelData;
                    if(Object.keys(newPixelData).length) {
                        this.pixelFields = this.hideFields(  this.pixelFields, newPixelData);
                    }                    
                }                
            },
            deep: true       
        }, 
        "selectedCountry": {
            handler: function(newVal) {
                if(newVal) {
                    this.filterPixelByCountry();
                }                
            }

        },
        "currentClassData": {
            handler: function(newVal) {
                if(newVal) {                    
                    this.loading = false;                    
                    this.snapshot = JSON.parse(JSON.stringify(newVal));
                    let pixels = (newVal.pixels)?newVal.pixels.items:[];  
                    this.pixels = JSON.parse(JSON.stringify(pixels));
                    this.currentPixel = this.pixels[0] || {};
                    this.showPixel(this.currentPixel);

                    let maps = ((newVal.maps)?newVal.maps:{});
                   
                    let mapsArr = [];
                    for (const [key, value] of Object.entries(maps)) {
                        mapsArr.push({name:key, ...value});
                    }
                    this.maps = mapsArr;
                    this.mapData = mapsArr[0];
                    if(this.maps.length) {
                        this.showMap(mapsArr[0]);
                    }
                }              
            },
            deep: true
        },
        "currentClass": {
            handler(newData) {
                if(!newData[this.$store.state.currentClient.cid])
                    this.initiateClass();
                else
                    this.currentCls = newData[this.$store.state.currentClient.cid];                
            },
            deep: true
        },
        

    },   
    computed: {  
        ...mapState({ 
            cid: state => state.currentClient.cid,
            currentClient: state => state.currentClient,  
            countries: state => state.aut.countries,
            currentClass: state => state.aut.currentClass,
            currentClassData: state => state.aut.currentClassData,
            classItems: state => state.currentClient.classes.items||[]
           
        }), 
        classObject: {
            get() {
                let clsObj = JSON.parse(JSON.stringify(this.$store.state.aut.currentClassData ));
                if(clsObj) {
                    clsObj.loadType = (clsObj.pixelBatchSize == 0) ? "BATCH_LOAD" : "LAZY_LOAD";  
                    clsObj.logCookies = clsObj.log_cookies ? (clsObj.log_cookies.client ? ((clsObj.log_cookies.client.length == 1 && clsObj.log_cookies.client[0] == '*') ? 'ALL' : 'SPECIFIC') : 'ALL') : 'ALL';
                    clsObj.cookieList = clsObj.logCookies === 'SPECIFIC' ? clsObj.log_cookies.client : []  
                }                
                return clsObj;
            },
            set(val) {
                let self = this;
                // Hide/show fields based on other field values 
                this.classFields = this.hideFields( this.classFields, val );
                //BaseClass fiels options are dependent on current Class
                this.classFields = this.classFields.map(item => {
                                    if(item.name == "baseClass"){
                                            item.options = [{label:"NONE", value: null}];
                                            self.classItems.forEach((e) => {
                                                if (e.cls != self.currentCls) {
                                                    item.options.push({ label: e.cls == 'NA' ? 'default' : e.cls, value: e.cls })                                       
                                                }
                                            })
                                        }
                                        return item;
                                    });
            }            
        },
        userRoleRank() {
            return this.$store.getters.userRoles.userRoleRank;
        },
        tabItems() {
            return [
                {
                    name: "Settings",
                    label: "Settings",
                    id: "id1",
                    slot: "settings"
                },
                {
                    name:"Pixels",
                    label: `Pixels ${this.classObject&&this.classObject.baseClass===null?this.pixels?`(${this.pixels.length})`:(0):''}`,
                    id: "id2",
                    slot: "pixels"
                },
                {
                    name: "maps",
                    label: `Maps (${this.maps?this.maps.length:0})`,
                    id: "id3",
                    slot: "maps"
                }

            ]

        }
    },
    methods: {
        ...mapActions({
            updateAutClientClass: "aut/updateAutClientClass",
            fetchAutClassData: "aut/fetchAutClassData",            
            showModal: "showModal"
        }),
        setEditMode(mode) {
            let self = this;            
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        onAddClassClick() {
            this.showModal({component:"AddClass", data:{}});
        },
        onDeleteClick() {
            this.showModal({component:"DeleteClass", data: {classObject:this.classObject, cls:this.currentCls}});
        },
        onEditClick() {
            this.setEditMode(true);            
        },
        onCancelClick() {
            this.setEditMode(false);
            this.$store.dispatch("aut/setAutClassData", this.snapshot);
        },
        getCurrentClassData(cls) {
            this.loading = true;
            this.currentCls = cls;
            this.$store.dispatch("aut/fetchAutClassData", cls);
            this.setEditMode(false);
        },
        hideFields(fields, obj) {
            let self = this;
            return fields.map((field) => {
                if(field.suppressIfField) {                                        
                    const val = field.suppressIfField.split('.').reduce((o, i) => o[i], obj);
                    if(field.suppressIfValueContains && field.suppressIfValueContains.indexOf(val) > -1)
                        field.visible = false;
                    else if(field.suppressIfValueDoesNotContains && field.suppressIfValueDoesNotContains.indexOf(val) == -1)
                        field.visible = false;    
                    else
                        field.visible = true;

                                            
                    return field;   
                    
                }
                if(self.deprecatedFields.includes(field.name)){                    
                        field.visible = false;
                }
                return field;
            }); 
        },
        onPixelDelete(pixelData) {
            this.pixels = this.pixels.filter(obj => obj.beaconId != pixelData.beaconId);
            this.pixelData = this.pixels[0] || null;
        },

        reOrderPixel(pixel, currentIndex, newIndex) {
            this.pixels.splice(newIndex, 0, this.pixels.splice(currentIndex, 1)[0]);  
            this.pixelData = pixel;
        },
        onMapDelete(mapData) {
            this.maps = this.maps.filter(obj => obj.name != mapData.name);
            this.showMap(this.maps[0]);
        },
        async removePixelCustomProps() {
            const modifiedPixels = this.pixels.map(obj => {
                            let copyObj = Object.assign({}, obj);

                            copyObj.pubDeny = (copyObj.pub == "ALLOW" || copyObj.pub == "NONE")?[]:copyObj.pubDeny;
                            copyObj.pubAllow = (copyObj.pub == "DENY" || copyObj.pub == "NONE")?[]:copyObj.pubAllow;

                            copyObj.userDeny = (copyObj.country == "ALLOW" || copyObj.country == "NONE")?[]:copyObj.userDeny;
                            copyObj.userAllow = (copyObj.country == "DENY" || copyObj.country == "NONE")?[]:copyObj.userAllow;

                            delete copyObj.pub;
                            delete copyObj.country;   
                            

                            if(copyObj.beaconId && (copyObj.beaconId.indexOf("auto genretaed") !== -1)) {
                                delete copyObj.beaconId;
                            }
                            
                            return copyObj;                                                        
                       
                    });
            return modifiedPixels;
        },
        async removeClassCustomProps() {         
            let modifiedCls = JSON.parse(JSON.stringify(this.classObject));
            //splitDl cannot be a empty string
            modifiedCls.splitDl = (modifiedCls.splitDl=='')? null: modifiedCls.splitDl;
            modifiedCls.log_cookies.client = modifiedCls.logCookies ? ( modifiedCls.logCookies === 'ALL' ?  ['*'] : modifiedCls.cookieList) : ['*'];

            modifiedCls.rTag.restricted_redirect_domains = (modifiedCls.rTag.redirect_restriction !== "NONE")?  modifiedCls.rTag.restricted_redirect_domains: [];
            delete modifiedCls.loadType;
            delete modifiedCls.logCookies;
            delete modifiedCls.cookieList;
            return modifiedCls;
        },
        async onSaveClick() {
            this.currentClassObj = await this.removeClassCustomProps();
            const pixels = await this.removePixelCustomProps();
            this.currentClassObj.pixels.items = pixels;
            this.currentClassObj.maps  = this.maps.reduce((obj, {name, ...cur}) => ({...obj, [name]: cur}), {});
            this.showModal({ component: 'DiffViewModal' ,  data: {current:this.currentClassObj, prev:this.snapshot, saveMethod:this.updateClass, cancelMethod: this.onCancelClick, modalTitle: "Update Config? (save/cancel)"}});            
        },

        async updateClass() {
            let toastoptions;
            try {                              
                const response = await this.updateAutClientClass({classObject:this.currentClassObj, cls:this.currentCls});
                this.setEditMode(false);
                const clsObj = await response.data;
                this.snapshot = JSON.parse(JSON.stringify(clsObj));
                this.pixels = clsObj.pixels.items;
                let self = this;
                this.pixelData = this.pixels.filter(obj => obj.beaconId == self.currentPixel.beaconId)[0] || this.pixels[this.pixels.length-1];
                this.showPixel(this.pixelData);
                this.successMessage = 'Class settings updated successfully.'
                toastoptions = {
                    message: "Class settings updated successfully.",
                    type: "success"
                }
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;


                toastoptions = {
                    message: this.error || "Something went wrong when updating class. Please contact support.",
                    type: "error"
                }
            }
            this.$store.dispatch('addToast', toastoptions);
           
        },

        addMap() {
            let newMapData = {
                name:"New_map_"+this.newMapKey,
                mappings:{},   
            };

            this.maps = [...this.maps, newMapData ];
            this.newMapKey++;
            this.showMap(newMapData);

        },
        
        showMap(mapData) {
            this.currentMap = JSON.parse(JSON.stringify(mapData));
            this.mapData = Object.assign({},mapData);
            this.mapData['defaultType'] = this.mapData.default?"SPECIFIC_DEFAULT_VALUE":"DIRECT_VALUE";
            this.mapData['csv'] = '';
            for (var k in this.mapData['mappings']) {
                var v = this.mapData['mappings'][k];
                for (var i = 0; i < v.length; i++) {
                    this.mapData.csv += v[i] + ',' + k + '\n'
                }
            }
            this.mapData.csv = this.mapData.csv.trim();
        },
        showPixel(pixelData) {
            this.pixelData = pixelData;
            this.addCustomPixelProperties();
        },
        addPixel() {
            const newPixelData = {
                beaconId: '*auto genretaed*_'+this.newPixelKey,
                userAllow: [],
                userDeny: [],
                pubAllow: [],
                pubDeny: [],
                req: true,
                cookie_req: false,
                type: 'DIR',
                frequency: 100,
                fire_conditions: [],
                gpp : {
                    tcf: {
                        status: "DISABLED",
                        gvlId: null
                    },
                    ccpa: {
                        status: "DISABLED"
                    },
                    usMultiStatePrivacy: {
                        "configName": null,
                        "usco": "DISABLED",
                        "usca": "DISABLED",
                        "usva": "DISABLED",
                        "usut": "DISABLED",
                        "usct": "DISABLED",
                        "usnat": "DISABLED"
                    }
                },
                continent_frequency: {},
                should_host_script: false,
                rotationName: 'default'                
            };
            
            this.pixels = [...this.pixels, newPixelData ];
            this.newPixelKey++;
            this.pixelData = newPixelData;
        },
        addCustomPixelProperties() {
            if(Object.keys(this.pixelData).length > 1) {
                if(!this.pixelData.pubAllow.length && !this.pixelData.pubDeny.length) {
                    this.pixelData['pub'] = "NONE";
                } else  {
                    this.pixelData['pub'] = this.pixelData.pubAllow.length?"ALLOW":"DENY"
                }

                if(!this.pixelData.userAllow.length && !this.pixelData.userDeny.length) {
                    this.pixelData['country'] = "NONE";
                } else  {
                    this.pixelData['country'] = this.pixelData.userAllow.length?"ALLOW":"DENY"
                }
            }
        },
        filterPixelByCountry() {
            const filteredPixels = this.classObject.pixels.items.filter((obj) => {
                if(obj.userAllow.length == 0 || this.selectedCountry == "ALL") {
                    return true;
                } else if(obj.userAllow.includes(this.selectedCountry)){
                    return true;
                } else {
                    return false;
                }
           });
          
           this.pixels = [...filteredPixels];
           this.currentPixel = this.pixels.length?this.pixels[0].beaconId:{};

        },
        toMapJSON(newMapData) {
            let lines = newMapData.csv.split('\n');
            let m = {
                name: newMapData.name,
                mappings: {}
            }
            for (let i = 0; i < lines.length; i++) {
              var fields = lines[i].split(',')
              if (fields.length > 1) {
                var key = fields.pop();
                if (!m['mappings'][key]) m['mappings'][key] = [];
                m['mappings'][key].push(fields.join(','))
              }
            }
            if (newMapData.defaultType === "SPECIFIC_DEFAULT_VALUE") {
              m['default'] = newMapData.default;
            }
            return m
        },
        collpaseToggle(event) {
            event.target.parentElement.classList.toggle("collapsed");
        },       
        initiateClass() { 
            if(this.currentClass[this.$store.state.currentClient.cid])
                this.currentCls = this.currentClass[this.$store.state.currentClient.cid];
            else 
                this.currentCls =  this.classItems.length? this.classItems[0].cls: null;

            this.getCurrentClassData(this.currentCls);
        },
        getPixelListItemTitle(pixel) {
            return `<span><strong>${pixel.beaconId }</strong></span><span class="font-weight-light">${ pixel.description }</span>`;
        },
        onCopyClick() {
            this.showModal({ component: 'CopyPixel' ,  data: {currentCls:this.currentCls, classes:this.classItems, currentPixel:this.currentPixel, pixels: this.pixels, onCopyConfirm: this.onCopyConfirm}});            
        },
       async onCopyConfirm(classObj, className, pixelData) {
            try {
                const pixelArray = classObj.pixels.items.map(pixel => pixel.beaconId);
                pixelData.beaconId = undefined;
                pixelData.uuid = undefined;
                classObj.pixels.items.push(pixelData);
                const response = await this.updateAutClientClass({classObject:classObj, cls:className});
                const updatedClassObj = response.data;
                const updatedPixelArr = updatedClassObj.pixels.items;
                const currentPixel = updatedPixelArr.filter(pixel => !pixelArray.includes(pixel.beaconId))[0];
                this.getCurrentClassData(className);
                return new Promise ((resolve, reject) => {
                    setTimeout(() => {
                        document.getElementById(currentPixel.beaconId+"-beaconId").click();
                        resolve();
                    }, 1000);
                })
            } catch(err) {
               throw new Error(err.message);
            }
                        
        },
        isAccessByUserRole(roles) {
            return this.$store.getters.userRoles.isAccessByRole(roles);
        } 
    },

    beforeMount() {
        this.$store.dispatch("aut/fetchCountries");
        this.$store.dispatch("aut/fetchPrivacyConfig",this.cid);
        this.initiateClass();        
    },
    mounted() {
        this.classFields = getclassFields();          
        this.mapFields = getMapFields();		
        this.pixelFields = getPixelFields();        
    }   

}
</script>

<style scoped>
	.class-btn {
		margin-right: 1rem;
		margin-bottom: 2rem;
		padding: 0.5rem 1.5rem;
	}	
	.input-container {
		display: flex;
		align-items: center;
	}
</style>