<template>
  <div class="modal-card card">
    <div class="card-header">
      <!-- Title -->
      <h3 class="card-header-title">
        {{ modalTitle }}
      </h3>
      <!-- Close -->
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="card-body">
      <div v-if="fileDiffHtml" v-html="fileDiffHtml" />
      <p v-else>No changes detected!</p>

     
    </div>
    <div class="card-footer">
      <div class="text-end">
        <button @click="onActionBtnClick" class="btn btn-success">{{ actionBtnText }}</button>&nbsp;
        <button data-bs-dismiss="modal" aria-label="Close" class="btn btn-secondary">Cancel</button
        >&nbsp;
      </div>
    </div>
  </div>
</template>

<script>
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
const Diff = require("diff");
export default {
  data() {
    return {      
      prev: JSON.stringify(this.modalData.prev, null, " "),
      current: JSON.stringify(this.modalData.current, null, " "),
      actionBtnText: this.modalData.actionBtnText || "Save",
      modalTitle: this.modalData.modalTitle || "Activate to deploy latest changes"
    };
  },

  props: {
    modalData: {
      type: [Object, Array, String],
    }
  },
  computed: {
    fileDiffHtml() {
            const uniDiff = Diff.createPatch("Config Changes", this.prev, this.current);
            if(uniDiff) { 
                const diffRes = Diff2Html.html(uniDiff, {
                    drawFileList: false,
                    inputFormat: 'diff', 
                    showFiles: false, 
                    matching: 'lines'
                });
                //Returning null if no change in diff
                return (diffRes.indexOf('File without changes') >= 0) ? null: diffRes;
            } 
            return null;  
        },
  },

  methods: {
    onActionBtnClick() {
      this.modalData.saveMethod();
      this.$store.dispatch("hideModal");
    },
    onCancelClick() {
      this.modalData.cancelMethod();
      this.$store.dispatch("hideModal");
    },
  },
};
</script>

<style lang="scss">
</style>