const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Name Field is required" }
        ]
    },
    {
        name: "parameter",
        label: "Parameter",
        type: "text",
        editable: true,
        validation: [
            { type: "required"}
        ]
    },{
        name: "input",
        label: "Input",
        type: "text",
        editable: true,
       
    },
    {
        name: "rank",
        label: "Rank",
        type: "number",
        editable: true,
    },
    {
        name: "initiator",
        label: "Initiator",
        type: "select",
        editable: true,
        options:[],
        validation: [
            { type: "required" }
        ],
    },{
        name: "description",
        label: "Description",
        type: "text",
        editable: true,
       
    }
];

export default Fields;