<template>
    <div class="container-fluid">
        <div v-if="tagTemplate">
            <div class="row">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <input-field :editable="editMode" label="Name" type="text"
                                        v-model="tagTemplate.name" />
                                </div>
                                <div v-if="this.$store.getters.userRoles.isAqferAdmin" class="col-md-3">
                                    <input-field :editable="editMode" label="Make Public" type="checkbox"
                                        v-model="tagTemplate.public" />
                                    <input-field v-if="showRecommended" :editable="editMode" label="Recommended" type="checkbox"
                                        v-model="tagTemplate.recommended" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <input-field :editable="editMode" label="Event Type" :options="eventTypeOptions"
                                        type="select" v-model="tagTemplate.event_type" />
                                </div>
                                <div class="col-md-3">
                                    <input-field :editable="editMode" label="Sub Event Type 1" :options="subEvent1Options" placeholder="Enter/Select"
                                        type="customSingleSelect" v-model="tagTemplate.sub_event_type_1" />
                                </div>
                                <div v-if="tagTemplate.sub_event_type_1" class="col-md-3">
                                    <input-field :editable="editMode" label="Sub Event Type 2" :options="subEvent2Options" placeholder="Enter/Select"
                                        type="customSingleSelect" v-model="tagTemplate.sub_event_type_2" />
                                </div>
                                <div class="col">
                                    <input-field :editable="editMode" label="Initiator (optional)"
                                        :options="initiatorOptions" type="select" v-model="tagTemplate.initiator" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <input-field :editable="editMode" label="Delivery Type" :options="deliveryOptions"
                                        type="select" v-model="deliveryType" />
                                </div>
                                <div class="col" v-if="deliveryType == 'atomic'">
                                    <input-field :editable="editMode" label="Atomic Type" :options="atomicOptions"
                                        type="select" v-model="atomicType" />
                                </div>
                                <div class="col" v-if="
                                    deliveryType != 'atomic' ||
                                    atomicType != 'r'
                                ">
                                    <input-field :editable="editMode" label="Extra Logic" :options="logicOptions"
                                        type="select" v-model="logicType" />
                                </div>
                                <div class="col">
                                    <input-field :editable="false" label="Tag Type" :options="tagTypeOptions"
                                        type="select" v-model="tagTemplate.tag_type" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <input-field :editable="editMode" label="Log Delivery" :options="logDeliveryOptions"
                                        type="select" v-model="logDelivery" />
                                </div>
                                <div class="col">
                                    <input-field :editable="editMode" label="Log Size" :options="logSizeOptions"
                                        type="select" v-model="logSize" />
                                </div>
                                <div class="col">
                                    <input-field :editable="editMode" label="Log IP type" :options="logIPTypeOptions"
                                        type="select" v-model="logIPType" />
                                </div>
                                <div class="col">
                                    <input-field :editable="false" label="Logging Mode" :options="loggingModeOptions"
                                        type="select" v-model="tagTemplate.logging_mode" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <input-field :editable="editMode" label="Category Tags" :options="tagTemplate.tag"
                                        type="multiselect" v-model="tagTemplate.tag" />
                                </div>
                                <div class="col-md-3 checkbox-center">
                                    <input-field :editable="editMode" label="Measurement Tag" type="checkbox"
                                        v-model="tagTemplate.measurement_tag" />
                                </div>
                            </div>

                            <div class="row initiator-fields-div" v-if="tagTemplate.initiator">
                                <div class="col">
                                    <h3>Initiator Fields
                                        <span v-if="initiator && initiator.name">(
                                            <a v-if="initiator.url && initiator.url!==''" 
                                                :href="initiator.url" target="_blank" 
                                                class="card-link link-primary"
                                                data-bs-placement="right">{{initiator.name}}</a>
                                            <label v-else >{{ initiator.name }}</label>
                                        )</span>
                                    </h3>
                                    <table class="table table-sm table-nowrap">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Parameter</th>
                                                <th scope="col">Input/Macro</th>
                                                <th scope="col">Omit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(
                                                    field, index
                                                ) in initiatorFields" :key="index">
                                                <td>{{ field.name }}<span   v-if="field.description!==''" class="fa fa-circle-info mx-1 text-muted" :data-bs-title="field.description"  data-bs-toggle="tooltip" data-bs-placement="top" data-bs-container="table" data-bs-html="true"></span></td>
                                                <td>{{ field.parameter }}</td>
                                                <td>{{ field.input }}</td>
                                                <td>
                                                    <input type="checkbox" v-model="
                                                        initiatorFields[
                                                            index
                                                        ].omit
                                                    " />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h3>User Defined Fields</h3>
                                    <table class="table table-sm table-nowrap">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Parameter</th>
                                                <th scope="col">Input/Macro<span class="fa fa-circle-info text-muted" title="use !var: for no quotes, eg: !var:document.var" data-bs-toggle="tooltip" data-bs-placement="right"></span></th>
                                                <th v-if="editMode" scope="col">Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(
                                                    field, index
                                                ) in tagTemplate.user_defined_fields" :key="index">
                                                <td>{{ field.name }}</td>
                                                <td>
                                                    <div v-if="field.name =='Custom' && editMode">
                                                        <input style="padding: 1px 2px;" 
                                                                class="form-control" type="text" 
                                                                v-model="tagTemplate.user_defined_fields[index].parameter"
                                                        />
                                                    </div>
                                                    <div v-else>
                                                        {{ field.parameter }}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div v-if="!editMode">
                                                        {{ field.input }}
                                                    </div>
                                                    <div v-else>
                                                        <input style="padding: 1px 2px;"
                                                            class="form-control" type="text" v-model="tagTemplate.user_defined_fields[index].input" />
                                                    </div>
                                                </td>
                                                <td v-if="editMode">
                                                    <i @click="removeUserDefinedField(index)" class="fa fa-regular fa-trash-can"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="row" v-if="editMode">
                                        <div class="col9">
                                            <input-field :editable="editMode" placeholder="Please select"
                                                label="Add User Defined Field" :options="userDefinedFieldOptions"
                                                type="select" v-model="userDefinedFieldAddon" />
                                        </div>
                                        <div class="col-md-4 mb-3">
                                                <button class="btn btn-primary" @click="onCustomAdd">Add Custom Field</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="tagTemplate.notes || editMode">
                                <div class="col">
                                    <h3>Notes</h3>
                                    <WYSIWYG v-if="editMode" v-model="tagTemplate.notes" />
                                    <div v-else v-html="tagTemplate.notes"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">

                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-header-title">Generate Tag</h3>
                        </div>
                        <div class="card-body">

                            <input-field label="Choose Domain" :validate="[{ type: 'required' }]"
                                :options="domainOptions" type="select" v-model="tagDomain" />

                            <input-field label="Choose Class" :options="classOptions" type="select"
                                v-model="tagClass" />

                            <input-field v-if="this.tagTemplate.tag_type == 'r'" label="Redirect URL" type="text"
                                v-model="redirectTarget" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary float-end mb-2" @click="copyMinified()">
                                Copy Minified Code
                            </button>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-footer rounded-3 bg-dark">
                            <code class="highlight html bg-dark xml">
                                {{ generatedCode }}
                            </code>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InputField from "../../components/InputField.vue";
import WYSIWYG from "../../components/WYSIWYG.vue";
import { tag_event_types } from "../../utils/constOptionValues";

const LOG_KEYS = {
    b: "bbh",
    c: "bbi",
    d: "beh",
    e: "bei",
    f: "sbh",
    g: "sbi",
    h: "seh",
    i: "sei",
    j: "bbn",
    k: "ben",
    l: "sbn",
    m: "sen",
};

const USER_DEFINED_FIELD_KEYS = {
    dmn: "Publisher Domain",
    pubid: "Publisher ID",
    uu: "Unique User ID",
    suu: "Statistical Unique User ID",
    payload: "Payload",
    evid: "Event ID",
    v0: "User Defined(0)",
    v1: "User Defined(1)",
    v2: "User Defined(2)",
    v3: "User Defined(3)",
    v4: "User Defined(4)",
    v5: "User Defined(5)",
    v6: "User Defined(6)",
    v7: "User Defined(7)",
    v8: "User Defined(8)",
    v9: "User Defined(9)",
};

export default {
    name: "TagTemplate",
    components: { InputField, WYSIWYG },
    data() {
        return {
            error: "",
            tagClass: "",
            tagDomain: "",
            logDelivery: "",
            logSize: "",
            logIPType: "",
            deliveryType: "",
            atomicType: "",
            logicType: "",
            initiatorFields: [],
            userDefinedFieldAddon: "",
            redirectTarget: "",
            label: this.$store.state.currentClient.label,
            subEvent1Options:[],
            subEvent2Options:[]
        };
    },
    props: {
        modelValue: Object,
        editMode: Boolean
    },
    watch: {
        loggingMode(value, oldValue) {
            this.tagTemplate.logging_mode = value;
        },
        deliveryType() {
            this.updateTagTypeField()
        },
        logicType() {
            this.updateTagTypeField()
        },
        atomicType() {
            this.updateTagTypeField()
        },
        "tagTemplate.initiator"(value, oldValue) {
            this.initiatorFields = [];
            this.getInitiatorFields();
        },
        userDefinedFieldAddon(value, oldValue) {
            if(value=="Custom"){
                this.tagTemplate.user_defined_fields.push({
                    name: "Custom",
                    parameter: "",
                    input: ""
                })
                this.userDefinedFieldAddon = "";
            } else if (value) {
                this.tagTemplate.user_defined_fields.push({
                    name: USER_DEFINED_FIELD_KEYS[value],
                    parameter: value,
                    input: ""
                })
                this.userDefinedFieldAddon = "";
            }
         },
        tagTemplate(value) {
            this.$emit("setTagTemplate", value);
        },
        "tagTemplate.event_type": {
            handler: function(newVal,oldValue) {
               this.onEventChange(newVal,oldValue);
            }
        },
        "tagTemplate.sub_event_type_1": {
            handler: function(newVal,oldValue) { 
                this.onSubEvent1Change(newVal);                                             
                this.tagTemplate.sub_event_type_2=''
            }
        },
        "tagTemplate.public":{
            handler: function(newVal){
                if(!newVal){
                    this.showRecommended = false;
                }
            }
        }
    },
    computed: {
        ...mapState({
            cid: (state) => state.currentClient.cid,
            domainList: (state) => state.aut.domainList,
        }),
        tagTemplate() {
            return this.modelValue;
        },
        eventTypeOptions() {
            return tag_event_types;
        },
        initiatorOptions() {
            var options = [{ label: "None", value: "" }];
            this.$store.state.aut.initiators.map((initiator)=>{
                    return options.push({ label: initiator.name, value:initiator.value })
            })
            return options;
        },
        tagTypeOptions() {
            var options = [];
            options.push(
                { label: "a.gif", value: "a.gif" },
                { label: "c.gif", value: "c.gif" },
                { label: "r", value: "r" },

                { label: "s.js", value: "s.js" },
                { label: "c.js", value: "c.js" },
                { label: "cs.js", value: "cs.js" },

                { label: "t.dhj", value: "t.dhj" },
                { label: "tc.dhj", value: "tc.dhj" },
                { label: "ts.dhj", value: "ts.dhj" },
                { label: "tcs.dhj", value: "tcs.dhj" }
            );
            return options;
        },
        loggingModeOptions() {
            var options = [];
            options.push(
                { label: "A", value: "a" },
                { label: "B", value: "b" },
                { label: "C", value: "c" },
                { label: "D", value: "d" },
                { label: "E", value: "e" },
                { label: "F", value: "f" },
                { label: "G", value: "g" },
                { label: "H", value: "h" },
                { label: "I", value: "i" },
                { label: "J", value: "j" },
                { label: "K", value: "k" },
                { label: "L", value: "l" },
                { label: "M", value: "m" }
            );
            return options;
        },
        deliveryOptions() {
            var options = [
                { label: "Atomic", value: "atomic" },
                { label: "Container", value: "container" },
            ];
            return options;
        },
        atomicOptions() {
            var options = [
                { label: "Image", value: "img" },
                { label: "JavaScript", value: "js" },
                { label: "Redirect", value: "r" },
            ];
            return options;
        },
        logicOptions() {
            var options = [];

            if (this.deliveryType != "atomic" || this.atomicType != "js")
                options.push({ label: "None", value: "" });

            options.push({ label: "Cookie", value: "c" });

            if (this.deliveryType != "atomic" || this.atomicType != "img")
                options.push(
                    { label: "Stat", value: "s" },
                    { label: "Cookie and Stat", value: "cs" }
                );

            return options;
        },
        domainOptions() {
            let domainOptions = [];
            if (this.domainList) {
                this.domainList.map((domainObj) => {
                    if (domainObj.domains && domainObj.domains.items) {
                        domainObj.domains.items.map((domainItem) => {
                            domainOptions.push({
                                label: domainItem.name,
                                value: domainItem.name,
                            });
                        });
                    }
                });
            }
            return domainOptions;
        },
        classOptions() {
            let clsOptions = [{ label: "None", value: "" }];
            this.$store.state.currentClient.classes.items.map((clsObj) => {
                clsOptions.push({ label: clsObj.cls, value: clsObj.cls });
            });
            return clsOptions;
        },
        logDeliveryOptions() {
            var options = [
                { label: "None", value: "" },
                { label: "Batch", value: "b" },
                { label: "Streaming", value: "s" },
            ];
            return options;
        },
        logSizeOptions() {
            var options = [
                { label: "Base", value: "b" },
                { label: "Extended", value: "e" },
            ];
            return options;
        },
        logIPTypeOptions() {
            var options = [
                { label: "Hashed IP", value: "h" },
                { label: "Plain IP", value: "i" },
                { label: "No IP", value: "n" },
            ];
            return options;
        },
        userDefinedFieldOptions() {
            var options = [];
            if (this.tagTemplate.user_defined_fields) {
                for (const key in USER_DEFINED_FIELD_KEYS) {
                    var exists = false;
                    for (let i = 0; i < this.tagTemplate.user_defined_fields.length; i++) {
                        if (this.tagTemplate.user_defined_fields[i].parameter == key) {
                            exists = true;
                        }
                    }
                    if (!exists)
                        options.push({ label: USER_DEFINED_FIELD_KEYS[key], value: key })
                }
            }
            return options;
        },
        loggingMode() {
            let logMode = this.logDelivery + this.logSize + this.logIPType;
            for (const key in LOG_KEYS) {
                if (LOG_KEYS[key] == logMode) return key;
            }
            return "a";
        },
        generatedCode() {
            let template;
            let tagParams = {};
            if (this.tagClass) tagParams["cls"] = this.tagClass;
            if (this.tagTemplate.event_type)
                tagParams["aqet"] = this.tagTemplate.event_type;
            if (this.tagTemplate.sub_event_type_1)
                tagParams["aqet_l1"] = this.tagTemplate.sub_event_type_1;
            if (this.tagTemplate.sub_event_type_2)
                tagParams["aqet_l2"] = this.tagTemplate.sub_event_type_2;
            if (this.tagTemplate.measurement_tag) tagParams["aq_m"] = 1;

            // find initiator values
            if (this.initiatorFields) {
                for (let i = 0; i < this.initiatorFields.length; i++) {
                    const field = this.initiatorFields[i];
                    if (!field.omit) tagParams[field.parameter] = field.input;
                }
            }

            // find user defined fields
            if (this.tagTemplate.user_defined_fields) {
                for (let i = 0; i < this.tagTemplate.user_defined_fields.length; i++) {
                    const field = this.tagTemplate.user_defined_fields[i];
                    tagParams[field.parameter] = field.input;
                }
            }

            //for redirect tag
            if (this.tagTemplate.tag_type == 'r' && this.redirectTarget) {
                tagParams['ru'] = encodeURIComponent(this.redirectTarget);
            }

            if (this.tagTemplate.tag_type.indexOf(".gif") !== -1 || this.tagTemplate.tag_type == 'r') {
                // URL TEMPLATE - USE FOR ATOMIC IMG TAGS
                const urlParams = Object.keys(tagParams)
                    .map((k) => `${k}=${tagParams[k]}`)
                    .join("&");
                template = `https://${this.tagDomainValue}/1/${this.tagTemplate.logging_mode}/${this.tagTemplate.tag_type}?${urlParams}`;
            } else {
                // WRAPPER TEMPLATE - ALL OTHER TAGS
                template = `<script title="pxsnpt">\n\
  var ${this.label}Data = {\n\
    cdmn : '${this.tagDomainValue}',\n\
    lm : '${this.tagTemplate.logging_mode}',\n\
    tt : '${this.tagTemplate.tag_type}'\n\
  };\n\
  !function(a){\n`;
                for (const key in tagParams) {
                    template += `    a.${key} = ${tagParams[key].toString().startsWith("!var:")?tagParams[key].slice(5):"'"+tagParams[key]+"'"};\n`;
                }
                template +=
                    `  }(${this.label}Data);\n\
  function _pxTagInject(p,d,w,l){\n\
    var o,k=[],t=p.tt.slice(-1),x={cdmn:1,lm:1,tt:1},y={cid:1,cls:1,dmn:1,pubid:1,evid:1,aq_m:1};p.dmn=(p.dmn||(w.top[l]===w[l]?''+w[l]:d.referrer).split('/')[2]).split(':')[0];for(o in p){if(y[o]||((t!='j'||p.aq_m)&&!x[o])){k.push(o+"="+p[o]);};};var s=d.createElement(t=='f'?'img':'script');s.id=s.title='pxscrpt';s.async=s.defer=!0;s.src='//'+p.cdmn+'/1/'+p.lm+'/'+p.tt+'?'+k.join("&");d.body.appendChild(s);\n\
}_pxTagInject(${this.label}Data,document,window,'location');\n\
</` + `script>`;
            }

            return template;
        },
        tagDomainValue(){
            return this.tagDomain === ""?"{{hostname}}":this.tagDomain
        },
        showRecommended(){
            return this.tagTemplate.public
        },
        initiator(){
            return this.$store.state.aut.initiators.find((initiator)=> initiator.value == this.tagTemplate.initiator)   
        }
    },
    beforeMount() {
        this.setInitialOptions();
        this.getDomainList();
        this.$store.dispatch("aut/fetchInitiators");
    },
    methods: {
        ...mapActions({
            getDomainList: "aut/getDomainLists",
            fetchInitiatorFields: "aut/fetchInitiatorFields",
            readTagTemplate: "aut/readTagTemplate",
            updateTagTemplate: "aut/updateTagTemplate",
            deleteTagTemplate: "aut/deleteTagTemplate",
            showModal: "showModal",
        }),
        setInitialOptions() {
            if (!this.tagTemplate.notes)
                this.tagTemplate.notes = ""
            if (!this.tagTemplate.user_defined_fields)
                this.tagTemplate.user_defined_fields = []
            this.getInitiatorFields();

            if (this.tagTemplate.logging_mode in LOG_KEYS) {
                const logMode = LOG_KEYS[this.tagTemplate.logging_mode];
                this.logDelivery = logMode[0];
                this.logSize = logMode[1];
                this.logIPType = logMode[2];
            }

            let deliveryType, logicType, atomicType;
            
            deliveryType = (this.tagTemplate.tag_type.indexOf("dhj") !== -1) ? "container" : "atomic";
            
            if (this.tagTemplate.tag_type.indexOf("cs") !== -1)
                logicType = "cs";
            else if (this.tagTemplate.tag_type.indexOf("c") !== -1)
                logicType = "c";
            else if (this.tagTemplate.tag_type.indexOf("s") !== -1)
                logicType = "s";
            else logicType = "";

            if (this.tagTemplate.tag_type.indexOf(".js") !== -1)
                atomicType = "js";
            else if (this.tagTemplate.tag_type.indexOf("r") !== -1)
                atomicType =  "r";
            else atomicType = "img";

            this.deliveryType = deliveryType;
            this.atomicType = atomicType;
            this.logicType = logicType;
            this.onEventChange(this.tagTemplate.event_type)
            if(this.tagTemplate.sub_event_type_1){
                this.onSubEvent1Change(this.tagTemplate.sub_event_type_1)
            }

        },
        async getInitiatorFields() {
            var self = this;
            if (!this.tagTemplate.initiator) return;
            try {
                let response = await this.fetchInitiatorFields(
                    this.tagTemplate.initiator
                );
                this.initiatorFields = response.data;
            } catch (error) {
                return;
            }
        },
        removeUserDefinedField(index) {
            this.tagTemplate.user_defined_fields.splice(index, 1);
        },
        copyMinified() {
            if (!this.tagDomain) {
                const options = {
                    message: "You must select a Domain.",
                    type: "error",
                };
                this.$store.dispatch("addToast", options);
            } else {
                let code = this.generatedCode;
                code = code.replaceAll("\n", "");
                code = code.replaceAll("  ", "");
                navigator.clipboard.writeText(code);
                const options = {
                    message: "Code copied to clipboard",
                    type: "success",
                };
                this.$store.dispatch("addToast", options);
            }
        },
        onCustomAdd(){
            this.userDefinedFieldAddon="Custom"
        },
        updateTagTypeField() {
            let tagType;
            if (this.deliveryType == "container") {
                tagType = "t" + this.logicType + ".dhj";
            } else {
                if (this.atomicType == "img") {
                    tagType = this.logicType == "c" ? "c.gif" : "a.gif";
                } else if (this.atomicType == "js") {
                    tagType = this.logicType + ".js";
                } else {
                    tagType = "r";
                }
            }
            this.tagTemplate.tag_type = tagType
        },
        onEventChange(newVal,oldValue){
            let event_index= tag_event_types.findIndex((event)=> event.value==newVal)
            this.subEvent1Options=[]
            this.subEvent2Options=[]
            if(oldValue){
                this.tagTemplate.sub_event_type_1='';
                this.tagTemplate.sub_event_type_2='';
            }
            if(event_index!=-1 && tag_event_types[event_index].sub_events){
                this.subEvent1Options=tag_event_types[event_index].sub_events;
            }
        },
        onSubEvent1Change(newVal){
            this.subEvent2Options=[]
                if(this.subEvent1Options.length>0){
                    let event_index= this.subEvent1Options.findIndex((event)=> event.value==newVal)
                    if(event_index!=-1 && this.subEvent1Options[event_index].sub_events){
                        this.subEvent2Options=this.subEvent1Options[event_index].sub_events
                    }
                }
        }
    }
};
</script>

<style scoped>
code {
    color: #dcdcdc;
    white-space: pre-wrap;
}
.checkbox-center{
    display: flex;
    align-items: center;
}
</style>