<template>
    <basic-header
        pretitle="Administration"
        title="Users"
        buttonText="Add User"
        @btnClick="showModal({ component: 'addUser' })"
    />

    <div class="container-fluid">
        <div class="card">
            <datatable
                tableId="usersTable"
                :columns="columns"
                uniqueField = "email"
                :data="$store.state.admin.users"
                @deleteRows="showDeleteUsers"
                @rowCallback = "restrictSelectOnRows"
                :deletable="true"
                :compact="true"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:'users/', name: 'User', identifier:'email'}"
            />
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions, mapMutations } from "vuex";

export default {
    name: "Users",
    components: { BasicHeader, Datatable },
    data() {
        return {
            columns: [
                { data: "name" },
                { data: "email" },
                {
                    data: "roles",
                    render: function (data, type, row) {
                        if (!data) return "";
                        return Array.isArray(data) ? data.join(" ") : data;
                    },
                },
                {
                    data: "cids",
                    render: function (data, type, row) {
                        if (!data) return "";
                        return Array.isArray(data) ? data.join(" ") : data;
                    },
                },
            ],
        };
    },
    beforeMount() {
        this.$store.dispatch("admin/fetchUsers");
    },
    methods: {
        showDeleteUsers(rows) {
            this.showModal({ component: "deleteUsers", data: rows });
        },
        ...mapActions({
            showModal: "showModal",
        }),
        restrictSelectOnRows(row, rowData, type, index) {
            if(!this.$store.getters.userRoles.isAqferAdmin && this.$store.getters.userRoles.isClientAdmin) {
                if(rowData) {
                    if((["aqfer_admin","client_admin"].some(r => rowData.roles.indexOf(r) >= 0)))  {
                        // Hide select row
                        row.children[0].disabled = true;
                        row.children[0].classList.remove('select-row', 'fa', 'fa-regular', 'select-checkbox-fa');
                        
                        // Hide detail row
                        row.childNodes[1].childNodes[0].style.visibility = "hidden";
                    }
                }                           
            }
           
        }
    },
};
</script>

<style scoped>
</style>