<template>
    <div class="header">
        <div class="">
            <!-- Body -->
            <div class="header-body">
                <div class="row align-items-center">
                    <div class="col">
                        <!-- Pretitle -->
                        <h6 class="header-pretitle">{{ pretitle }}</h6>
                        <!-- Title -->
                      
                        <slot v-if="breadcrumbTitle" name="breadcrumb-title"></slot>
                        
                        <h1 v-else class="header-title">{{ title }}</h1>
                        <!-- Secondary Title -->
                        <h4 class="header-subtitle text-secondary">{{secondaryTitle}}</h4>
                    </div>
                    <div class="col-auto ms-auto">
                        <slot name="header-button-content"></slot>   
                    </div>
                </div>

                <slot name="cards-content"></slot>              

                <!--- Nav Tabs -->
                <div class="row">
                    <div class="col-12">
                        <ul class="nav nav-tabs header-tabs">
                            <li v-for="item in navItems" :key="item.index" class="nav-item" :class="[(($route.name != item.link.name) && item.disable)?'hide':'']">
                                <router-link :to="!editMode?item.link:''" :class="[$route.name == item.link.name ? 'active' : '', 'nav-link text-center']" @click.stop.prevent="editMode?$emit('prevent-navigation'):null">
                                    {{item.name}}
                                    &nbsp;<button  v-if="item.closeTab" type="button" class="btn-close" @click.stop.prevent="$emit('nav-close-clicked', item.link.name)" aria-label="Close"></button>
                                </router-link>                                
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- / .row -->
            </div>
            <!-- / .header-body -->
        </div>
    </div>
</template>

<script>
export default {
    name: "NavHeader",
    props: {
        pretitle: String,
        title: String,
        secondaryTitle: {
            type: String,
            default: "",
        },
        breadcrumbTitle:{
            type: Boolean,
            default: false,
        },
        editMode:{
            type: Boolean,
            default: false,
        },
        navItems: Array
    },
    emits: [
      'nav-close-clicked', 'prevent-navigation'
    ],
  
};
</script>

<style scoped>
.hide {
    display: none;
}
</style>