<template>
	<div class="row job-config left-nav-collapsible" v-show="!loading && jobObj.created_at">
		<div v-if="detailPageOpen" id="detail-page-open">
			<button
				class="btn details-open-btn"
				type="button"
				@click="onDetailPageOpenClose(false)"
			>
				<span> <i class="fa fa-caret-right"></i></span>
			</button>
		</div>
		<TransitionGroup :name="jobObj.created_at ? 'slide' : ''" key="detail">
			<div
				v-if="showCloseBtn && !detailPageOpen"
				id="detail-page-close"
				key="close"
			>
				<button
					class="btn text-start details-open-btn"
					type="button"
					@click="onDetailPageOpenClose(true)"
				>
					<span> <i class="fa fa-caret-left"></i></span>
				</button>
			</div>
			<div
				v-if="!detailPageOpen"
				id="details-col"
				@mouseover="onDetailPageHover(true)"
				key="details-col"
			>
				<div class="card">					
					<div class="">
						<div class="card-header">
							<h5 class="card-header-title">Details</h5>
							<button
								type="button"
								data-bs-toggle="collapse"
								href="#collapse-props-div"
								aria-expanded="false"
								aria-controls="collapse-props-div"
								id="collapse-btn-2"
								class="btn btn-sm collapsed"
							>
								<i class="fa fa-chevron-up float-end"></i>
							</button>
						</div>
						<div
							id="collapse-props-div"
							class="collapse show"
							aria-labelledby="collapse-btn-2"
						>
							<div class="card-body pt-0">
								<div class="list-group list-group-flush">
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<h5 class="mb-0">Type</h5>
											</div>
											<div class="col-auto">
												{{ job.kind
												}}<!--v-if-->
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<h5 class="mb-0">Author</h5>
											</div>
											<div class="col-auto">
												<small class="text-muted">{{
													job.created_by
												}}</small>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<h5 class="mb-0">Created</h5>
											</div>
											<div class="col-auto">
												<small class="text-muted">{{
													DateTimeFormat(
														job.created_at
													)
												}}</small>
												<span
													class="
														badge badge-secondary
													"
													>{{
														convertToRelativeTime(
															job.created_at
														)
													}}</span
												>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<h5 class="mb-0">Updated</h5>
											</div>
											<div class="col-auto">
												<small class="text-muted">{{
													DateTimeFormat(
														job.updated_at
													)
												}}</small
												><span
													class="
														badge badge-secondary
													"
													>{{
														convertToRelativeTime(
															job.updated_at
														)
													}}</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="col"
				id="main-col"
				@mouseover="onDetailPageHover(false)"
				key="main-col"
			>
				<job-editor
					v-on:tabChange="showTab"
					v-on:onLintFailure="onLintFailure"
					:editMode="editMode"
					:jobType="jobType"
					v-model="yamlContent"
					:activeTab="activeTab"
				></job-editor>
			</div>
		</TransitionGroup>
	</div>
	<div v-show="loading || !jobObj.created_at" class="text-center">
		<div class="spinner-border" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import dayjs from "dayjs";
	import duration from "dayjs/plugin/duration";
	import macros from "../../../utils/macros";
	import { mapActions, mapState } from "vuex";
	import JobEditor from "../../../components/sandbox/JobEditor.vue";
	import {
		DateTimeFormat,
		relativeTime,
	} from "../../../utils/commonFunction";
	import { GenerateCurlCommand } from "../../../utils/curlCommand";
	dayjs.extend(duration);
	export default {
		name: "Configuration",
		components: { JobEditor },
		data() {
			return {
				jobType: this.job.kind,
				activeTab: "",
				yamlContent: {},
				jobObj: {},
				runClicked: false,
				response: "",
				error: "",
				isButtonDisabled: false,
				loading: false,
				name: "tagName",
				tagOptions: [],
				jobName: this.$route.params.jobId,
				detailPageOpen: true,
				showCloseBtn: false,
			};
		},
		props: [
			"editMode",
			"job",
			"runJobTrigger",
			"saveJobTrigger",
			"saveAndJobTrigger",
			"cancelJobTrigger",
			"snapshot",
		],
		watch: {
			runJobTrigger() {
				this.runJob();
			},
			saveJobTrigger() {
				const runJobAfter = false;
				this.saveJob(runJobAfter, false);
			},
			saveAndJobTrigger() {
				const runJobAfter = true;
				this.saveJob(runJobAfter, false);
			},
			job() {
				this.initateYamlContent();
			},
			editMode(val) {
				if (val) {
					this.error = "";
					this.response = "";
				}
				this.addingFile = false;
				this.initateYamlContent();
			}
			
		},
		computed: {
			generatedUrl() {
				let urlTemplate = `${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/${this.$store.state.sandbox.currentSandbox}/jobs/${this.jobObj.name}/executions`;
				if (this.tag) urlTemplate = `${urlTemplate}?tag=${this.tag}`;

				return urlTemplate;
			},
			tag: {
				get() {
					return this.$store.state.sandbox.jobRunParams[this.jobName]
						.tag;
				},
				set(val) {
					this.$store.dispatch("sandbox/setJobTag", {
						jobName: this.jobName,
						tag: val,
					});
				},
			},
			image: {
				get() {
					return this.$store.state.sandbox.jobRunParams[this.jobName]
						.image;
				},
				set(val) {
					this.$store.dispatch("sandbox/setJobImage", {
						jobName: this.jobName,
						image: val,
					});
				},
			},
			envParams: {
				get() {
					return this.$store.state.sandbox.jobRunParams[this.jobName]
						.envParams;
				},
				set(val) {
					this.$store.dispatch("sandbox/setJobEnvParams", {
						jobName: this.jobName,
						envParams: val,
					});
				},
			},
		},
		methods: {
			...mapActions({
				readSandboxJob: "sandbox/readSandboxJob",
				updateSandboxJob: "sandbox/updateSandboxJob",
				readJob: "amdp/readJob",
				showModal: "showModal",
			}),
			DateTimeFormat,
			initateYamlContent() {
				this.jobObj = this.job;

				let yamlContent = {};

				//Show only vars.yaml from system files
				if (this.job.system_files) {
					for (const i of this.job.system_files) {
						if (i.relative_path == "vars.yaml")
							yamlContent[i.relative_path] = i.content;
					}
				}
				//Show only edge_extract.yaml from user files
				if (this.job.user_files) {
					for (const i of this.job.user_files) {
						if (i.relative_path == "edge_extract.yaml")
							yamlContent[i.relative_path] = i.content;
					}
				}
				//Initially activeTab value is empty or undefined otherwise no need to reset to first value
				if (!this.activeTab || this.activeTab == "")
					this.activeTab = Object.keys(yamlContent)[0];
				this.yamlContent = JSON.parse(JSON.stringify(yamlContent));
			},
			async saveJob(runJobAfter) {
				this.loading = true;
				let self = this;
				let toastoptions;

				try {
					this.jobObj.system_files = this.job.system_files.map(
						(file) => {
							if (this.yamlContent[file.relative_path])
								return {
									content:
										typeof this.yamlContent[file.relative_path] === "string"
											? this.yamlContent[file.relative_path]
											: JSON.stringify(this.yamlContent[file.relative_path] ),
									relative_path: file.relative_path,
								};
							else return file;
						}
					);

					this.jobObj.user_files = this.job.user_files.map((file) => {
						if (this.yamlContent[file.relative_path])
							return {
								content:
									typeof this.yamlContent[file.relative_path] === "string"
										? this.yamlContent[file.relative_path]
										: JSON.stringify(this.yamlContent[file.relative_path] ),
								relative_path: file.relative_path,
							};
						else return file;
					});

					const response = await this.updateSandboxJob(this.jobObj);
					this.$emit("setSnapshot", this.jobObj);
					toastoptions = {
						message: "Job updated successfully.",
						type: "success",
					};
					if (runJobAfter) {
						setTimeout(function () {
							self.runJob();
						}, 2000);
					}
				} catch (error) {
					if (
						error.response &&
						error.response.data &&
						error.response.data.error
					)
						this.error = error.response.data.error;
					else this.error = error.message;

					toastoptions = {
						message:
							this.error ||
							"Something went wrong when updating Job. Please contact support.",
						type: "error",
					};
				} finally {
					this.loading = false;
					this.$emit("triggerEditMode", false);
					this.$store.dispatch("addToast", toastoptions);
				}

				this.loading = false;
			},
			runJob() {
				this.$emit("setRunClick", true);
				const self = this;
				var payload = {};

				if (this.image) payload.image = this.image;

				if (this.envParams.length) {
					let envParamsObj = {};
					if (this.envParams) {
						for (let i = 0; i < this.envParams.length; i++) {
							const field = this.envParams[i];
							if (field.input)
								envParamsObj[field.param] = field.input;
						}
					}
					payload.env_params = envParamsObj;
				}

				// Make job run request
				const promise = axios
					.post(this.generatedUrl, payload, {
						headers: {
							Authorization: `Bearer ${this.$store.state.accessToken}`,
						},
					})
					.then((res) => {
						self.$store.dispatch("sandbox/fetchSandboxJobRuns", {
							jobName: self.jobName,
						});
						this.response = JSON.stringify(res.data, null, 4);
						//ShowHandlerExecution
						self.showModal({
							component: "ShowHandlerExecution",
							data: {
								jobName: self.job.name,
								sandboxId: self.$route.params.sandboxId,
								executionId: res.data.execution_id,
								liveDeployStatus: true,
								feature: "AGM",
							},
						});
					})
					.catch((error) => {
						self.error = error.message;
						if (error.response.data && error.response.data.error) {
							self.error += `: ${error.response.data.error}`;
						}

						const toastoptions = {
							message:
								"Something went wrong when running the Job. Please contact support.",
							type: "error",
						};
						this.$store.dispatch("addToast", toastoptions);
					})
					.finally(() => {
						this.$emit("setRunClick", false);
					});
			},
			showTab(tab) {
				this.activeTab = tab;
			},
			onLintFailure(isError) {
				this.$emit("onLintFailure", isError);
			},
			convertToRelativeTime(timestamp) {
				return relativeTime(Date.now(), dayjs(timestamp));
			},
			onDetailPageOpenClose(value) {
				this.showCloseBtn = false;
				this.detailPageOpen = value;
			},
			onDetailPageHover(value) {
				this.showCloseBtn = value;
			},
		},

		beforeMount() {
			this.initateYamlContent();
			this.$store.dispatch(
				"sandbox/setDefaultJobRunParams",
				this.jobName
			);
		},
		mounted() {
			this.$emit("hideEdit", false);
			this.$emit("hideRunJob", false);
			this.$emit("showCancelJob", false);
		},
		emits: [
			"onLintFailure",
			"setRunClick",
			"triggerEditMode",
			"hideEdit",
			"hideRunJob",
			"showCancelJob",
			"setSnapshot",
		],
	};
</script>

<style scoped>
	.job-config .card-header {
		background-color: #f9fbfd;
		text-transform: uppercase;
		color: #95aac9;
		height: 40px;
	}
	.job-config .collapsed .fa {
		transform: rotate(180deg);
	}

</style>
