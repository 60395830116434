<template>
    <div class="modal-card card">
        <form @submit.prevent="onEdgeHostName()">  
        <div class="card-header">
            <!-- Title -->
            <h4 v-if="!modalData" class="card-header-title">
                Add EdgeHostName
            </h4>
            <h4 v-else class="card-header-title">
                Add Domain Record
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
            <div v-if="!modalData" class="mb-4">
                <label class="form-label">
                    Edge Hostname
                    <span class='fa fa-circle-info text-muted' title="Name of edge-hostname to add" data-bs-toggle="tooltip" data-bs-placement="right"></span>
                    <span class="text-danger">&nbsp;*</span>
                </label>

                <div style="display: flex; align-items: center;">
                  <input type="text" class="form-control" placeholder="Subdomain" v-model="ehName" @input="validateEdgeHostName" @blur="validateEdgeHostName" :class="{'is-invalid': ehNameError}">
                    <span style="padding-left: 5px; white-space: nowrap;"> {{ edgeHostNameSuffix() }} </span>
                </div>
                <div v-if="ehNameError" class="invalid-feedback" style="display: block;"> {{ ehNameError }} </div>
            </div>

            <form-fields :fields="fields" v-model="domainList" />
            <div v-if="error" class="text-danger">
                {{error}}
            </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :isDisabled=isDisabled :fields="fields" v-model="domainList"  :loading="loading"/>
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/domainListFields"
export default {
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            ehName: "",
            ehNameError: "",
            domainList: {
                "ipType": "IPV4",
            },
            fields: Fields,
            loading: false
        }
    },
    props: {       
        modalData: {
            type: [Object, Array, String],
        }
    },
    beforeMount() {  
        let self = this;     
        this.fields =  this.fields.map((field) => {
            if((field.name === "fipsEnabled" || field.name === "ipType") && self.modalData) {  //Visiblity false for fipsEnabled and ipType when adding domain record
                field.visible = false;                                            
                return field;                      
            } else {
                field.visible = true;
            }
            return field;
        });         
    },
    computed:{
        isDisabled(){
            return this.ehNameError !== '';
        }
    },
    methods: {
        ...mapActions('aut', {
            updateEdgeHostname: 'updateEdgeHostname'
        }),
        validateEdgeHostName() {
            if (!this.ehName) {
                this.ehNameError = 'SubDomain is required';
            } else {
                this.ehNameError = '';
            }
        },
        edgeHostNameSuffix() {
            let environment = '';
            if (this.$store.state.environment === 'dev') {
                environment = `-dev`;
            } else if (this.$store.state.environment === 'pre_prod') {
                environment = `-preprod`;
            }
            return `.a24365${environment}-${this.$store.state.currentClient.cid}.edgekey.net`;
        },
        async onEdgeHostName() {            
            let domainListObj = {};
            let domainArr = [];
            let ordinal = 0;
            if(this.modalData && this.modalData.domains && this.modalData.domains.items) {
                this.modalData.domains.items.map((domainObj) => {
                    if(ordinal < domainObj.ordinal)
                        ordinal = domainObj.ordinal;               
                });
                ordinal++;
            }
            
            if(this.domainList.domains && this.domainList.domains.length) {
                domainArr = this.domainList.domains.map((domainName) => {
                    let domainObj = {};
                    domainObj.name = domainName;
                    domainObj.ordinal = ordinal;
                    domainObj.status= "PENDING";
                    domainObj.recordType = this.domainList.recordType;
                    ordinal++;
                    return domainObj;
                });
            }

            if(this.modalData) {
                domainListObj = this.modalData
                domainListObj.domains = this.modalData.domains;
                domainListObj.domains.items = [...this.modalData.domains.items, ...domainArr];
                domainListObj.ipType = this.modalData.ipType
            } else {
                domainListObj.edgeHostname = this.ehName + this.edgeHostNameSuffix();
                domainListObj.fipsEnabled =this.domainList.fipsEnabled
                domainListObj.domains = {"items": domainArr};
                domainListObj.ipType = this.domainList.ipType
            }
            this.onUpdateEdgeHostname(domainListObj); 
           
        },

        async onUpdateEdgeHostname(domainListObj) {
            let toastOptions;
            this.loading = true;
             try {
                await this.updateEdgeHostname(domainListObj);
                toastOptions = {
                    message: "Updated Edge Hostname successfully!",
                    type: "success",
                };
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message

                toastOptions = {
                    message: this.error || "Something went wrong when updating hostname. Please contact support.",
                    type: "error",
                };
                this.$store.dispatch("addToast", toastOptions);                
                return
            }  
            this.loading = false;          
            this.$store.dispatch("addToast", toastOptions);
            this.$store.dispatch('hideModal');
            if(toastOptions.type == "success") {
                if(this.modalData) 
                    this.$store.dispatch("aut/getDomainListObject", this.modalData.edgeHostname);
                else
                    this.$router.push({ name: 'Configure CNames', params: {hostName: this.domainList.edgeHostname}})  
            }
           

        }
    }
}
</script>

<style>

</style>