<template>
	<div>
		<div id="override-div">
			<job-editor
				v-on:tabChange="showTab"
				:editMode="overrideEditMode"
				v-model="yamlContent"
				:activeTab="activeTab"
			></job-editor>
		</div>
	</div>
</template>
<script>
	import JobEditor from "../../../components/sandbox/JobEditor.vue";
	import { mapActions } from "vuex";
	export default {
		components: { JobEditor },
		data() {
			return {
				activeTab: "override.yaml",
				yamlContent: {},
			};
		},
		props: ["overrideEditMode", "isSandbox", "job", "override", "saveJobOverrideTrigger"],
		mounted() {
			this.initateJobEditorContent();
			this.$emit("hideEdit", true);
			this.$emit("hideSaveBtn", true);
			this.$emit("showOverrideEdit", true);
			this.$emit("hideRunJob", true);
			this.$emit("hidePromoteBtn", true);
			this.$emit("setOverrideEditMode", false);
		},
		methods: {
			...mapActions({
				updateOverrideFile: "sandbox/updateOverrideFile"
			}),   
			showTab(tab) {
				this.activeTab = tab;
			},
			initateJobEditorContent() {
				let yamlContent = {};
				if (this.override) {
					yamlContent["override.yaml"] = this.override?.override;
					this.yamlContent = JSON.parse(JSON.stringify(yamlContent));
				}
			},
            async saveOverride() {
				let yaml ={"override":this.yamlContent["override.yaml"]}
				let payload ={name:this.job.name, payload:yaml}
				let toastoptions =""
				await this.updateOverrideFile(payload).then((response)=>{
					this.$emit("setSnapOverride", {"override":yaml.override});
					toastoptions = {
						message: "Override file updated successfully.",
						type: "success"
					}
				}).catch((error)  => {
					if (error.response.data.error) 
						this.error = error.response.data.error
					else 
						this.error = error.message
					toastoptions = {
						message: this.error || "Error in updating override file.",
						type: "error"
					}
				});
				this.$store.dispatch('addToast', toastoptions);
            }
            
		},
		watch: {
			override() {
				this.initateJobEditorContent();
			},
            saveJobOverrideTrigger() {
                this.saveOverride();
            },
		},
		emits: [
			"hideEdit",
			"hideSaveBtn",
			"showOverrideEdit",
			"hideRunJob",
			"hidePromoteBtn",
			"setOverrideEditMode",
			"setSnapOverride"
		],
	};
</script>

<style></style>
