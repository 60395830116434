<template>
	<div class="card">
		<div class="card-header">
			<span>
				<h4>Privacy Configurations</h4>
			</span>
			<span class="add-btn">
				&nbsp;<button
					type="button"
					@click="
						showModal({
							component: 'PrivacyConfig',
							data: { edit: true, config: { signals: {} } },
						})
					"
					class="btn btn-primary text-right"
				>
					<i
						class="fa fa-plus"
						data-bs-toggle="tooltip"
						data-bs-placement="left"
						:title="
							userRoleRank >= 4
								? `User do not have permission to add privacy config. Please contact support`
								: `Add Privacy Config`
						"
					/>
				</button>
			</span>
		</div>
		<div
			class="card-body config-body p-1"
			v-if="privacyConfig && privacyConfig.length && Object.keys(privacySignals).length"
			:key="columns.length"
		>
			<Datatable
				tableId="usPrivacyConfig"
				:columns="columns"
				uniqueField="name"
				:data="privacyConfig"
				:showLoading="privacyConfig == null"
				:compact="true"
				:deletable="isPrivacyConfigDelete"
				@deleteRows="
					(data) =>
						showModal({
							component: 'DeletePrivacyConfig',
							data: data,
						})
				"
				:selectCheckboxes="true"
				:rowDelete="true"
				@onRowDelete="(data)=> onRowDelete(data)"
				:ignoreCache="true"
			/>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import Datatable from "../Datatable.vue";
	export default {
		components: { Datatable },
		data() {
			return {
				columns: [{ data: "name"}],
				allowedUserRoles:[1,3]
			};
		},
		computed: {
			...mapState({
				cid: (state) => state.currentClient.cid,
				privacyConfig: (state) => state.aut.privacyConfig,
				privacySignals: (state) => state.aut.privacySignals,
			}),
			userRoleRank() {
				return this.$store.getters.userRoles.userRoleRank;
			},
			isPrivacyConfigDelete() {
				return this.isAccessByUserRole(this.allowedUserRoles);
			}
		},
		methods: {
			...mapActions({
				showModal: "showModal",
				deletePrivacyConfig: "aut/deletePrivacyConfig",
			}),
			defineColumns() {
				this.columns = [
					{ data: "name" },
					{
						data: "signals.privacySignals",
						title: (() => {
							let info = " ";
							let signal = this.privacySignals?.privacySignals;
							if (signal) {
								Object.keys(signal).forEach((item) => {
									info +=
										item + ". " + signal[item] + "<br/>";
								});
							}
							return `<span   class="fa fa-circle-info mx-1" data-bs-title="${info}"  data-bs-toggle="tooltip" data-bs-placement="right" data-bs-container="body" data-bs-html="true"></span><span class="datatable-title">Privacy Signals</span>`;
						})(),
						render: function (data, type, row) {
							if (!data) return "";
							return Array.isArray(data) ? data.join(",") : data;
						},
					},
					{
						data: "signals.childSensitiveDataSignals",
						title: (() => {
							let info = " ";
							let signal =
								this.privacySignals?.childSensitiveDataSignals;
							if (signal) {
								Object.keys(signal).forEach((item) => {
									info +=
										item + ". " + signal[item] + "<br/>";
								});
							}
							return `<span class="fa fa-circle-info mx-1" data-bs-title="${info}"  data-bs-toggle="tooltip" data-bs-placement="right" data-bs-container="body" data-bs-html="true"></span><span class="datatable-title">Child Sensitive Data Signals</span>`;
						})(),
						render: function (data, type, row) {
							if (!data) return "";
							return Array.isArray(data) ? data.join(",") : data;
						},
					},
					{
						data: "signals.sensitiveDataProcessingSignals",
						title: (() => {
							let info = " ";
							let signal =
								this.privacySignals
									?.sensitiveDataProcessingSignals;
							if (signal) {
								Object.keys(signal).forEach((item) => {
									info +=
										item + ". " + signal[item] + "<br/>";
								});
							}
							return `<span   class="fa fa-circle-info mx-1" data-bs-title="${info}"  data-bs-toggle="tooltip" data-bs-placement="right" data-bs-container="body" data-bs-html="true"></span><span class="datatable-title">Sensitive Data Processing Signals</span>`;
						})(),
						render: function (data, type, row) {
							if (!data) return "";
							return Array.isArray(data) ? data.join(",") : data;
						},
					},
				];
			},
			isAccessByUserRole(roles) {
				return this.$store.getters.userRoles.isAccessByRole(roles);
			},
			async onRowDelete(row){
				let toastOptions;
				try {
                    await this.deletePrivacyConfig(row.name)
					toastOptions = {
						message: "Privacy config "+ row.name+" deleted successfully",
						type: "success",
					};                
				} catch (error) {
					if (error.response.data.error) 
						this.error = error.response.data.error
					else 
						this.error = error.message

					toastOptions = {
						message: this.error || "Something went wrong when deleting privacy config record. Please contact support.",
						type: "error",
					};
					this.$store.dispatch("addToast", toastOptions);
					
					return
				} finally {
					this.deleteInProgress = false;
				}
				this.$store.dispatch("addToast", toastOptions);            
				this.$store.dispatch("aut/fetchPrivacyConfig", this.cid);
			},
		},
		watch: {
			privacySignals() {
				this.defineColumns();
			},
		},
		mounted() {
			this.defineColumns();
		},
		beforeMount() {
			if (!this.privacyConfig?.length || !this.privacySignals) {
				this.$store.dispatch("aut/fetchPrivacyConfig", this.cid);
				this.$store.dispatch("aut/fetchPrivacySignals");
			}
		},
	};
</script>

<style></style>
