<template>
    <div class="modal-card card">
        <form @submit.prevent="onClickPrivacyConfig()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    {{editMode?"Add":""}} Privacy Config
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body" style="height:550px">
                <label></label>
                <div class="">
                    <InputField :editable="editMode" label="Name" type="text" 
                            :validate="[{ type: 'required', errMsg: 'name is required' },
                            { type: 'regex', pattern: '^[a-zA-Z0-9-_]+$',errMsg: 'name may contain only alphanumeric or - or _ characters'}
                        ]"
                            v-model="config.name" />
                    <InputField :editable="editMode" label="Privacy Signals" type="multiselect"
                            :toolTip="privacySignalInfo" :toolTipHtml="true" :options="privacySignalOptions" v-model="config.signals.privacySignals" />
                    <InputField :editable="editMode" label="Child Sensitive Data Signals" type="multiselect"
                            :toolTip="childSensitiveSignalInfo" :toolTipHtml="true" :options="childSensitiveSignalOptions"  v-model="config.signals.childSensitiveDataSignals" />
                    <InputField :editable="editMode" label="Sensitive Data Processing Signals" type="multiselect"
                            :toolTip="sensitiveDataSignalInfo" :toolTipHtml="true" :options="sensitiveDataSignalOptions"   v-model="config.signals.sensitiveDataProcessingSignals" />
                </div>
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
                <div v-if="message">
                    {{ message }}
                </div>
            </div>
            <div class="card-footer" v-if="!message && editMode">
                <div class="text-end">
                    <button type="submit" class="btn btn-primary" :disabled="btnDisabled">
                        <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Add
                       <!--  <div v-else>{{editMode?"Add":"Update"}}</div> -->
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import InputField from '../InputField.vue';
export default {
    components: { InputField },
    data() {
        return {
            error: "",
            message: "",
            config: {signals:{}},
            editMode:true,
            loading: false
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    name: "AddPrivacyConifg",
    methods: {
        ...mapActions('aut', {
            addPrivacyConfig: "addPrivacyConfig",
            updatePrivacyConfig: "updatePrivacyConfig",
        }),
        async onClickPrivacyConfig() {
            this.loading = true;
            this.error =null;
            let toastoptions;
            try {
                if(this.editMode)
                    await this.addPrivacyConfig(this.config);
                else
                    await this.updatePrivacyConfig(this.config);
                toastoptions = {
                    message: this.config.name+" config "+ (this.editMode?"added":"updated") + " successfully.",
                    type: "success"
                }
                this.$store.dispatch("aut/fetchPrivacyConfig", this.cid);
            } catch (error) {
                if (error.response.data.error) 
						this.error = error.response.data.error
					else 
						this.error = error.message
                toastoptions = {
                    message: this.error || "Something went wrong when adding the privacy config. Please try again!.",
                    type: "error"
                }

            }
            this.loading = false;
            this.$store.dispatch('addToast', toastoptions);
            if(!this.error)
                this.$store.dispatch('hideModal')
        }
    },
    computed: {
        ...mapState({
            cid: (state) => state.currentClient.cid
        }),
        privacySignalOptions() {
            let options=[];
            if(this.$store.state.aut.privacySignals.privacySignals){
                Object.keys(this.$store.state.aut.privacySignals.privacySignals).forEach(function(key) {
                    options.push({label:key, value:Number(key)})
                });
            }
            return options
        },
        childSensitiveSignalOptions(){
            let options=[];
            if(this.$store.state.aut.privacySignals.childSensitiveDataSignals){
                Object.keys(this.$store.state.aut.privacySignals.childSensitiveDataSignals).forEach(function(key) {
                    options.push({label:key, value:Number(key)})
                });
            }
            return options
        },
        sensitiveDataSignalOptions(){
            let options=[];
            if(this.$store.state.aut.privacySignals.sensitiveDataProcessingSignals){
                Object.keys(this.$store.state.aut.privacySignals.sensitiveDataProcessingSignals).forEach(function(key) {
                    options.push({label:key, value:Number(key)})
                });
            }
            return options
        },
        privacySignalInfo(){
            let info="";
            let signal =this.$store.state.aut.privacySignals.privacySignals;
            if(signal){
                Object.keys(signal).forEach((item)=>{
                    info+=item +". "+signal[item]+"<br/>"
                })
            }
            return info
        },
        childSensitiveSignalInfo(){
            let info="";
            let signal =this.$store.state.aut.privacySignals.childSensitiveDataSignals;
            if(signal){
                Object.keys(signal).forEach((item)=>{
                    info+=item +". "+signal[item]+"<br/>"
                })
            }
            return info
        },
        sensitiveDataSignalInfo(){
            let info="";
            let signal =this.$store.state.aut.privacySignals.sensitiveDataProcessingSignals;
            if(signal){
                Object.keys(signal).forEach((item)=>{
                    info+=item +". "+signal[item]+"<br/>"
                })
            }
            return info
        },
        btnDisabled(){
            let regex=new RegExp('^[a-zA-Z0-9-_]+$')
            if(this.config.name && this.config.name!=="" && regex.test(this.config.name))
                return false
            return true
        }
    },
    beforeMount(){
        this.editMode= this.modalData?this.modalData.edit:true;
        this.config= this.modalData?this.modalData.config:{signals:{}};
    }
}
</script>

<style></style>