<template>
  <div
    style="height: 100vh"
    class="
      d-flex
      align-items-center
      bg-auth
      border-top border-top-2 border-primary
    "
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-xl-4 my-5">
          <img src="../assets/logo2.png" style="width:250px" class="mx-auto d-block mb-5" alt="aqfer logo" />
          <div class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Loading'
}
</script>

<style>

</style>