<template>
    <div v-if="error" class="text-danger">
                    {{error}}
    </div>
    <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="row" v-else>
        <div class="col">
            <div class="card">
                <div class="card-body mt-2">
                    <pre class="mt-2 downloadLink " v-if="isDownloading"><code>Downloading...</code></pre>
                    <div v-if="downloadLink" class="p-2 downloadLink">
                         <p v-if="fileSize!=''">
                            File Size:&nbsp; {{ fileSize }}  
                        </p>
                        <p v-if="fileTime!=''">
                            Created:&nbsp; {{ fileTime }}
                        </p>
                        <a :href="downloadLink" class="mb-4"
                            @click.prevent="downloadFile(downloadLink, filename)">
                                <span class="fa fa-download"></span>&nbsp;{{filename}}</a> &nbsp;
                        <button v-if="showOutputParser" @click="showModal({ component: 'CustomComponent', data: { component:'OutputParser',path:this.folderPath,limit:5,filename:this.filename,screenlarge:true, title: 'File Preview' } })" class=" btn btn-primary">Preview File</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import { DateTimeFormat, formatBytes} from '../../utils/commonFunction'
import axios from 'axios';
export default {
    name: "FileView",
    data() {
        return {
            loading:true,
            isDownloading: false,
            downloadLink:"",
            error: null,
            filename:'',
            fileSize:'',
            fileTime:''
        }
    },
    beforeMount() {
        this.fetchFileData();
        if(this.$route.params.data){
            let data =JSON.parse(this.$route.params.data)
            this.fileSize =  formatBytes(data.size?data.size:0);
            this.fileTime =  DateTimeFormat(data.time?data.time:"");
        }
    },
    computed:{
        folderPath(){
            //removing ending / from path
           return this.$store.state.amdp.folderPath.slice(0, -1);  
        },
        showOutputParser(){
            return (this.filename.includes(".avro") || this.filename.includes(".parquet"))? true: false
        }
    },
    methods: {
        ...mapActions({
            showModal: "showModal",
            getPresignURL: "amdp/getPresignURL",
        }),
        async fetchFileData() { 
            this.filename=this.folderPath.split("/").slice(-1)[0]
            let response;
            if(this.filename!=""){
                try {
                    response = await this.getPresignURL({filePath:this.folderPath, operation:"download", sandboxName: this.$route.params.sandboxId },);
                } catch (error) {
                    this.loading=false
                    if (error.response.data.error) this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
                let data = await response.data
                this.downloadLink =  data.url;
            }else{
                this.$router.push({
                    name:'File Browser List',
                    params: {
                        bucket: this.$route.params.bucket,
                        cid:this.$store.state.currentClient.cid,
                        sandboxId:this.$route.params.sandboxId
                        } 
            })
            }
            this.loading = false
        },
        async downloadFile (url, label) {
            this.isDownloading=true;
            await axios.get(url, 
                { 
                    responseType: 'blob' 
                }).then(response => {
                    
                    var blob = new Blob([response.data], {type: "text/plain"});
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = label;
                    a.click();
                });
            this.isDownloading=null
    }
    },
  
}
</script>
<style></style>