<template>
    <div id="editor-container">
        <div id="wysiwyg-editor">
        </div>
    </div>
</template>

<script>
import Quill from "quill";
export default {
    name: "WYSIWYG",
    props: {
        modelValue: String,
    },
    data (){
        return {
            quill: null,
        }
    },
    methods: {
        initialize() {

            const toggle = document.querySelector('#wysiwyg-editor');

            const elementOptions = {}

            const defaultOptions = {
                modules: {
                    toolbar: [
                        ["bold", "italic"],
                        ["link", "blockquote", "code", "image"],
                        [
                            {
                                list: "ordered",
                            },
                            {
                                list: "bullet",
                            },
                        ],
                    ],
                },
                theme: "snow",
            };

            const options = {
                ...elementOptions,
                ...defaultOptions,
            };

            var self = this;
            this.quill = new Quill(toggle, options);
            this.quill.root.innerHTML = this.modelValue
            this.quill.on('text-change', function() {
                let contents = self.quill.root.innerHTML
                self.$emit(`update:modelValue`, contents);
            });
        },
    },
    mounted() {
        this.initialize();
    },
};
</script>

<style>
    #wysiwyg-editor{
        position: relative;
    }
</style>