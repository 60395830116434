<template>
  <basic-header pretitle="aqfer Universal Tag" title="Tag Settings" />
  <div class="container-fluid" id="tag_dashboard">
    <div class="row" id="general-settings-div" v-if="generalSettings">
        <div class="col-lg-7" >
            <div class="card-title clearfix">
                <h4 class="d-inline-block" >{{cid}}</h4> 
                 <span v-if='editMode' class="float-end">
                    <button @click="onSaveClick" class="btn btn-success"> Save </button>&nbsp;
                    <button @click="onCancelClick" class="btn btn-secondary "> Cancel </button>&nbsp;
                </span>
                <span v-else  class="float-end">
                    <button type="button" @click="onEditClick" class="btn btn-primary"  :disabled ="userRoleRank < 4 ? false:true">
                        Edit
                        <i v-if="userRoleRank >= 4" class="fa fa-circle-exclamation" title="Your user do not have permission .Please contact support" data-bs-toggle="tooltip" data-bs-placement="right"></i>
                    </button>
                </span>
            </div>
            <div class="card">                
                <div class="card-body">                                   
                    <form-fields :fields="autGeneralFields" v-model="generalSettings" v-if="generalSettings && clientData" :inlineField=true :editMode="editMode"/>                
                </div>
            </div>
            <privacy-config></privacy-config>
        </div>
         <div class="col-lg-5">
            <div class="card-title clearfix">
                <h4 class="d-inline-block">Deployment Status</h4>
                <div class="float-end">
                    <button type="button" v-if ="showRefreshButton" class="btn btn-primary" @click="onRefreshClick"><i class="fa fa-refresh"></i></button> &nbsp;
                    <button type="button" v-if ="userRoleRank < 4 " class="btn btn-primary" :disabled ="!enableActivateButton"  @click="onActivateClick" >
                        Activate
                        <i v-if="clientData && !clientData.enabled" class="fa fa-circle-exclamation text-danger" title="Activation requires client to be enabled!" data-bs-toggle="tooltip" data-bs-placement="right"></i>
                        <div
                            v-else-if="!enableActivateButton"
                            class="spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>                        
                        <i v-else-if="needActivation" class="fa fa-circle-exclamation text-warning" title="Client config changes have not been deployed. Activate to deploy the changes!" data-bs-toggle="tooltip" data-bs-placement="right"></i>
                    </button>
                </div>
            </div>           
            <div class="card" v-if="autdeploymentStatus"> 
                <div class="card-body">  
                    <form-fields :fields="deploymentStatusFields" v-model="autdeploymentStatus" v-if="autdeploymentStatus" :inlineField=true :editMode=false />                
                </div>
            </div>
             <div v-else class="text-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>             
        </div>
    </div>
    <div v-else class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div> 
  </div>
  
</template>

<script>

import BasicHeader from '../../components/BasicHeader.vue';
import getAutGeneralFields from "../../utils/field-constants/autGeneralFields";
import getDeploymentStatusFields from "../../utils/field-constants/deploymentStatusFields";
import FormFields from "../../components/FormFields.vue";
import { deepEqual } from "../../utils/commonFunction";
import { mapActions, mapState } from "vuex";
import PrivacyConfig from '../../components/aut/PrivacyConfigDatatable.vue'
const Diff = require("diff");

export default {
    components: { FormFields, BasicHeader, PrivacyConfig },
    data() {
        return {            
            editMode: false,
            autGeneralFields: [],
            deploymentStatusFields: [],
            snapshot: null,
            error: "",
            successMessage: "",
            actError: "",
            actSuccessMessage: "",
            generalSettings: null,
            undeployAlertFlag: false,
            testinfo: " 1. Share Consumer's Personal Data with Third Parties<br/>2. Sale of the Consumer's Personal Data<br/>3. Share Consumer's Personal Data<br/>4. Processing of the Consumer's Personal Data for Targeted Advertising<br/>5. Processing of the Consumer's Personal Data<br/>6. Limit Use or Disclosure of the Consumer's Sensitive Data<br/>7. Collect, use, retain, sale, and/or Sharing of the Consumer's Personal Data that Is Unrelated to or Incompatible with the Purpose(s) for which the Consumer's Personal Data Was Collected or Processed<br/>",
            deprecatedFields: ['gpp.enabled','gdprStatus', 'gdpr.gvlId', 'gdpr.queryStringLogList','usPrivacyStatus'],
        };
    },    
    computed: {
     ...mapState({ 
            cid: state => state.currentClient.cid,               
            autdeploymentStatus: state => state.aut.deploymentStatus,
            prodClientData: state => state.aut.autProdClientData,
            clientData: state => state.currentClient
        }),
        userRoleRank() {
            return this.$store.getters.userRoles.userRoleRank;
        }, 
        enableActivateButton(){
            return  (this.autdeploymentStatus && (this.autdeploymentStatus.status == '' || this.autdeploymentStatus.status == 'COMPLETED' || this.autdeploymentStatus.status =='ABORTED'))
        },
        showRefreshButton(){
            return this.autdeploymentStatus && this.autdeploymentStatus.status !== '' && this.autdeploymentStatus.status !=='COMPLETED'
        },
        needActivation() {            
            return !deepEqual(this.prodClientData, this.clientData);
        }

   },
    watch: {       
        generalSettings: {
            deep: true,
            handler(newVal) {
                this.generalSettings.gpp.tcf.gvlId =   this.generalSettings.gpp.tcf.status == "DISABLED"? null:this.generalSettings.gpp.tcf.gvlId;
                this.autGeneralFields = this.hideFields(this.autGeneralFields, newVal); 
            }            
        }, 
        autdeploymentStatus(newVal) {
            if(newVal && newVal.status == "ABORTED") {
                this.deploymentStatusFields.map((field)=>{
                    if(field.name =="status"){
                        field.helpText = `<span><i class="fa fa-circle-info text-danger"></i>&nbsp;<strong class="text-danger">Support has been notified of this failure. It will be resolved as soon as possible.</strong></span>
                </div>  `
                    }                    
                })

            }           
        }       
    },
    methods: {        
        ...mapActions({
            updateAutClientData: "aut/updateAutClientData",
            fetchAutProdClientData: "aut/fetchAutProdClientData",            
            activateClientConfig: "aut/activateClientConfig",
            showModal: "showModal"
        }),
        initializeData(){  
            const autClientData =  JSON.parse(JSON.stringify(this.clientData));        
            this.generalSettings = autClientData;
            //Adding custom fields and values  
            this.generalSettings.logCookies = autClientData.log_cookies ? (autClientData.log_cookies.client ? ((autClientData.log_cookies.client.length == 1 && autClientData.log_cookies.client[0] == '*') ? 'ALL' : 'SPECIFIC') : 'ALL') : 'ALL';
            this.generalSettings.cookieList =  this.generalSettings.logCookies === 'SPECIFIC' ? autClientData.log_cookies.client : []
            let generalFields = getAutGeneralFields(); 
            this.autGeneralFields = this.generalSettings? this.hideFields(generalFields, this.generalSettings): generalFields;
            this.deploymentStatusFields = getDeploymentStatusFields();
            this.snapshot = JSON.parse(JSON.stringify(this.generalSettings));
        },
        setEditMode(mode) {           
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        onEditClick() {
            this.setEditMode(true)
        },
        onCancelClick() {
            this.setEditMode(false);
            this.generalSettings = JSON.parse(JSON.stringify(this.snapshot));
            this.error = null
        },
        hideFields(fields, obj) {
            let self = this;
            return fields.map((field) => {
                if(field.suppressIfField) {                                        
                    const val = field.suppressIfField.split('.').reduce((o, i) => o[i], obj);
                    if(field.suppressIfValueContains.indexOf(val) > -1)
                        field.visible = false;
                    else
                        field.visible = true;     
                    
                }
                if(self.deprecatedFields.includes(field.name)){
                        field.visible = false;
                }
                return field;
            }); 
        },
       
        async onSaveClick() {
            let toastoptions;
            this.undeployAlertFlag = true;
            try {
                let updatedGeneralSettings = JSON.parse(JSON.stringify(this.generalSettings ));
                // Change custom variables to api compatible
                updatedGeneralSettings.log_cookies = {};
                updatedGeneralSettings.log_cookies.system = this.clientData.log_cookies.system;
                updatedGeneralSettings.log_cookies.client = updatedGeneralSettings.logCookies ? ( updatedGeneralSettings.logCookies === 'ALL' ?  ['*'] : updatedGeneralSettings.cookieList) : ['*'];                                
                delete updatedGeneralSettings.logCookies;
                delete updatedGeneralSettings.cookieList; 
                
                updatedGeneralSettings.gpp.tcf.gvlId =  (updatedGeneralSettings.gpp.tcf.gvlId&&updatedGeneralSettings.gpp.tcf.gvlId!=='')?Number(updatedGeneralSettings.gpp.tcf.gvlId):null;         

                const response = await this.updateAutClientData(updatedGeneralSettings);
                this.setEditMode(false);
                this.$store.dispatch('setUpdatedCurrentClient', this.cid)
                this.snapshot = JSON.parse(JSON.stringify(this.generalSettings));
                this.successMessage = 'Client settings updated successfully.'
                toastoptions = {
                    message: "Client settings updated successfully.",
                    type: "success"
                }
            } catch (error) {
                if (error.response && error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;

                toastoptions = {
                    message: this.error || "Something went wrong when updating client settings. Please contact support.",
                    type: "error"
                }
            }  
            this.$store.dispatch('addToast', toastoptions);          
        },

        async onActivateClick() {
            this.showModal({ component: 'DiffViewModal' ,  data: {prev:this.prodClientData, current:this.clientData, saveMethod:this.activateClient, cancelMethod: this.onCancelClick, modalTitle: "Update Config? (activate/cancel)", actionBtnText: "Activate", diffExists: this.needActivation}}); 
        },

        async activateClient() {
            let toastoptions;
            this.undeployAlertFlag = false;
            try {
                const response = await this.activateClientConfig();                
                this.actSuccessMessage = 'Activation successful.';
                this.fetchDeploymentData(); 
                toastoptions = {
                    message: "Activation submitted successfully.",
                    type: "success"
                }
            } catch (error) {
                if (error.response.data.error)
                    this.actError = error.response.data.error;
                else this.actError = error.message;

                toastoptions = {
                    message: this.error || "Something went wrong while activating deployment. Please contact support.",
                    type: "error"
                }
            }

            this.$store.dispatch('addToast', toastoptions);    
        },
       
        fetchDeploymentData() {            
            this.$store.dispatch("aut/fetchDeploymentStatus");
            this.$store.dispatch("aut/fetchAutProdClientData");
            this.$store.dispatch("aut/fetchPrivacyConfig",this.cid);
            this.$store.dispatch("aut/fetchPrivacySignals");
            
        },
        onRefreshClick(){
            //to show loading, clearing deployment status
            this.$store.dispatch("aut/setDeploymentStatus", null);
            this.$store.dispatch("aut/fetchDeploymentStatus");
        } 
    }, 
       
    mounted() {
        this.initializeData();
    },
    
    beforeMount() {           
        this.$store.dispatch('setUpdatedCurrentClient', this.cid) 
        this.fetchDeploymentData();   
    },
   
    beforeRouteLeave (to, from , next) {
        let self = this;
        if(this.needActivation && this.undeployAlertFlag) {
            this.showModal({
                component: "confirm",
                data: {
                    title: "Undeployed changes",
                    message: `<div class="alert alert-warning">Do you really want to leave? You have changes that haven’t been deployed.</div>`,
                    async confirm() {
                        self.undeployAlertFlag = false;
                        next()
                    },
                    async cancel() {
                        self.undeployAlertFlag = false;
                        next(false)
                    }
                },
            });           
        } else {
            next()
        }        
    }

}
</script>

<style scoped>
.add-btn{
    position: absolute;
    top: 10px;
    right: 10px;
}
.config-body{
    padding: 0%;
}
</style>