<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">{{ modalData.streams?this.modalData.streamData.id:this.modalData.executionId }}</h4>
            <!-- Close -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card-body viewlog">
            <div class="row" style="--bs-gutter-x:0">
                <div class="col">
                    <tabs :tabs="tabItems">
                        <template v-slot:logs>
                            <div class="mb-3">
                                <div v-if="loading" class="text-center p-3">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <div v-if="log">
                                    <CodemirrorEditor editorId="viewLog" :lineWrapping="true" v-model="log"
                                        mode="text/plain" class="form-control logEditor" :disabled="true" :readOnly="true">
                                    </CodemirrorEditor>
                                    <input type="hidden" v-on:focus="$event.target.select()" class="copy" v-model="log" />
                                </div>
                                <div v-else-if="!loading" class="pt-3">
                                    No Log Files
                                </div>
                            </div>
                        </template>
                        <template v-slot:progress v-if="modalData.status == 'running'">
                            <div class="mt-3" id="progress-config-div">
                                <JobExecutionProgress :sandboxName="modalData.sandboxName" :jobName="modalData.jobName"
                                    :executionId="modalData.executionId"></JobExecutionProgress>
                            </div>
                        </template>
                    </tabs>
                </div>
            </div>
        </div>
        <div class="card-footer text-end">
            <button v-if="log" @click="copy" :class="copyText == 'Copy Log' ? 'btn btn-primary' : 'btn btn-success'">{{
                copyText }}</button>&nbsp;
            <button data-bs-dismiss="modal" class="btn btn-secondary" aria-label="Close">
                Close</button>&nbsp;
        </div>
    </div>
</template>

<script>

import { mapActions } from "vuex";
import axios from "axios";
import CodemirrorEditor from "../../components/CodemirrorEditor.vue";
import Tabs from "../../components/Tabs.vue";
import JobExecutionProgress from '../../components/sandbox/JobExecutionProgress.vue';
export default {
    name: "ViewLog",
    components: { CodemirrorEditor, JobExecutionProgress, Tabs },
    data() {
        return {
            copyText: 'Copy Log',
            log: '',
            error: null,
            loading: true
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        }
    },
    watch: {
        "modalData.status"(val) {
            if (val !== "running") {
                this.$store.dispatch('setActiveTab', 'Logs');
            }

        }
    },
    computed: {
        tabItems() {
            return [
                {
                    name: "Progress",
                    id: "id1",
                    slot: "progress",
                    hide: this.modalData.status == 'running' ? false : true
                },
                {
                    name: this.modalData.streams?'Log Stream': 'Logs',
                    id: "id2",
                    slot: "logs"
                }


            ]

        }

    },
    methods: {
        ...mapActions({
            fetchSandboxLog: "sandbox/fetchSandboxLog",
        }),
        copy() {
            //select the text in input field and perform copy
            let codeToCopy = document.querySelector('.copy')
            codeToCopy.setAttribute('type', 'text')
            codeToCopy.select()
            document.execCommand('copy');
            codeToCopy.setAttribute('type', 'hidden')
            this.copyText = "Copied!"
        },
        async getLog() {
            try {
                this.loading = true
                this.error = null
                let response = {}
                response = await this.fetchSandboxLog({ payload: this.modalData })
                this.log = response.data ? response.data.Logs : null
            } catch (error) {
                this.error = error.message
            } finally {
                this.loading = false
            }
        },
        getLogStreamByID(stream) {
            return axios.get(this.modalData.baseEndpoint+ `/jobs/${this.modalData.jobName}/executions/${this.modalData.executionId}/logs?source=${stream.source}&log_stream_id=${stream.id}&type=${stream.type}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`
                }
            }).then((response)=>{
                this.loading = false;
                this.log = response.data ? response.data.Logs : null
            }).catch((error) => {
                this.loading = false;
                this.log = null;
            })

        },
    },
    beforeMount() {
        if(this.modalData.streams) {
            this.loading = true
            this.getLogStreamByID(this.modalData.streamData);
        } else {
            this.getLog();

        }

              
        if (this.modalData.status !== "running" && !this.modalData.streams) {
            this.$store.dispatch('setActiveTab', 'Logs');
        } else {
            this.$store.dispatch('setActiveTab', 'Log Stream');
            //this.$store.dispatch('setActiveTab', 'Log Streams');
        }
    },
    beforeUnmount() {
        if(this.modalData.streams);
            this.$store.dispatch('setActiveTab', 'Log Streams');
    }

}
</script>

<style scoped>
.logEditor {
    padding: 0;
}
</style>