<template>

      <!-- NAVIGATION -->
    <nav :class="[this.$store.state.collapseNav ? 'navbar-vertical-sm':'', 'navbar navbar-vertical fixed-start navbar-expand-md navbar-dark scrollbar-container']"  id="sidebar">
      <div class="container-fluid">
        <!-- Togglers -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <button class="nav-collapse-btn d-sm-none d-md-block" type="button" @click="toggleCollapseNav(client)">
          <i :class="[this.$store.state.collapseNav ? 'fa-square-caret-right':'fa-square-caret-left', 'fa-regular']"></i>
        </button>
    
        <!-- Brand -->
        <router-link class="navbar-brand" to="/">
          <img v-if="!this.$store.state.collapseNav" src="../assets/logo.png" class="navbar-brand-img mx-auto" alt="aqfer logo">
          <img v-else src="../assets/icon-logo.png" class="navbar-brand-collapsed-img mx-auto" alt="aqfer logo">
          <h2 class="navbar-app-name my-auto">{{ $route.params.appId }}</h2>
        </router-link>
    
        <!-- User (xs) -->
        <div class="navbar-user d-md-none">
    
          <!-- Dropdown -->
          <div class="dropdown">
    
            <!-- Toggle -->
            <a href="#" id="sidebarIcon" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="avatar avatar-sm avatar-online">
                <div class="user-avatar" :style="{backgroundColor:randomColor}">{{avatarLetter}}</div>
              </div>
            </a>
    
            <!-- Menu -->
            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
              <!-- <a href="./profile-posts.html" class="dropdown-item">Profile</a>
              <a href="./account-general.html" class="dropdown-item">Settings</a>
              <hr class="dropdown-divider"> -->
              <router-link to="/logout" class="dropdown-item">Logout</router-link>
            </div>
    
          </div>
    
        </div>
    
        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidebarCollapse" @click="navClicked" >

          <!-- Client Drop Down -->
          <div v-if="!this.$store.state.collapseNav">
            <h6 class="navbar-heading mb-0"> CLIENT </h6>
            <div class="client-dropdown dropdown mb-2 d-grid gap-2" @keyup.down="onArrowDown" @keyup.up="onArrowUp"  @keyup.enter ="selectCid">
              <button class="btn btn-secondary btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click=" focusSearch()">
                {{ $store.state.currentClient ? $store.state.currentClient.cid + ' - ' + $store.state.currentClient.name : 'Loading...' }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="">
                <input v-if="$store.state.clients.length > 7" ref="searchCid" type="search" class="form-control form-control-sm searchCid" v-model="searchCid" placeholder="Search..." @input="arrowCounter=0">
                <a 
                  v-for="client, index in clientList" 
                  :key="client.cid" 
                  href="#"
                  :class="[$store.state.currentClient.cid == client.cid ? 'active pe-none':'', 'dropdown-item',  arrowCounter == index ? 'searchActive':'']" 
                  @click.prevent="setCurrentClient(client)">
                    {{client.cid}} - {{client.name}}
                </a>
              </div>
            </div>
          </div>

          <!-- Divider -->
          <hr class="navbar-divider my-3">

          <!-- Navigation -->
          <ul class="navbar-nav">
            <NavItem v-for="(item) in navItems" :key="item.name" :item="item"  /> 
          </ul>
    
          <!-- Push content down -->
          <div class="mt-auto"></div>
    
    
            <!-- User (md) -->
            <div class="navbar-user d-none d-md-flex" id="sidebarUser">
              <!-- Icon -->
              <a v-if="!this.$store.state.collapseNav" class="navbar-user-link" target="_blank" :href="`https://help.aqfer.net`" data-bs-toggle="tooltip" data-bs-placement="top" title="Knowledge Base">
                <span class="icon">
                  <i class="fa fa-circle-info"></i>
                </span>
              </a>
    
              <!-- Dropup -->
              <div class="dropup">
    
                <!-- Toggle -->
                <a href="#" id="sidebarIconCopy" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div class="avatar avatar-sm">
                    <div class="user-avatar" :style="{backgroundColor:randomColor}">{{avatarLetter}}</div>
                  </div>
                </a>
    
                <!-- Menu -->
                <div class="dropdown-menu" aria-labelledby="sidebarIconCopy">
                  <!-- <a href="./profile-posts.html" class="dropdown-item">Profile</a>
                  <a href="./account-general.html" class="dropdown-item">Settings</a>
                  <hr class="dropdown-divider"> -->
                  <span class="dropdown-item">{{ $store.state.currentUser.email }}</span>
                  <hr class="dropdown-divider"> 
                  <router-link to="/logout" class="dropdown-item">Logout</router-link>
                </div>
    
              </div>
    
              <!-- Icon -->
              <a v-if="!this.$store.state.collapseNav" class="navbar-user-link" target="_blank" href="mailto:support@aqfer.com?Subject=CX Portal Support" data-bs-toggle="tooltip" data-bs-placement="top" title="Contact Us">
                <span class="icon">
                  <i class="fa fa-envelope"></i>
                </span>
              </a>
    
            </div>
    
        </div> <!-- / .navbar-collapse -->
    
      </div>
    </nav>
</template>

<script>
import NavItem from './NavItem.vue'
import { Tooltip } from 'bootstrap'

export default {
    name: 'Sidebar',
    components: {
        NavItem
    },
    data(){
      return {
        searchCid:"",
        arrowCounter: 0
      }
    },
    props: {
        msg: String
    },
    computed: {
        navItems() {
            let filteredNavItems = [];
            let clientFeatures = [];
            if(this.$store.state.currentClient) {
                clientFeatures = this.$store.state.currentClient.config.features;
                filteredNavItems = JSON.parse(JSON.stringify(this.$store.state.navItems));
            }

            // Filter optional nav items based on current client config features array
            filteredNavItems = filteredNavItems.filter((item) => {
                if (item.optional) {
                    return clientFeatures.filter(function (feature) {
                        return feature.name === item.featureId;
                    }).length != 0
                } else {
                    return true;
                }
            });

            //Filter child features by usinf property "featureItemID"
            filteredNavItems = filteredNavItems.map((obj) => {
                if(obj.children) {
                  obj.children = obj.children.filter((childNav) => {
                    if(typeof childNav.featureItemID !== "undefined") {
                      const filterenFEatiure =  clientFeatures.filter(function (feature) {
                          return (feature[childNav.featureItemID] && feature[childNav.featureItemID].length)? true: false;
                      })
                      return (filterenFEatiure.length !== 0);                      
                    } else {
                      return true
                    } 
                  })
                }
                return obj;
            });
            

            // Populate "apps" nav item based on current client features array objects with key uri     
            let appsArray = [];
            clientFeatures.forEach(obj => {
                if (obj.uri) {
                    let rObj = {}
                    rObj["name"] = obj.name;
                    rObj["route"] = `/apps/${obj.name}`;
                    rObj["uri"] = obj.uri;
                    appsArray.push(rObj);
                }

            });

            if (appsArray.length) {
                filteredNavItems = filteredNavItems.map(obj =>
                    obj.name === "Apps" ? { ...obj, children: appsArray } : obj
                );
            } else {
                filteredNavItems = filteredNavItems.filter((el) => el.name != "Apps");
            }

            return this.filterNavItemsByRole(filteredNavItems)
        },
        randomColor(){
          let str=this.$store.state.currentUser.name
          let s=50;//Saturation
          let l=80;//lightness
          var hash = 0; //Unique hash value for string will be calculated
          for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
          }
          var h = hash % 360;
          return 'hsl('+h+', '+s+'%, '+l+'%)';
        },
        avatarLetter(){
          if(this.$store.state.currentUser.name && this.$store.state.currentUser.name.length>2)
            return this.$store.state.currentUser.name. slice(0, 2) 
          return this.$store.state.currentUser.name
        },
        clientList(){
          if(this.searchCid!==""){
            return this.$store.state.clients.filter((client)=>{
               return (client.cid+" - "+client.name).toLowerCase().includes(this.searchCid.toLowerCase())
            })
          }
          return this.$store.state.clients
        }
    },
    methods: {
        async setCurrentClient(client) {
          const currentParams = this.$route.params;
          // Add a key as value to param when param is null
          if(currentParams){
            for(const param in currentParams) {
              if(!currentParams[param])
                currentParams[param]= param
            }

          }

          
          this.$store.dispatch('setCurrentClient', client.cid);
          await this.$store.dispatch('sandbox/fetchSandboxes', client.cid);           
          //Get initailSandbox for the selected cid
          let initializeSandbox = this.$store.getters["sandbox/initializeCurrentSandbox"](this.$route);
          //Adding string null to avoid router.push failure
          initializeSandbox = initializeSandbox?initializeSandbox:"null"
          let newParams = (this.$route.params.sandboxId)?{ cid: client.cid, sandboxId: initializeSandbox}: {cid: client.cid};  
          const mergedParams = { ...currentParams, ...newParams }; 
          if(this.$route.meta?.cidChangeRedirectRoute){
            await this.$router.replace({name:this.$route.meta.cidChangeRedirectRoute, params:mergedParams});
          } else {
            await this.$router.replace({ params: mergedParams });
          }
        },
        filterNavItemsByRole(filteredNavItems) {
            let currentUserRoles = this.$store.state.currentUser.roles;
            let email = this.$store.state.currentUser.email;
            var self = this; 
            //Filter based on roles and if domain specific
            filteredNavItems = filteredNavItems.filter((item) => {
                if (item.domainSpecific && email.indexOf(item.domainSpecific) === -1) {
                    return false;
                }
                if (item.environmentRestrictions && item.environmentRestrictions.indexOf(this.$store.state.environment) === -1) {
                    return false;
                }
                if (item.roles) {
                    return item.roles.some(r => currentUserRoles.includes(r));
                }
                if (item.stateConditional) {
                    return self.$store.getters[item.stateConditional]
                }
                return true;
            });

            //check children
            for (const item of filteredNavItems) {
                if (item.children) {
                    item.children = this.filterNavItemsByRole(item.children)
                }
            }

            return filteredNavItems;
        },
        toggleCollapseNav(){
          this.$store.dispatch('setCollapseNav', !this.$store.state.collapseNav)
        },
        navClicked(e) {
          this.$store.dispatch('setCollapseNav', false)
        },
        focusSearch(){
          // focus only if search field is visible
          if(this.$store.state.clients.length > 7)
            this.$refs.searchCid.focus()
        },
        onArrowDown(ev) {
          ev.preventDefault()
          if (this.arrowCounter < this.clientList.length) {
            this.arrowCounter = this.arrowCounter + 1;
          }
        },
        onArrowUp(ev) {
          ev.preventDefault()
          if (this.arrowCounter > 0) {
            this.arrowCounter = this.arrowCounter - 1;
          }
        },
        selectCid(){
           //if there is any search found then on enter select first
           if(this.$store.state.clients.length && this.clientList.length){
            this.setCurrentClient(this.clientList[this.arrowCounter]) 
            document.getElementById("dropdownMenuButton").click();
            this.searchCid =""
            this.arrowCounter=0;
          }
        }
        

    },
    mounted() {
        //activate tooltips
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        }) 
    }
}
</script>

<style scoped lang="scss">
  @import "../theme/scss/_user-variables.scss";
  *{
  --var-height: 20px;
  --var-badge-color: #4eaf94;
  }
  .user-avatar {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size:20px;

    /* Colors */
    color: #000;

    /* Rounded border */
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
}
.searchCid{
  width:85%;
  margin-left:20px;
}
.searchActive{
  background-color: #edf2f8;
}

.navbar-brand-collapsed-img{
  margin-top: 1.25em;
  max-height: 2em;
}

.navbar-vertical-sm.navbar-expand-md {
  padding-right: 0.25rem !important
}

.nav-collapse-btn{
  background: transparent;
  border:transparent;
  position: absolute;
  right:-6px;
  top:2px;
  font-size: 20px;
  color: $gray-700;
}
</style>
