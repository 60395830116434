<template>
  <basic-header
        pretitle="aIO"
        title="Handlers"
    />

    <div class="container-fluid" v-if="sandBoxListOptions.length">
        <input-field
            label="Choose a Sandbox"
            type="select"
            :options="sandBoxListOptions"
            v-model="selectedSandbox"
        />  
        <div class="row mb-1">
            <div class="col-md-8">
                <p> </p>
                <p>Handler List</p>
            </div>
            <div class="col-md-4">
                <div class="text-end">
                    <button @click="showModal({ component: 'addHandler' , data: {kind : ''} })" class="btn lift btn-primary" >
                         Create Handler
                    </button>                  
                </div>
            </div>
        </div>  
        <div class="card">
            <datatable
                tableId="handlerTable"
                :columns="columns"                
                :formatColumn="formatColumn"
                :orderColumn="orderColumn"
                :data="jobList"   
                :showLoading="jobList===null"             
                @deleteRows="showDeleteHandlers"
                :deletable="true"
                :compact="true"
                :selectCheckboxes="true"
                uniqueField="name"
                :detailColumn="{renderBaseUrl: (data) => {
                                                    return `handler-sandbox/${data.name}/configuration`;                                                   
                                                },}"
            />
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions } from "vuex";
import InputField from "../../components/InputField.vue";
export default {
    components: { BasicHeader, Datatable, InputField },
    data() {
        return {
            orderColumn: [3, 'desc'],
            columns: [
                { 
                    data: "name",
                    title: "Handler Name"
                },                
               
                {
                    data: "created_at", 
                    title: "Created At"
                }, 
                
                {
                    data: "created_by", 
                    title: "Created by"
                },           
            ],
            formatColumn:[
                {
                    index: 3,
                    type:"datetime"
                }
            ],
        };
    },
    methods: {
        ...mapActions({
            fetchSandboxJobs: "sandbox/fetchSandboxJobs",
            showModal: "showModal",
        }),
        showDeleteHandlers(rows) {
            this.showModal({ component: "deleteSandboxJobs", data: rows });
        },
    },

    computed: {
        selectedSandbox: {
            get() {
                return this.$store.state.sandbox.currentSandbox;
            },
            set(value) {
                if(value) {  
                    this.$store.dispatch('sandbox/setCurrentSandbox',value); 
                        this.$router.push({
                            name: "aIO Handler Sandbox",
                            params: {
                                sandboxId: `${value}`
                            },
                        });    
                }
                                        
            }
        },
        sandBoxListOptions() {
            const listOtpions =  this.$store.state.sandbox.devSandboxes? this.$store.state.sandbox.devSandboxes.map((sandbox) => {
                return {"label": sandbox.name, "value": sandbox.name };                
            }) : [];
            return listOtpions;
        },
        jobList(){
            let jobList=[];
            this.$store.state.sandbox.sandboxJobs.filter((job) => {
                return job.kind === "AIO-HANDLER" || job.kind === "AIO-HANDLER-DBDS" || job.kind === "AIO-HANDLER-GRAPHDS";
            }).map((job)=>{
                let status = job.last_run_status?job.last_run_status.toLowerCase():'';
                let statusStr = job.last_run_status;
                if(status==="succeeded" || status==="completed"){
                    statusStr = "<span class='text-success'>●</span> "+job.last_run_status;
                } else if(status==="failed"){
                    statusStr = "<span class='text-danger'>●</span> "+job.last_run_status;
                } else if(status==="running"){
                    statusStr = "<span class='text-primary'>●</span> "+job.last_run_status;
                } else if(status==="submitted"){
                    statusStr = "<span class='text-warning'>●</span> "+job.last_run_status;
                } else if(status==="cancelled"){
                    statusStr = "<span class='text-secondary'>●</span> "+job.last_run_status;
                }
                job["last_run_status"] = statusStr;
                jobList.push(job);
            })
            return jobList;
        }  
    },

    async beforeMount() {
        await this.fetchSandboxJobs(); 
    },

}
</script>

<style>

</style>