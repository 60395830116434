<template>
    <basic-header pretitle="Administration" title="Entitlement Usage" :compact="true" />

    <div class="container-fluid">
        <tabs :tabs="tabItems">
            <template v-slot:current>

                <h2 class="mt-4">Current Month Usage ({{startDateLabel}} - {{endDateLabel}})</h2>
                
                <div v-if="loading" class="text-center mt-5">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                
                
                <div class="row mt-4">
                    <div class="col-md-4">
                        
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-header-title">Usage Credits</h2>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center gx-0">
                                    <div class="col">
                                        <h6 class="text-uppercase text-body-secondary mb-2">Unit
                                        </h6>
                                        <span class="h2 mb-0">{{usageCreditConsumption.toFixed(1)}} / {{usageCreditLimit}}</span>
                                        <div class="row align-items-center g-0">
                                            <div class="col-auto">
                                                <span class="h5 me-2 mb-0">{{usageCreditConsumptionPercentage.toFixed(1)}}%</span>
                                            </div>
                                            <div class="col">
                                                <div class="progress progress-sm me-4">
                                                    <div class="progress-bar" role="progressbar" :style="{width: usageCreditConsumptionPercentage.toFixed(1)+'%'}"
                                                        :aria-valuenow="usageCreditConsumptionPercentage.toFixed(1)" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <span class="h2 fa fa-coins text-body-secondary mb-0"></span>
                                    </div>
                                </div>
                            
                            
                                <hr />
                                <div>
                                    <canvas ref="usageCreditChart" id="usageCreditChart" class="chart-canvas" width="843" height="400" style="
                                            display: block;
                                            box-sizing: border-box;
                                            height: 400px;
                                            width: 843px;
                                        ">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-header-title">Storage Credits</h2>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center gx-0">
                                    <div class="col">
                                        <h6 class="text-uppercase text-body-secondary mb-2">Unit
                                        </h6>
                                        <span class="h2 mb-0">{{storageCreditConsumption.toFixed(1)}} / {{storageCreditLimit}}</span>
                                        <div class="row align-items-center g-0">
                                            <div class="col-auto">
                                                <span class="h5 me-2 mb-0">{{storageCreditConsumptionPercentage.toFixed(1)}}%</span>
                                            </div>
                                            <div class="col">
                                                <div class="progress progress-sm me-4">
                                                    <div class="progress-bar" role="progressbar" :style="{width: storageCreditConsumptionPercentage.toFixed(1)+'%'}"
                                                        :aria-valuenow="storageCreditConsumptionPercentage.toFixed(1)" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <span class="h2 fa fa-coins text-body-secondary mb-0"></span>
                                    </div>
                                </div>
                            
                            
                                <hr />
                                <div>
                                    <canvas ref="storageCreditChart" id="storageCreditChart" class="chart-canvas" width="843" height="400" style="
                                            display: block;
                                            box-sizing: border-box;
                                            height: 400px;
                                            width: 843px;
                                        ">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-4">
                        
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-header-title">Bandwidth Credits</h2>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center gx-0">
                                    <div class="col">
                                        <h6 class="text-uppercase text-body-secondary mb-2">Unit
                                        </h6>
                                        <span class="h2 mb-0">{{bandwidthCreditConsumption.toFixed(1)}} / {{bandwidthCreditLimit}}</span>
                                        <div class="row align-items-center g-0">
                                            <div class="col-auto">
                                                <span class="h5 me-2 mb-0">{{bandwidthCreditConsumptionPercentage.toFixed(1)}}%</span>
                                            </div>
                                            <div class="col">
                                                <div class="progress progress-sm me-4">
                                                    <div class="progress-bar" role="progressbar" :style="{width: bandwidthCreditConsumptionPercentage.toFixed(1)+'%'}"
                                                        :aria-valuenow="bandwidthCreditConsumptionPercentage.toFixed(1)" aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <span class="h2 fa fa-coins text-body-secondary mb-0"></span>
                                    </div>
                                </div>
                            
                            
                                <hr />
                                <div>
                                    <canvas ref="bandwidthCreditChart" id="bandwidthCreditChart" class="chart-canvas" width="843" height="400" style="
                                            display: block;
                                            box-sizing: border-box;
                                            height: 400px;
                                            width: 843px;
                                        ">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


            </template>
            <template v-slot:historical>

                <div class="card mt-4">
                    <div class="card-header">
                        <h2 class="card-header-title">Usage Credits</h2>
                    </div>
                    <div class="card-body">
                        <canvas ref="usageCreditHistoricalChart" id="usageCreditHistoricalChart" class="chart-canvas" width="843" height="400" style="
                                display: block;
                                box-sizing: border-box;
                                height: 400px;
                                width: 843px;
                            ">
                        </canvas>
                    </div>
                </div>

                <div class="card mt-4">
                    <div class="card-header">
                        <h2 class="card-header-title">Storage Credits</h2>
                    </div>
                    <div class="card-body">
                        <canvas ref="storageCreditHistoricalChart" id="storageCreditHistoricalChart" class="chart-canvas" width="843" height="400" style="
                                display: block;
                                box-sizing: border-box;
                                height: 400px;
                                width: 843px;
                            ">
                        </canvas>
                    </div>
                </div>

                <div class="card mt-4">
                    <div class="card-header">
                        <h2 class="card-header-title">Bandwidth Credits</h2>
                    </div>
                    <div class="card-body">
                        <canvas ref="bandwidthCreditHistoricalChart" id="bandwidthCreditHistoricalChart" class="chart-canvas" width="843" height="400" style="
                                display: block;
                                box-sizing: border-box;
                                height: 400px;
                                width: 843px;
                            ">
                        </canvas>
                    </div>
                </div>
            </template>

        </tabs>


    </div>
</template>

<script>
import axios from "axios";
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    Utils
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

import dayjs from "dayjs";
import BasicHeader from "../../components/BasicHeader.vue";
import { isThrowStatement } from "typescript";
import Tabs from "../../components/Tabs.vue";
export default {
    components: { BasicHeader, Tabs },
    data() {
        return {
            loading: false,
            countsData: [],
            entitlements: [],
            entitlementUsage: [],
            usageCreditLimit: 34,
            storageCreditLimit: 42,
            bandwidthCreditLimit: 3,
            containerRequests:  1.1,
            beaconRequests: 1.3,
            mdpRecords: 8.2,
            aioGetRequests: 1.1,
            aioPutRequests: 4.6,
            mdpStorage: 32.5,
            aioStorage: 1.6,
            extraBandwidth: 0.7,
            intraBandwidth: 1.6,
        }
    },
    computed: {        
        startDateLabel() {
            return dayjs(this.startDate).format("MMM. D")
        },
        endDateLabel() {
            return dayjs(this.endDate).format("MMM. D, YYYY") 
        },
        usageCreditConsumption(){
            return this.containerRequests + this.beaconRequests + this.mdpRecords + this.aioGetRequests + this.aioPutRequests
        },
        usageCreditConsumptionPercentage(){
            return this.usageCreditConsumption / this.usageCreditLimit * 100
        },
        storageCreditConsumption(){
            return (this.mdpStorage + this.aioStorage)
        },
        storageCreditConsumptionPercentage(){
            return (this.storageCreditConsumption) / this.storageCreditLimit * 100
        },
        bandwidthCreditConsumption(){
            return (this.extraBandwidth + this.intraBandwidth)
        },
        bandwidthCreditConsumptionPercentage(){
            return (this.bandwidthCreditConsumption) / this.bandwidthCreditLimit * 100
        },
        tabItems() {
            return [
                {
                    name: "Current",
                    id: "id1",
                    slot: "current"
                },
                {
                    name: "Historical",
                    id: "id2",
                    slot: "historical"
                },
                {
                    name: "aUT",
                    id: "id3",
                    slot: "aut",
                    hide:  true
                },
                {
                    name: "aUT Legacy",
                    id: "id1",
                    slot: "aut-unpooled",
                    hide:  true
                },

            ]

        }

    },
    methods: {
        addVariance(number) {
            let variance = (Math.random() * 0.3) - 0.15;
            let newNumber = number + (number * variance);
            return newNumber;
        },
        async initChartData() {
            //this.entilements = await this.getEntitlements()
            await this.getEntitlementUsage()
        },
        async getEntitlements() {
            return axios.get(
                `${this.$store.state.clientMgmtUrl}/v1/clients/${this.$store.state.currentClient.cid}/product_entitlement/dashboard`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
        },
        async getEntitlementUsage() {

            this.loading = true;
            
            var self = this
            setTimeout(()=>{
                self.drawCharts()
            },1000)

            this.loading = false;
            
        },

        drawCharts() {

            

            const horizontalLine = {
                id: 'horizontalLine',
                beforeDraw(chart, args, options) {
                    const ctx = chart.ctx;
                    const yScale = chart.scales['y'];
                    const yValue = options.limit; 
                    
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(chart.chartArea.left, yScale.getPixelForValue(yValue));
                    ctx.lineTo(chart.chartArea.right, yScale.getPixelForValue(yValue));
                    ctx.strokeStyle = options.borderColor;
                    ctx.lineWidth = options.borderWidth;
                    ctx.setLineDash(options.borderDash || []);
                    ctx.lineDashOffset = options.borderDashOffset;
                    ctx.stroke();
                    ctx.restore();
                }
            };

            new Chart(this.$refs['usageCreditChart'], {
                type: 'bar',
                data: {
                    labels: ['Usage'],
                    datasets: [
                        {
                            label: "Container Tag",
                            data: [this.containerRequests]
                        },
                        {
                            label: "Beacon Tag",
                            data: [this.beaconRequests]
                        },
                        {
                            label: "MDP Records",
                            data: [this.mdpRecords]
                        },
                        {
                            label: "aIO GET",
                            data: [this.aioGetRequests]
                        },
                        {
                            label: "aIO PUT",
                            data: [this.aioPutRequests]
                        }
                    ]
                },
                options: {
                    plugins: {
                        title: {
                            display: false,
                            text: 'Usage Credits'
                        },
                        horizontalLine: {
                            borderColor: 'red',
                            borderWidth: 2,
                            borderDash: [5, 5],
                            borderDashOffset: 2,
                            limit: this.usageCreditLimit, // credit limit
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                },
                plugins: [horizontalLine]
            });

            new Chart(this.$refs['storageCreditChart'], {
                type: 'bar',
                data: {
                    labels: ['Storage'],
                    datasets: [
                        {
                            label: "MDP Storage",
                            data: [this.mdpStorage]
                        },
                        {
                            label: "aIO Storage",
                            data: [this.aioStorage]
                        }
                    ]
                },
                options: {
                    plugins: {
                        title: {
                            display: false,
                            text: 'Storage Credits'
                        },
                        horizontalLine: {
                            borderColor: 'red',
                            borderWidth: 2,
                            borderDash: [5, 5],
                            borderDashOffset: 2,
                            limit: this.storageCreditLimit, // credit limit
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                },
                plugins: [horizontalLine]
            });



            new Chart(this.$refs['bandwidthCreditChart'], {
                type: 'bar',
                data: {
                    labels: ['Bandwidth'],
                    datasets: [
                        {
                            label: "Extra-Cloud",
                            data: [this.extraBandwidth]
                        },
                        {
                            label: "Intra-Cloud",
                            data: [this.intraBandwidth]
                        }
                    ]
                },
                options: {
                    plugins: {
                        title: {
                            display: false,
                            text: 'Bandwidth Credits'
                        },
                        horizontalLine: {
                            borderColor: 'red',
                            borderWidth: 2,
                            borderDash: [5, 5],
                            borderDashOffset: 2,
                            limit: this.bandwidthCreditLimit, // credit limit
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                },
                plugins: [horizontalLine]
            });



            //historical charts

            new Chart(this.$refs['usageCreditHistoricalChart'], {
                type: 'bar',
                data: {
                    labels: ['Feb','Mar',"Apr","May","Jun","Jul"],
                    datasets: [
                        {
                            label: "Container Tag",
                            data: [this.addVariance(this.containerRequests*2), this.addVariance(this.containerRequests*2), this.addVariance(this.containerRequests*2), this.addVariance(this.containerRequests*2), this.addVariance(this.containerRequests*2), this.containerRequests]
                        },
                        {
                            label: "Beacon Tag",
                            data: [this.addVariance(this.beaconRequests*2),this.addVariance(this.beaconRequests*2),this.addVariance(this.beaconRequests*2),this.addVariance(this.beaconRequests*2),this.addVariance(this.beaconRequests*2),this.beaconRequests]
                        },
                        {
                            label: "MDP Records",
                            data: [this.addVariance(this.mdpRecords*2),this.addVariance(this.mdpRecords*2),this.addVariance(this.mdpRecords*2),this.addVariance(this.mdpRecords*2),this.addVariance(this.mdpRecords*2),this.mdpRecords]
                        },
                        {
                            label: "aIO GET",
                            data: [this.addVariance(this.aioGetRequests*2),this.addVariance(this.aioGetRequests*2),this.addVariance(this.aioGetRequests*2),this.addVariance(this.aioGetRequests*2),this.addVariance(this.aioGetRequests*2),this.aioGetRequests]
                        },
                        {
                            label: "aIO PUT",
                            data: [this.addVariance(this.aioPutRequests*2),this.addVariance(this.aioPutRequests*2),this.addVariance(this.aioPutRequests*2),this.addVariance(this.aioPutRequests*2),this.addVariance(this.aioPutRequests*2),this.aioPutRequests]
                        }
                    ]
                },
                options: {
                    plugins: {
                        title: {
                            display: false,
                            text: 'Usage Credits'
                        },
                        legend: {
                            position: 'bottom'
                        },
                        horizontalLine: {
                            borderColor: 'red',
                            borderWidth: 2,
                            borderDash: [5, 5],
                            borderDashOffset: 2,
                            limit: this.usageCreditLimit, // credit limit
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                },
                plugins: [horizontalLine]
            });


            new Chart(this.$refs['storageCreditHistoricalChart'], {
                type: 'bar',
                data: {
                    labels: ['Feb','Mar',"Apr","May","Jun","Jul"],
                    datasets: [
                        {
                            label: "MDP Storage",
                            data: [this.addVariance(this.mdpStorage),this.addVariance(this.mdpStorage),this.addVariance(this.mdpStorage),this.addVariance(this.mdpStorage),this.addVariance(this.mdpStorage),this.mdpStorage]
                        },
                        {
                            label: "aIO Storage",
                            data: [this.addVariance(this.aioStorage),this.addVariance(this.aioStorage),this.addVariance(this.aioStorage),this.addVariance(this.aioStorage),this.addVariance(this.aioStorage),this.aioStorage]
                        }
                    ]
                },
                options: {
                    plugins: {
                        title: {
                            display: false,
                            text: 'Storage Credits'
                        },
                        legend: {
                            position: 'bottom'
                        },
                        horizontalLine: {
                            borderColor: 'red',
                            borderWidth: 2,
                            borderDash: [5, 5],
                            borderDashOffset: 2,
                            limit: this.storageCreditLimit, // credit limit
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                },
                plugins: [horizontalLine]
            });



            new Chart(this.$refs['bandwidthCreditHistoricalChart'], {
                type: 'bar',
                data: {
                    labels: ['Feb','Mar',"Apr","May","Jun","Jul"],
                    datasets: [
                        {
                            label: "Extra-Cloud",
                            data: [this.addVariance(this.extraBandwidth),this.addVariance(this.extraBandwidth),this.addVariance(this.extraBandwidth),this.addVariance(this.extraBandwidth),this.addVariance(this.extraBandwidth),this.extraBandwidth]
                        },
                        {
                            label: "Intra-Cloud",
                            data: [this.addVariance(this.intraBandwidth),this.addVariance(this.intraBandwidth),this.addVariance(this.intraBandwidth),this.addVariance(this.intraBandwidth),this.addVariance(this.intraBandwidth),this.intraBandwidth]
                        }
                    ]
                },
                options: {
                    plugins: {
                        title: {
                            display: false,
                            text: 'Bandwidth Credits'
                        },
                        legend: {
                            position: 'bottom'
                        },
                        horizontalLine: {
                            borderColor: 'red',
                            borderWidth: 2,
                            borderDash: [5, 5],
                            borderDashOffset: 2,
                            limit: this.bandwidthCreditLimit, // credit limit
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                },
                plugins: [horizontalLine]
            });

        }

    },
    async mounted() {
        await this.initChartData();
        this.drawCharts()
    }
};
</script>

<style></style>