<template>
    <div v-for="(field, index) in inputVal" :key="field.index" class="sub-input-group" > 
        <div class="form-group row align-items-center">
            <label class="col-sm-2 col-form-label"> Name </label>
            <div class="col-sm-9">
                <input type="text" class="form-control" v-model="field['condition_name']" :disabled="!editable" :readonly="!editable">
            </div>
            <div v-show="editable" class="col-sm-1 align-middle">
                <button class="btn btn-sm btn-rounded-circle btn-white "  @click="deleteEntry(index)">
                    <i class="fa fa-regular fa-trash-can"></i>
                </button> 
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Variable</label>
            <div class="col-sm-9">
                <input type="text" class="form-control" v-model="field['name']" :disabled="!editable" :readonly="!editable">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">Criterion</label>
            <div class="col-sm-9">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`PRESENCE-${index}`" type="radio" v-model="field['criteria']"  :name="`Criteria-${index}`" value="PRESENCE" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >is present</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`ABSENCE-${index}`" type="radio" v-model="field['criteria']"  :name="`Criteria-${index}`" value="ABSENCE" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >is absent</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`ONE_OF-${index}`" type="radio" v-model="field['criteria']"  :name="`Criteria-${index}`"  value="ONE_OF" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >is one of</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`NOT_ANY_OF-${index}`" type="radio" v-model="field['criteria']"  :name="`Criteria-${index}`"  value="NOT_ANY_OF" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >is not one of</label>
                </div>
            </div>
        </div> 
        <div class="form-group row" v-if="field['criteria'] =='ONE_OF' || field['criteria'] =='NOT_ANY_OF' ">
            <label class="col-sm-2 col-form-label">Values</label>
            <div class="col-sm-9">
                <input type="text" class="form-control" :value="field['values']" @input="inputHandler(index, $event)" :disabled="!editable" :readonly="!editable">
                <!-- <input type="text" class="form-control" v-model="field['values']" :disabled="!editable" :readonly="!editable"> -->
            </div>
        </div>        
    </div>    
    <button v-show="editable" @click="addSubCondition" class="btn btn-primary mb-2">Add Sub Condition</button>  
</template>

<script>
export default {     
    computed: {
        inputVal() {
            return this.modelValue;
        }
    },    
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        editable: {
            type: Boolean,
            default: true
        },
        visible: {
            type: Boolean,
            default: true
        }        
    },
    methods: {
        addSubCondition() {           
            this.inputVal.push({});
        },
        inputHandler(index,e) {           
           this.inputVal[index].values = e.target.value.split(',');
        },
        deleteEntry(index) {
            this.inputVal.splice(index, 1);            
        }
    },
    emits:[
        "update:modelValue",
        'change'
    ]

}
</script>

<style>

</style>