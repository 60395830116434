<template>
        <ul class="list-group list-group-lg list-group-flush list my-n4">
            <li v-for="log in logList" :key="log.index" class="list-group-item">
                <h2 class="d-inline-block"><span class="badge bg-primary-soft mt-n1">{{ log.version }}</span></h2>
                <span class="text-muted">&nbsp; - {{ log.date }}</span>
                <ul v-for="item in log.items" :key="item.index">
                    <li>
                        {{ item.description }}
                        <router-link v-if="item.route" :to="item.route">
                            <span  class="loglink fa fa-arrow-up-right-from-square" ></span>
                        </router-link>
                        <span v-for="tag in item.tags" :key="tag.index" class="badge rounded-pill badge-success" :style= "[item.category== 'Bugfix' ? {'background-color': 'tomato'} : {'background-color': '#4eaf94'}]">
                            {{tag}}
                        </span>
                    </li>
                </ul>
            </li>
        </ul>
</template>
<script>
export default {
    name: "ChangeLogList",
    props:{
        logList: {
            type:Array,
            default: function () {
              return []
          }
        }
    }
}
</script>
<style>
.badge {
    background-color: #4eaf94;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
    margin: 2px
}
.loglink{
    color:#2582c6
}
</style>