<template>
  <div v-for="(arr, index) in inputVal" :key="index" class="sub-input-group">
    <div class="form-group row row align-items-center">
      <label class="col-sm-2 col-form-label"> Continent Name </label>
      <div class="col-sm-9">
        <select
          class="form-select"
          :value="inputVal[index][0]"
          @input="update(index, 0, $event.target.value)"
          :disabled="!editable"
          :readonly="!editable"
        >
          <option value="AS">Asia</option>
          <option value="NA">North America</option>
          <option value="EU">Europe</option>
          <option value="AF">Africa</option>
          <option value="OC">Oceania</option>
          <option value="SA">South America</option>
          <option value="OT">Others</option>
        </select>
      </div>
      <div v-show="editable" class="col-sm-1">
        <button class="btn btn-sm btn-rounded-circle btn-white" @click="deleteEntry(index)">
          <i class="fa fa-regular fa-trash-can"></i>
        </button> 
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Throttle Percentage</label>
      <div class="col-sm-9">
        <input
          type="number"
          class="form-control"
          :value="inputVal[index][1]"
          @input="update(index, 1, Number($event.target.value))"
          :disabled="!editable"
          :readonly="!editable"
        />
      </div>
    </div>     
  </div>
  <button v-show="editable" @click="addContinent" class="btn btn-primary mb-2">
    Add Continent
  </button>
</template>

<script>
export default { 
  computed: {
    inputVal() {
      return this.modelValue ? Object.entries(this.modelValue) : [];
    }
  },  
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    addContinent() {
      this.inputVal.push([]);
      this.emitUpdatedModalVal(this.inputVal);
    },

    update(index, subIndex,val) {
      this.inputVal[index][subIndex] = val;
      this.emitUpdatedModalVal(this.inputVal);     
    },

    deleteEntry(index) {
      this.inputVal.splice(index, 1);
      this.emitUpdatedModalVal( this.inputVal);
    },

    emitUpdatedModalVal(inputVal) {
      const inputValObj = inputVal.reduce(
        (acc, [k, v]) => ((acc[k] = v), acc),
        {}
      );
      this.$emit("update:modelValue", inputValObj);
    }
    
  },
  emits: ["update:modelValue", "change"],
};
</script>

<style></style>
