<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4  v-if="this.modalData" class="card-header-title">
                {{this.modalData.title}}
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body component">
            <component :is="customComp" :compData="modalData" />   
        </div>
    </div>
</template>

<script>
//Dynamic Components
import OutputParser from '../OutputParser.vue';
export default {
    components: { OutputParser },
    name: "CustomComponent",
    props: { 
        title:{
            type: String,
            default:""
        },      
        modalData: {
            type: [Object, Array, String],
        }
    },
    computed:{
        customComp(){
            return this.modalData && this.modalData.component ? this.modalData.component: ""
        }
    }
};
</script>

<style scoped>
.component{
    margin-top: -31px;
}
</style>