<template>
  <div>
      <div v-if="error" class="text-danger">
            {{ error }}
      </div>
      <div
        :class="[editable?'file-container':'disable']"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          :multiple="multipleFile"
          type="file"
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
        />
  
        <label for="fileInput" class="file-label">
          <div v-if="isDragging">Release to drop file here.</div>
          <div v-else>Drop file here or <u>click here</u> to upload.</div>
        </label>
  
        <div class="preview-container mt-4" v-if="files.length">
          <div v-for="file in files" :key="file.name" class="preview-card">
            <div>
              <p class="filename" :title="file.name">
                {{ file.name }}
              </p>            
            
            </div>
            <div>
                <button
                type="button"
                class="btn-close"
                @click="remove(files.indexOf(file))"
                aria-label="Remove file"
            ></button>
            </div>
          </div>
        </div>
        <div v-else-if="image" class="displayImg">
          <div>
                <button
                type="button"
                class="btn-close removeBtn"
                @click="removeImage"
                aria-label="Remove file"
            ></button>
            </div>
          <img :src="image"  style="max-height: 100px; max-width: 100px;"> 
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name:"FileUploader",
    props: {
        multipleFile: {
            type: Boolean,
            default: false
        },
        onFileChange: {
            type: Function,
            default: () => {}
        },
        editable:{
          type: Boolean,
          default: true
        },
        value:{
          type: [String,Object],
          default: null
        },
        maxSize:{
          type: Number,
          default: 0
        },
        format:{
          type: String,
          default:''
        }
    },
    data() {
      return {
        isDragging: false,
        files: [],
        error: null,
        removeImg:false,
        image:this.value
      };
    },
    watch:{
        'files':{
            handler: function(newVal) {
              if(newVal.length){
                if(this.maxSize && !this.multipleFile){
                    let size= newVal[0].size/1000; // converting size from byte to kb
                    if(size>this.maxSize){
                      this.reset();
                      this.error ="Image size should be less than "+this.maxSize+" kb!";
                      return;
                    }
                }
                if(!newVal[0].type.includes(this.format)){
                  this.reset();
                  this.error ="Upload only png images as logo!"
                  return;
                }
                this.onFileChange(newVal)
              }
            },
            deep: true
        },
        "value": {
          handler: function(newVal) {
            if( typeof newVal === 'string'){
              this.files=[]
              this.image =newVal
            }
          }
        }
    },
    methods: {
      onChange() {
        this.error=''
        this.files = [...this.$refs.file.files];
      },

      remove(i) {
         this.files.splice(i, 1);
         this.image =null
         this.onFileChange([])
      },
  
      dragover(e) {
        e.preventDefault();
        this.isDragging = true;
      },
  
      dragleave() {
        this.isDragging = false;
      },
  
      drop(e) {
        e.preventDefault();
        this.$refs.file.files = e.dataTransfer.files;
        this.onChange();
        this.isDragging = false;
      },
      reset(){
        this.onFileChange([])
        this.files=[]
      },
      removeImage(){
        this.files =[]
        this.image =null
        this.onFileChange([])
      }
    },
  };
  </script>
  
  <style>
  .file-container {
    padding: 4rem;
    background: #f7fafc;
    border: 1px solid #e2e8f0;
  }
  .hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }
  .file-label {
    font-size: 20px;
    display: block;
    cursor: pointer;
  }
  .preview-container {
    display: flex;
    margin-top: 2rem;
  }
  .preview-card {
    display: flex;
    padding: 5px;
    margin-left: 5px;
  }
  .filename{
    color:#2C7BE5
  }
  .disable{
    pointer-events: none;
    opacity: 0.7;
  }
  .displayImg{
    text-align: center;
  }
  .removeBtn{
    margin-left:30%;
    float: none!important;
  }
  </style> 