<template>
    <div class="row" v-show="jobTemplateGroup && jobTemplateGroup.created_at">
    <div v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
    </div>
    <div v-if="jobTemplateGroup">
        <div class="row">
            <div class="card">
                    <div class="card-body">
                        <div class="row">
                                <div class="form-group col-12 inline-field row">
                                    <label class="col-sm-4 col-form-label"><h5>Name</h5></label>
                                    <InputField
                                        v-model="jobTemplateGroup.name"
                                        :editable="false"
                                        :showLabel="false"
                                        type="text"
                                        colField="col-8"
                                    />
                                </div>
                                <div class="form-group col-12 inline-field row">
                                    <label class="col-sm-4 col-form-label"><h5>Description</h5></label>
                                    <InputField
                                        v-model="jobTemplateGroup.description"
                                        :editable="editModeGroup"
                                        :showLabel="false"
                                        type="text"
                                        colField="col-8"
                                    />
                                </div>
                                <div class="form-group col-12 inline-field row">
                                    <label class="col-sm-4 col-form-label"><h5>Tags</h5></label>
                                    <InputField
                                        v-model="tag"
                                        :options="jobTemplateGroup.tag"
                                        :editable="editModeGroup"
                                        :showLabel="false"
                                        type="multiselect"
                                        colField="col-8"
                                    />
                                </div>
                                <div class="form-group col-12 inline-field row">
                                    <InputField
                                        v-model="jobTemplateGroup.is_pipeline"
                                        :editable="editModeGroup"
                                        label="Is Pipeline"
                                        type="checkbox"
                                    />
                                </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            Job Templates for {{ jobTemplateGroup.name }}
                        </h4>
                        <button v-if="!editDisable" class="btn btn-primary float-right"
                            @click="showModal({ component: 'addJobTemplateMap', data: { jobTemplateGroup: jobTemplateGroup, list: jobTemplateMapList, onSaveChange: onSaveChange } })"><i class="fa fa-plus"></i></button>&nbsp;
                        <button v-if="!editDisable" class="btn btn-primary float-right" :disabled="editDisable"
                            @click="setEditDisable(true)"><i class="fa fa-edit"></i></button>
                        <button v-if="editDisable" class="btn btn-success float-right" 
                            @click="onSaveMappings"><i class="fa fa-save"></i></button>&nbsp;
                        <button v-if="editDisable" class="btn btn-danger float-right" 
                            @click="onCancelMap"><i class="fa fa-close"></i></button>
                    </div>
                    <div class="card-body">
                        <Datatable 
                            :tableId="`${jobTemplateGroup.name}TemplateMapTable`" 
                            :columns="columns" 
                            uniqueField="job_template_id"
                            :data="jobTemplateMapList" 
                            :deletable="true" @deleteRows="showDelete" 
                            :compact="true"
                            :selectCheckboxes="true" 
                            :rowReorder="true"
                            :editable="editDisable"
                            :orderColumn="orderColumn"
                            @onMoveUp="(data)=> onMoveUp(data)"
                            @onMoveDown="(data)=> onMoveDown(data)"
                            :detailColumn="{ baseUrl: 'job-templates/', name: 'Job Template', identifier: 'job_template_id' }" />
                    </div>

                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>
<script>
import Datatable from '../../components/Datatable.vue';
import InputField from '../../components/InputField.vue';
import { mapActions } from 'vuex';
export default {
    name: "JobTemplateGroup",
    components: { Datatable, InputField},
    data() {
        return {
            tag:null,
            jobTemplateGroup:{},
            jobTemplateGroupMapList:[],
            jobTemplateMapList:[],
            snapshot: null,
            snapshotMap:[],
            error: null,
            columns: [
                { data: "job_template_id", title:"id", orderable: false },
                { data: "job_template_name", title:"name", orderable: false },
                { data: "type", orderable: false },
                { data: "execution_order", title: "Execution Order", orderable: false},
                { data: "changed_order", title: "Changed Order", visible:false},
            ],
            orderColumn: [6, "asc"],
            editDisable:false,
        }
    },
    props: ["editModeGroup", "saveJobGroup", "cancelClickGroup"],
    beforeMount() {
        this.fetchJobTemplateGroup();
        this.fetchJobTemplateGroupMapList(this.$route.params.id)
        this.$emit("hideEditGroup", false);
    },
    watch:{
        jobTemplateGroupMapList:{ 
            handler: function (newVal) {   
                this.jobTemplateMapList = newVal;
                
            },
            deep: true,
            immediate: true
        },
        '$store.state.admin.jobTemplateGroupMapList':{ 
            handler: function (newVal) {   
                this.jobTemplateMapList = newVal;
            },
            deep: true,
            immediate: true
        },
        editModeGroup(val) {
            if (val) {
                this.error = "";
                this.response = "";
            }
        },
        saveJobGroup() {
            this.onSaveJobGroup();
        },
        cancelClick() {
            this.fetchJobTemplateGroup();
        },
        cancelClickGroup() {
            this.fetchJobTemplateGroup();
        },
                
    },
    methods: {
        ...mapActions({
            readJobTemplateGroup: "admin/readJobTemplateGroup",
            updateJobTemplateGroup: "admin/updateJobTemplateGroup",
            deleteJobTemplateGroup: "admin/deleteJobTemplateGroup",
            showModal: "showModal",
            getJobTemplateGroupMappings: "admin/getJobTemplateGroupMappings"
        }),
        setEditDisable(mode){
            this.editDisable = mode;
        },
        async fetchJobTemplateGroup() {
            let response = {};
            if (!this.$route.params.data) {
                try {
                    response = await this.readJobTemplateGroup(this.$route.params.id);
                } catch (error) {
                    if (error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                }
            } else {
                response["data"] = JSON.parse(this.$route.params.data)
            }
            this.jobTemplateGroup = await response.data;
            this.jobTemplateGroup.tag =this.jobTemplateGroup.tag ? this.jobTemplateGroup.tag.split(",") : [];
            this.tag = this.jobTemplateGroup.tag;
            this.snapshot = JSON.parse(JSON.stringify(this.jobTemplateGroup));
            this.$emit("setJobTemplateGroup", this.jobTemplateGroup);
        },
        async fetchJobTemplateGroupMapList(jobTemplateGroupId) {
            let response;
            try {
                response = await this.getJobTemplateGroupMappings(jobTemplateGroupId);
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
            }
            this.jobTemplateGroupMapList = await response.data;
            this.onSaveChange(this.jobTemplateGroupMapList);
        },
        async onSaveJobGroup() {
            let self = this;
            let toastoptions;
            this.error =null;
            let jobTemplateGroupObj = JSON.parse(JSON.stringify(this.jobTemplateGroup));
            jobTemplateGroupObj.tag = this.tag?this.tag.toString():""
            try {
                const response = await this.updateJobTemplateGroup(jobTemplateGroupObj);
                this.snapshot = JSON.parse(JSON.stringify(jobTemplateGroupObj));
                this.$emit("setJobTemplateGroup", this.jobTemplateGroup);
                toastoptions = {
                    message: "Job Template group updated successfully.",
                    type: "success",
                };
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
                toastoptions = {
                message:
                    this.error ||
                    "Something went wrong when updating Job Template. Please contact support.",
                type: "error",
                };
            } finally {
                this.loading = false;
                this.$emit("triggerEditModeGroup", false);
                this.$store.dispatch("addToast", toastoptions);
            }
        },
        onMoveUp(data){
            let index =  this.jobTemplateGroupMapList.findIndex(e => e.job_template_id == data.job_template_id);
            if (index > 0) {
                let el =  this.jobTemplateGroupMapList[index];
                this.jobTemplateGroupMapList[index] =  this.jobTemplateGroupMapList[index - 1];
                this.jobTemplateGroupMapList[index - 1] = el;
                this.jobTemplateGroupMapList= this.jobTemplateGroupMapList.map((item,i)=>{item["changed_order"]=i+1;return item})
            }else if(index == 0){
                let el =  this.jobTemplateGroupMapList[0];
                this.jobTemplateGroupMapList.splice(0, 1);
                this.jobTemplateGroupMapList.push(el);
                this.jobTemplateGroupMapList= this.jobTemplateGroupMapList.map((item,i)=>{item["changed_order"]=i+1;return item})
            }
        },
        onMoveDown(data){
            let index = this.jobTemplateGroupMapList.findIndex(e => e.job_template_id == data.job_template_id);
            if (index !== -1 && index <  this.jobTemplateGroupMapList.length - 1) {
                let el =  this.jobTemplateGroupMapList[index];
                this.jobTemplateGroupMapList[index] = this.jobTemplateGroupMapList[index + 1];
                this.jobTemplateGroupMapList[index + 1] = el;
                this.jobTemplateGroupMapList= this.jobTemplateGroupMapList.map((item,i)=>{item["changed_order"]=i+1;return item})
            }else if(index ==  this.jobTemplateGroupMapList.length - 1){
                let el =  this.jobTemplateGroupMapList[ this.jobTemplateGroupMapList.length - 1];
                this.jobTemplateGroupMapList.pop();
                this.jobTemplateGroupMapList.unshift(el)
                this.jobTemplateGroupMapList= this.jobTemplateGroupMapList.map((item,i)=>{item["changed_order"]=i+1;return item})
           
            }
        },
        showDelete(rows) {
            this.showModal({ component: "DeleteJobTemplateGroupMappings", data: { jobTemplateGroup: this.jobTemplateGroup, rows: rows, onSaveChange:this.onSaveChange } });
        },
        onCancelMap(){
            this.jobTemplateGroupMapList=JSON.parse(JSON.stringify(this.snapshotMap));
            this.setEditDisable(false)
        },
        onSaveMappings(data){
            let changedData = this.jobTemplateGroupMapList.filter((item)=>item.changed_order!=item.execution_order)
            if(changedData.length>0)
                this.showModal({ component: 'SaveJobTemplateMap', data: { rows: changedData, onSaveChange:this.onSaveChange }})
            else{
                let toastoptions = {
                    message: "No changes to save!",
                    type: "warning",
                };
                this.$store.dispatch("addToast", toastoptions);
                this.onCancelMap()
            }
        },
        onSaveChange(response){
            this.setEditDisable(false);
            this.jobTemplateGroupMapList= this.reArrangeList(response)
            this.jobTemplateMapList= this.jobTemplateGroupMapList
            this.snapshotMap = JSON.parse(JSON.stringify(this.jobTemplateGroupMapList));
        },
        reArrangeList(list){
            if(list){
                list= list.map(i => {i["changed_order"]=i["execution_order"]; return i})
                list.sort((a, b) => a["changed_order"]- b["changed_order"]);
            }  
            return list
        }
    },
    emits: [
        "setJobTemplateGroup","hideEditGroup","triggerEditModeGroup"
    ],
}
</script>
<style></style>