<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddEntitlement()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add Entitlement
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="fields" v-model="entitlement" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="fields" v-model="entitlement" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex';
import Fields from "../../utils/field-constants/entitlementFields";
import { isDuplicate } from '../../utils/commonFunction';
export default {
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            entitlement: {base_entitlement_id:0},
            fields: []
        }
    },
    name: "AddEntitlement",
    mounted(){
        this.fields= JSON.parse(JSON.stringify(Fields));
        //setting base entitlement options as entitlement name and id
        this.fields.forEach((field)=>{
            if(field.name=="base_entitlement_id"){
                field.options=this.entitlementList.map((item)=>{
                    return { label: item.name, value: item.entitlement_id }
                })
            }
        })
        //hiding
        this.fields.map((field)=>{
            if(field.name ==="created_by" || field.name ==="created_at" ||
                field.name ==="updated_by" || field.name ==="updated_at"){
                    field.visible= false
                }
        })
    },
    computed:{
        entitlementList(){
            return this.$store.state.admin.entitlements
        }
    },
    methods: {
        ...mapActions('admin', {
            createEntitlement: 'createEntitlement'
        }),
        async onAddEntitlement() {
            let valid = true;
            let toastoptions;
            try {
                valid = !isDuplicate(this.entitlement.name,this.entitlementList, "name", null)
                if( valid ){
                    await this.createEntitlement(this.entitlement);
                    toastoptions = {
                        message: "Entitlement '"+this.entitlement.name+"' added successfully.",
                        type: "success",
                    };
                }else{
                    toastoptions = {
                            message: "Already '"+this.entitlement.name+"' name exits.'",
                            type: "error",
                        };
                }
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }
            this.$store.dispatch('addToast', toastoptions);
            if(valid){
                this.$store.dispatch('hideModal');
                this.$store.dispatch('admin/fetchEntitlements');
            }
        }
    }
};
</script>

<style>
</style>