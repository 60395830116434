<template>    
    <div class="card"> 
        <div class="card-header">
            <h4 className="card-header-title">Pipeline Jobs</h4>            
        </div>  
        <datatable
            :tableId="`${pipelineName}-jobList`"
            :columns="columns"
            :formatColumn="formatColumn"
            :data="jobs"
            :compact="true"
            :orderColumn="orderColumn"
            :detailColumn="{renderBaseUrl: (data) => {
                                                    return `/${$route.params.cid}/amdp/${$route.params.sandboxId}/jobs/${data.name}/configuration`;                                                   
                                                },}"          
        />
    </div>
    <div class="card" v-if="childPipelines.length"> 
        <div class="card-header">
            <h4  className="card-header-title">Child Pipelines</h4>            
        </div>  
       <div>
        <ul class="list-group">
            <li class="list-group-item" v-for="(queryParam, index ) in childPipelines" :key="index">{{ queryParam }}</li>
        </ul>
       </div>
    </div>
</template>

<script>
import Datatable from "../../../components/Datatable.vue";
import { secondsToClock } from '../../../utils/commonFunction';
import { mapActions } from "vuex";
export default {
    components: { Datatable },
    data() {
        return {
            pipelineName: this.$route.params.pipelineName,
            pipelineDetails: null,
            childPipelines: [],
            jobs: [],
            columns: [               
                { data: "name"},
                {
                    data: "type",
                    title: "Job Type",
                    render: function (data, type, row) {
                        let jobType = data;
                        switch (jobType) {
                            case '1': jobType = 'Collation'; break;
                            case '2': jobType = 'Ingestion'; break;
                            case '3': jobType = 'Analysis'; break;
                            case '4': jobType = 'Other'; break;
                            case '5': jobType = 'Sandbox'; break;
                            case '6': jobType = 'Distribution'; break;
                            case 'Undefined': jobType = 'Other'; break;
                        }
                        //To have this render value in dropdown filter setting valur for type
                        row.type=jobType
                        return jobType
                    },
                },
                {
                    data: "last_run_started_at", 
                    title: "Last Run At"
                }, 
                {
                    data: "last_run_time", 
                    title: "Last Run Time",
                    render: function (data, type, row) {
                        return secondsToClock(data)
                    },
                }, 
                {
                    data: "last_run_status", 
                    title: "Last Run Status"
                },
                {
                    data: "created_at", 
                    title: "Created"
                }
            ],
            orderColumn: [3, "desc"],
            formatColumn:[
                {
                    index:3,
                    type:"datetime"
                },               
                {
                    index:6,
                    type:"datetime"
                }                 
            ],
        }
    },
    props: ["selectedSandbox"],
    async beforeMount() {
        this.pipelineDetails = await this.fetchPipelines({sandboxName: this.selectedSandbox, pipelineName: this.pipelineName});
        let jobs =   this.pipelineDetails?this.pipelineDetails.jobs:[];
        if(jobs) {
            jobs.map((job)=>{
                let status = job.last_run_status?job.last_run_status.toLowerCase():'';
                let statusStr = job.last_run_status;
                if(status==="succeeded" || status==="completed"){
                    statusStr = "<span class='text-success'>●</span> "+job.last_run_status;
                } else if(status==="failed"){
                    statusStr = "<span class='text-danger'>●</span> "+job.last_run_status;
                } else if(status==="running"){
                    statusStr = "<span class='text-primary'>●</span> "+job.last_run_status;
                } else if(status==="submitted"){
                    statusStr = "<span class='text-warning'>●</span> "+job.last_run_status;
                } else if(status==="cancelled"){
                    statusStr = "<span class='text-secondary'>●</span> "+job.last_run_status;
                }
                job["last_run_status"] = statusStr;
                return job;
            })
        }            
        this.jobs = jobs;
        this.childPipelines = (this.pipelineDetails && this.pipelineDetails.child_pipelines)?this.pipelineDetails.child_pipelines:[];
    },
    methods: {
      ...mapActions({            
            fetchPipelines: "sandbox/fetchPipeline",         
        }),
    }    
}
</script>

<style>

</style>