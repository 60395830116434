const Fields = [
    {
        name: "owner",
        label: "Owner",
        type: ["const", "input", "list"],
        editable: true,
    },
    {
        name: "partner",
        label: "Partner",
        type: ["const", "input", "list"],
        editable: true,
    },
    {
        name: "property",
        label: "Property",
        type: ["const", "input", "list"],
        editable: true,
    },
    {
        name: "id_type",
        label: "ID Type",
        type: ["const", "input", "list"],
        editable: true,
    },
    {
        name: "id_format",
        label: "ID Format",
        type: ["const", "input", "list"],
        editable: true,
    }
];
export default Fields;