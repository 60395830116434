const Fields = [
    {
        key: true,
        name: "name",
        label: "Name",
        type: "text",
        editable: true,           
        validation: [
            { type: "required"},
            {type: "regex", pattern:"^[a-z0-9-]*$",
            errMsg:"Job group name only supports lowercase letters, numbers and dashes."}
        ],
        helpText: `<p><a href="https://help.aqfer.net/lakeview-api/job-naming-convention?from_search=129606375" target="_blank">Click here to view Naming Convention rules</a></p>`
    },
    {
        name: "description",
        label: "Description",
        visible: true,
        editable: true,
        type: "text",
        validation: [{ type: "required"}]
    },
    {
        name: "tag",
        label: "Tags",
        visible: true,
        editable: true,
        type: "multiselect",
        createOption:true,
    },
    {
        name: "is_pipeline",
        label: "Is Pipeline",
        visible: true,
        editable: true,
        type: "checkbox"
    }
];

export default Fields;