<template>
    <DetailHeader
        pretitle="Encrypted URLs"
        :editable="true"
        :editMode="editMode"
        @editClick="onEditClick"
        @deleteClick="onDeleteClick"
        @saveClick="onSaveClick"
        @cancelClick="onCancelClick"
        @closeClick="$router.push('/admin/encrypted-urls')"
    />

    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div v-if="error" class="alert alert-danger" role="alert">
                    {{ error }}
                </div>
                <div v-if="encryptedUrl" class="card mb-5">
                    <div class="card-body">
                        <FormFields
                            :fields="fields"
                            :inlineField="true"
                            v-model="encryptedUrl"
                            :editMode="editMode"
                        />
                    </div>
                </div>
                <div v-else class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DetailHeader from "../../components/DetailHeader.vue";
import Fields from "../../utils/field-constants/encryptedUrlFields";
import { mapActions } from "vuex";
import FormFields from "../../components/FormFields.vue";
export default {
    name: "EncryptedURL",
    components: { DetailHeader, FormFields },
    data() {
        return {
            editMode: false,
            encryptedUrl: null,
            snapshot: null,
            error: "",
            fields: [],
        };
    },
    beforeMount() {
        this.fetchEncryptedUrl();
        this.fields = JSON.parse(JSON.stringify(Fields))
        this.fields.map((field)=>{
            //all fields created_at, createdby ,updated_at, updated_by should be visible
            field.visible =true
            
        });
    },
    methods: {
        ...mapActions({
            readEncryptedUrl: "admin/readEncryptedUrl",
            updateEncryptedUrl: "admin/updateEncryptedUrl",
            deleteEncryptedUrl: "admin/deleteEncryptedUrl",
            showModal: "showModal",
        }),
        setEditMode(mode) {
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        async fetchEncryptedUrl() {
            let response= {}
            if(!this.$route.params.data){
                try {
                    response = await this.readEncryptedUrl(this.$route.params.id);
                } catch (error) {
                    if (error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
            }else{
                response["data"] = JSON.parse(this.$route.params.data)
            }
            this.encryptedUrl = await response.data;
            this.snapshot = { ...this.encryptedUrl };
        },
        onCancelClick() {
            this.setEditMode(false);
            this.encryptedUrl = { ...this.snapshot };
            this.error = null;
        },
        onEditClick() {
            this.setEditMode(true);
        },
        onDeleteClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete EncryptedUrl",
                    message: `Are you sure you want to delete ${this.encryptedUrl.url}?`,
                    async confirm() {
                        try {
                            const response = await self.deleteEncryptedUrl(
                                self.encryptedUrl.id
                            );
                            self.$router.push("/admin/encrypted-urls");
                            return response;
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            return;
                        }
                    },
                },
            });
        },
        async onSaveClick() {
           let toastoptions;
            try {
                const response = await this.updateEncryptedUrl(this.encryptedUrl);
                this.setEditMode(false);
                this.snapshot = { ...this.encryptedUrl };
                toastoptions = {
                        message: "Encrypted Url updated successfully.",
                        type: "success"
                }
                this.$store.dispatch('addToast', toastoptions);
                this.$router.push("/admin/encrypted-urls");
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
            }
        },
    },
};
</script>

<style>
</style>