<template>
    <basic-header
        pretitle="Adminstration"
        title="Entitlements"
        buttonText="Add Entitlement"
        @btnClick="showModal({ component: 'addEntitlement'})"
    />
    <div class="container-fluid">
        <div class="card">
            <Datatable
                tableId="entitlementTable"
                :columns="columns"
                uniqueField ="entitlement_id"
                :data="$store.state.admin.entitlements"
                @deleteRows="showDeleteEntitlements"
                :deletable="true"
                :compact="true"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:'entitlements/', name: 'Entitlement', identifier:'entitlement_id'}"
            />
        </div>
    </div>
</template>
<script>
import Datatable from '../../components/Datatable.vue';
import BasicHeader from '../../components/BasicHeader.vue';
import { mapActions } from 'vuex';
export default{
    name: "Entitlements",
    components:{ BasicHeader, Datatable},
    data(){
        return {
            columns:[
                { data: "name"},
                { data: "unit"},
                { data: "quantity"}
            ]
        };
    },
    beforeMount(){
        this.$store.dispatch("admin/fetchEntitlements")
    },
    methods: {
        ...mapActions({
            showModal:"showModal"
        }),
        showDeleteEntitlements(rows){
            this.showModal({ component: "deleteEntitlements", data:rows });
        }
    }
}

</script>
<style></style>