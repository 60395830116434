<template>
	<div class="form-group inline-field row align-items-center sub-input-group">
		<label class="col-sm-3 col-form-label"> Config Name <span  :class="['fa fa-circle-info text-muted']" title="Name of privacy configuration. choose none otherwise" data-bs-toggle="tooltip"  data-bs-placement="right"></span></label>
		<div class="col-sm-8">
			<select
				class="form-select"
				v-model="inputVal['configName']"
				:disabled="!editable"
				:readonly="!editable"
			>
				<option :value=null>NONE</option>
				<option
					v-for="option in privacyConfigOptions"
					:key="option.index"
					:value="option.value"
				>
					{{ option.label }}
				</option>
			</select>
		</div>
	</div>
	<div v-for="(privacyStatus,index) in UsPrivacyStatusList" :key="index" class="form-group row inline-field align-items-center sub-input-group">
		<label class="col-md-3 col-form-label">{{ privacyStatus.key.toUpperCase()}} <i class="fa fa-circle-info text-muted" :title="privacyStatus.tooltip" data-bs-toggle="tooltip" data-bs-placement="right"></i></label>
		<div class="col-md-8">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`ENABLED-${privacyStatus.key}`" type="radio" v-model="inputVal[privacyStatus.key]"  :name="`${privacyStatus.key}-ENABLED`" value="ENABLED" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >ENABLED</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`BLOCKED-${privacyStatus.key}`" type="radio" v-model="inputVal[privacyStatus.key]"  :name="`${privacyStatus.key}-BLOCKED`" value="BLOCKED" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >BLOCKED</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" :id="`DISABLED-${privacyStatus.key}`" type="radio" v-model="inputVal[privacyStatus.key]"  :name="`${privacyStatus.key}-DISABLED`" value="DISABLED" :disabled="!editable" :readonly="!editable">
                    <label class="form-check-label" >DISABLED</label>
                </div>  
		</div>
	</div>	
</template>

<script>
	import { mapState } from "vuex";
	export default {
		data(){
			return {
				UsPrivacyStatusList:[{key:"usca",tooltip:"USCA is applicale when gpp_sid=8"},{key:"usco",tooltip:"USCO is applicale when gpp_sid=10"},{key:"usct", tooltip:"USCT is applicale when gpp_sid=12"},{key:"usnat",tooltip:"USNAT is applicale when gpp_sid=7"},{key:"usut", tooltip:"USUT is applicale when gpp_sid=11"},{key:"usva", tooltip:"USVA is applicale when gpp_sid=9"}]
			}

		},
		computed: {
			inputVal() {
				return this.modelValue ? this.modelValue : {};
			},
			...mapState({
				privacyConfig: (state) => state.aut.privacyConfig,
			}),
			privacyConfigOptions() {
				if (this.privacyConfig?.length) {
					return this.privacyConfig.map((config) => {
						return { label: config.name, value: config.name };
					});
				}
				return [];
			},
		},
		props: {
			modelValue: {
				type: Object,
				default: () => {},
			},
			editable: {
				type: Boolean,
				default: true,
			},
			visible: {
				type: Boolean,
				default: true,
			},
		},

		emits: ["update:modelValue", "change"]		
	};
</script>

<style></style>
