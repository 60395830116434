const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Name Field is required" }
        ]
    },
    {
        name: "email",
        label: "Email",
        type: "text",
        editable: true,
        validation: [
            { type: "email"}, 
            { type: "required" }
        ]
    },{
        name: "cids",
        label: "CIDs",
        type: "multiselect",
        editable: true,
        readOnlyIfRoleOver: 1,
    },
    {
        name: "roles",
        label: "Roles",
        type: "multiselect",
        editable: true,
        readOnlyIfRoleOver: 1,
        validation: [
            { type: "required" }
        ],
        options:["access_token_validator","aqfer_admin","aqfer_user","client_admin","client_user"]
        

    }
];

export default Fields;

