<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddProductEntitlement()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Add Product Entitlement
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">

                <InputField label="Product" type="text" v-model="product.sku" :editable="false" />

                <InputField label="Entitlement Name" type="select" v-model="entitlement" :editable="true"
                    :options="entitlementOptions" :validate="[{ type: 'required', errMsg: 'select entitlement' }]" />

                <InputField label="Quantity" type="number" v-model="quantity" :editable="true"
                    :validate="[{ type: 'required', errMsg: 'quantity is required' }]" />

                <InputField label="Effective Date" type="calendar" v-model="effectiveDate" :editable="true" :inputConfig="config"
                    :validate="[{ type: 'required', errMsg: 'date is required' }]" />
            </div>
            <div class="card-footer">
                <div class="text-end">
                    <button class="btn btn-primary" :disabled="btnDisable" type="submit">Add</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import InputField from "../InputField.vue";
import { mapActions } from 'vuex';
import dayjs from "dayjs";
import { isDuplicate } from '../../utils/commonFunction';
export default {
    components: { InputField },
    name: "AddProductEntitlement",
    data() {
        return {
            effectiveDate: dayjs().format("YYYY-MM-DD"),
            quantity: 1,
            entitlement: null,
            entitlementOptions: [],
            config: {
                wrap: true, 
                dateFormat: 'Y-m-d',
            },
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    computed: {
        product() {
            return this.modalData.product ? this.modalData.product : { name: '' }
        },
        entitlementList() {
            return this.$store.state.admin.entitlements
        },
        btnDisable(){
            return !(this.entitlement && this.quantity>0 && this.effectiveDate)
        }
    },
    beforeMount() {
        if (!this.entitlementList.length) {
            this.$store.dispatch("admin/fetchEntitlements");
        }
        this.entitlementOptions = this.entitlementList.map((item) => {
                return { label: item.name, value: item.entitlement_id }
        })
    },
    watch:{
        entitlementList(newVal){
            this.entitlementOptions = newVal.map((item) => {
                return { label: item.name, value: item.entitlement_id }
            })
        }
    },
    methods: {
        ...mapActions('admin', {
            addProductEntitlement: 'addProductEntitlement'
        }),
        async onAddProductEntitlement() {
            let valid = false;
            let toastoptions;
            let data = {
                product_id: this.product.product_id,
                entitlement_id: this.entitlement,
                quantity: this.quantity,
                effective_date: this.effectiveDate
            }
            try {
                //checking whether this product is already mapped to this entitlement
                valid = !isDuplicate(this.entitlement,this.modalData.list, "entitlement_id", null)
                if (valid) {
                    await this.addProductEntitlement(data)
                    toastoptions = {
                        message: "Product Entitlement added successfully.",
                        type: "success",
                    };
                } else {
                    if (!valid) {
                        toastoptions = {
                            message: "Already Product Entitlement value exits.'",
                            type: "error",
                        };
                    }
                }
            } catch (error) {
                toastoptions = {
                    message: "Something went wrong when adding the tag. Please contact support.",
                    type: "error"
                }
                return
            }
            this.$store.dispatch('addToast', toastoptions);
            if (valid) {
                this.$store.dispatch('hideModal')
                this.$store.dispatch('admin/getProductEntitlementList',"product="+this.product.product_id);
            }
        }
    }
};
</script>

<style></style>