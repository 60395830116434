<template>
    <basic-header pretitle="aqfer Universal Tag" title="Create Tag Template"/>
    <div class="container-fluid">
        <form class="tab-content py-6" id="wizardSteps">
            <div class="tab-pane fade show active" id="wizardStepOne" role="tabpanel" aria-labelledby="wizardTabOne">

                <!-- Header -->
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">

                    <!-- Pretitle -->
                    <h6 class="mb-4 text-uppercase text-muted">
                        Step 1 of 3
                    </h6>

                    <!-- Title -->
                    <h1 class="mb-3">                                                                                                                        
                        Let’s start with template creation.
                    </h1>

                    <!-- Subtitle -->
                    <p class="mb-5 text-muted">
                        Begin by selecting an Event Type to build your Tag Template.
                    </p>

                    </div>
                </div> <!-- / .row -->
                <div class="row">
                    <div class="col-md-12">
                        <h4>Select Event Type</h4>                    
                    </div>
                    <div class="col-md-12">                  
                        <Tiles :tiles="eventTypeTiles" @onSelectTile="onSelectEventType" :selectedTile="eventType"></Tiles>
                    </div>
                </div>            

                <!-- Divider -->
                <hr class="my-5">

                <!-- Footer -->
                <div class="nav row align-items-center">
                    <div class="col-auto">

                    <!-- Button -->
                    <button class="btn btn-lg btn-white" type="reset">Cancel</button>

                    </div>
                    <div class="col text-center">

                    <!-- Step -->
                    <h6 class="text-uppercase text-muted mb-0">Step 1 of 3</h6>

                    </div>
                    <div class="col-auto">

                    <!-- Button -->
                    <a class="btn btn-lg btn-primary" data-toggle="wizard" href="#wizardStepTwo">Continue</a>

                    </div>
                </div>

            </div>
            <div class="tab-pane fade" id="wizardStepTwo" role="tabpanel" aria-labelledby="wizardTabTwo">

            <!-- Header -->
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">

                <!-- Pretitle -->
                <h6 class="mb-4 text-uppercase text-muted">
                    Step 2 of 3
                </h6>

                <!-- Title -->
                <h1 class="mb-3">
                    <!-- select the service you would like to utilize to initiate data -->
                </h1>

                <!-- Subtitle -->
                <p class="mb-5 text-muted">
                    Select the service you would like to utilize to initiate data
                </p>

                </div>
            </div> <!-- / .row -->

            <div class="row">
                <div class="col-md-6">
                    <input-field
                        label="Selected Event Type"
                        type="select"
                        :options="eventTypeOptions"
                        v-model="eventType"
                        :editable=false 
                    />
                </div>                
            </div> 
            <div class="row">
                <div class="col-md-12">
                    <h4>Select Platform</h4>                    
                </div>
                <div class="col-md-12">                   
                    <Tiles :tiles="platformTiles" @onSelectTile="onSelectPlatform" :selectedTile="platform"></Tiles>
                </div>
            </div>           

            <!-- Divider -->
            <hr class="my-5">

            <!-- Footer -->
            <div class="nav row align-items-center">
                <div class="col-auto">

                <!-- Button -->
                <a class="btn btn-lg btn-white" data-toggle="wizard" href="#wizardStepOne">Back</a>

                </div>
                <div class="col text-center">

                <!-- Step -->
                <h6 class="text-uppercase text-muted mb-0">Step 2 of 3</h6>

                </div>
                <div class="col-auto">

                <!-- Button -->
                <a class="btn btn-lg btn-primary" data-toggle="wizard" href="#wizardStepThree">Continue</a>

                </div>
            </div>

            </div>
            <div class="tab-pane fade" id="wizardStepThree" role="tabpanel" aria-labelledby="wizardTabThree">

            <!-- Header -->
            <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8 col-xl-6 text-center">

                <!-- Pretitle -->
                <h6 class="mb-4 text-uppercase text-muted">
                    Step 3 of 3
                </h6>

                <!-- Title -->
                <h1 class="mb-3">
                    <!-- Let’s get some last details. -->
                </h1>

                <!-- Subtitle -->
                <p class="mb-5 text-muted">
                   Select the type of tag you would like to generate
                </p>

                </div>
            </div> <!-- / .row -->

            <div class="row">
                <div class="col-md-4">
                    <input-field
                        label="Selected Event Type"
                        type="select"
                        :options="eventTypeOptions"
                        v-model="eventType"
                        :editable=false 
                    />
                </div>
                <div class="col-md-4">
                    <input-field
                        label="Platform"
                        type="select"
                        :options="platformOptions"
                        v-model="platform"
                        :editable=false 
                    />
                </div>
                <!-- <div class="col-md-4">
                    <input-field
                        label="Tag Type"
                        type="select"
                        :options="tagTypeOptions"
                        v-model="tagType"
                    />
                </div> -->
            </div> 
            <div class="row">
                <div class="col-md-12">
                    <h4>Select Tag Type</h4>
                    <Tiles :tiles="tagTypeTiles" @onSelectTile="onSelectTagType" :selectedTile="tagType"></Tiles>
                </div>
            </div>  

            <div class="card">
                <datatable
                    tableId="macroTable"
                    uniqueField="parameterName"
                    :columns="columns"
                    :data="data"
                    :compact = true
                />
            </div> 

            <!-- Divider -->
            <hr class="my-5">

            <!-- Footer -->
            <div class="nav row align-items-center">
                <div class="col-auto">

                <!-- Button -->
                <a class="btn btn-lg btn-white" data-toggle="wizard" href="#wizardStepTwo">Back</a>

                </div>
                <div class="col text-center">

                <!-- Step -->
                <h6 class="text-uppercase text-muted mb-0">Step 3 of 3</h6>

                </div>
                <div class="col-auto">

                <!-- Button -->
                <button class="btn btn-lg btn-primary" type="submit">Create</button>

                </div>
            </div>

            </div>
        </form>
    </div>

</template>

<script>
import { Tab } from 'bootstrap';
import  Tiles  from '../../components/Tiles.vue';
import Datatable from "../../components/Datatable.vue";
import BasicHeader from '../../components/BasicHeader.vue';
import InputField from "../../components/InputField.vue";
export default {
    components: { BasicHeader , InputField, Datatable, Tiles},
    data() {
        return {
            eventTypeTiles: [
                {title:"Impressions", subTitle:"Impressions sub title", value:"impressions"},
                {title:"Clicks", subTitle:"Clicks sub title", value: "clicks"},
                {title:"Conversions", subTitle:"Conversions sub title", value:"converions"},
                {title:"Engagements", subTitle:"Engagements sub title", value: "engagements"},
                {title:"Page Views", subTitle:"Page Views sub title", value: "page views"}
            ],
            eventTypeOptions: [
                    {label:"Impressions", value:"impressions"},
                    {label:"Clicks", value: "clicks"},
                    {label:"Conversions", value:"converions"},
                    {label:"Engagements", value: "engagements"},
                    {label:"Page Views", value: "page views"}
                ],
            eventType: null,
            platformTiles: [
                    {title:"GCM", subTitle:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." ,value:"GCM"},
                    {title:"SA360", subTitle:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." , value: "SA360"},
                    {title:"TTD", subTitle:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."  ,value:"TTD"}
                ],
            platformOptions: [
                    {label:"GCM", value:"GCM"},
                    {label:"SA360", value: "SA360"},
                    {label:"TTD", value:"TTD"}
                ],
            platform: null,
            tagTypeTiles: [
                    {title:"javascript", subTitle:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", value:"tag.js"},
                    {title:"Image", subTitle:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", value: "tag.gif"},
                ],
            tagTypeOptions: [
                    {label:"javascript", value:"tag.js"},
                    {label:"Image", value: "tag.gif"},
                ],
            tagType: null,
            columns: [
                { data: "parameterName" },
                { data: "parameter" },
                { data: "macros" }
            ],
            data: [{
                "parameterName": "Agency",
                "parameter": "ag",
                "macros" : "%%TTD_SUPPLYVENDOR_INT%%"
            },
            {
                "parameterName": "Advertiser",
                "parameter": "adv",
                "macros" : "%%TTD_ADVERTISERID_INT%%"
            },
            {
                "parameterName": "Campaign id",
                "parameter": "ca",
                "macros" : "%%TTD_CAMPAIGNID_INT%%"
            },
            {
                "parameterName": "Placement",
                "parameter": "pl",
                "macros" : "%%TTD_ADGROUPID_INT%%"
            },            
            ]

        }
    },

    mounted() {
        

        const toggles = document.querySelectorAll('[data-toggle="wizard"]');

        toggles.forEach(toggle => {
        toggle.addEventListener('click', function(e) {
            e.preventDefault();

            // Toggle new tab
            const tab = new Tab(toggle);

            tab.show();

            // Remove active state
            toggle.classList.remove('active');
        });
        });

    },

    methods: {
        onSelectEventType(val) {
            this.eventType = val;
        },
        onSelectPlatform(val) {
            this.platform = val;
        },
        onSelectTagType(val) {
            this.tagType = val;
        }
    }

}
</script>

<style>

</style>