<template>
	<label class="form-label">
		<span>Choose Template (optional)</span>
	</label>
	<Multiselect
		v-model="templateName"
		:options="options"
		@change="onTemplateChange"
		class="multiselect-blue"
	>
		<template v-slot:singlelabel="{ value }">
			<div class="multiselect-single-label">
				<span class="option__label">{{ value.name }}</span>
			</div>
		</template>
		<template v-slot:option="{ option }">
			<div>
				<span class="option__label">{{ option.name }}</span>
				<span class="text-muted"
					><strong>{{ option.description }}</strong></span
				>
			</div>
		</template>
	</Multiselect>

	<div
		v-if="dynamicFields && Object.keys(dynamicFields).length !== 0"
		class="my-3"
	>
		<label for="basic-url" class="form-label"
			>Fill in the following dynamic fields(optional)
		</label>
		<div
			class="mb-3"
			v-for="(value, key, index) in dynamicFields"
			:key="index"
		>
			<div class="input-group mb-3">
				<div class="input-group-prepend col-3 w-30">
					<span class="input-group-text w-100" id="basic-addon3">{{
						key.toUpperCase()
					}}</span>
				</div>
				<input
					type="text"
					class="form-control"
					id="basic-url"
					aria-describedby="basic-addon3 basic-addon4"
					:value="dynamicFields[key.toUpperCase()]"
					@input="update(key, $event.target.value)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import Multiselect from "@vueform/multiselect";
	export default {
		components: { Multiselect },
		data() {
			return {
				dynamicFields: {},
				templateName: null,
				currentTemplate: null,
				options: [],
			};
		},
		props: {
			jobTemplates: {
				type: Array,
				default: () => [],
			},
		},
		watch: {
			jobTemplates(newVal) {
				let self = this;
				this.setDefaultTemplate(newVal);
				this.options = [];
				newVal.map((template) => {
					return self.options.push({
						name: template.name,
						value: template.name,
						description: template.description,
					});
				});
			},
		},
		methods: {
			update(key, value) {
				this.dynamicFields[key.toUpperCase()] = value;
				this.$emit("update:modelValue", this.dynamicFields);
			},
			onTemplateChange(templateName) {
				this.dynamicFields = {};
				if (templateName) {
					const currentTemplate = this.jobTemplates.filter(
						(template) => template.name == templateName
					);
					this.currentTemplate = currentTemplate.length
						? currentTemplate[0]
						: null;
					this.$emit("setCurrentTemplate", this.currentTemplate);
					const templateFields = JSON.parse(
						this.currentTemplate.template_fields
					);
					templateFields.forEach((entry) => {
						// Extract the fields from the entry
						const { fields } = entry;

						// Add each field to the dynamicFields object
						fields.forEach((field) => {
							this.dynamicFields[field.toUpperCase()] = ""; // You can set an initial value if needed
						});
					});
				} else {
					this.currentTemplate = null;
					this.$emit("setCurrentTemplate", this.currentTemplate);
					this.dynamicFields = {};
				}
			},
			setDefaultTemplate(templates) {
				let defaultTemplates = templates.filter((template) => {
					const tags =
						template.tag && typeof template.tag === "string"
							? template.tag.toUpperCase().split(",")
							: template.tag;
					return tags ? tags.includes("DEFAULT") : false;
				});
				this.templateName = defaultTemplates.length
					? defaultTemplates[0].name
					: null;
				this.onTemplateChange(this.templateName);
			},
		},
		beforeMount() {
			if (this.jobTemplates) {
				this.setDefaultTemplate(this.jobTemplates);
			}
		},
		emits: ["update:modelValue", "change"],
	};
</script>

<style scoped>	
	.option__label {
		display: block;
	}
</style>
