<template>
    <div class="modal-card card">
        <form @submit.prevent="onFormSubmit()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">{{  `Delete ${title}` }}</h4>
                <!-- Close -->
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="card-body">
                <p>{{ `Are you sure you want to delete the following ${title}?` }}</p>
                <ul class="list-group list-group-sm">
                    <li v-for="item in modalData" :key="item.index" class="list-group-item">
                        {{item.name}} 
                    </li>
                </ul>
                <p v-if="error" class="mt-2"><code>{{error}}</code></p>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger">
                    <div
                        v-if="deleteInProgress"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else><slot></slot>Delete</div>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
export default {
    data() {
        return {
            error: "",
            job: {},
            deleteInProgress: false,
            title: "Jobs",
            sandboxId: this.$route.params.sandboxId,
            executionId:null,
            status:null,
            deleteHandlers:this.modalData,
            jobName:null,
        };
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    name: "DeleteSAndboxJobs",
    methods: {
        ...mapActions("sandbox", {
            deleteSandboxJob: "deleteSandboxJob",
        }),
        async onFormSubmit() {
            this.deleteInProgress = true;
            let deletePromiseArray = [];
            if(this.title =="handlers"){
                this.deleteHandler()
            } else{   
            for (const job of this.modalData) {                 
                deletePromiseArray.push( this.deleteSandboxJob(job.name));
            }        
            try {
                await Promise.all(deletePromiseArray);               
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            } finally {
                this.deleteInProgress = false;
            }

            this.$store.dispatch("hideModal"); 
            this.$store.dispatch("sandbox/fetchSandboxJobs");
            }
        },
        async deleteHandler(){
            this.deleteInProgress = true;
            try {
                if(this.deleteHandlers.length>0) {                 
                    this.deployHandler(this.deleteHandlers[0]);
                } else{
                    this.jobName= null;
                    this.executionId= null;
                    this.deleteInProgress =false;
                    this.$store.dispatch("hideModal"); 
                    this.$store.dispatch("sandbox/fetchSandboxJobs");
                }         
            } catch (error) {
                if (error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }   
        },
        async deployHandler(job){
            this.jobName =job.name;
            const promise = axios
                .post(
                    `${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/${this.sandboxId}/jobs/${job.name}/executions`,
                    {
                        "env_params": {
                            "OP": "delete"
                        }
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.accessToken}`,
                        },
                    }
                )
                .then((res) => {
                    this.executionId= res.data.execution_id;
                    this.handleJobChecking();
                })
                .catch((error) => {
                    self.error = error.message;
                    this.$store.dispatch("addToast", {
                        message: self.error,
                        type: "error",
                    });
                });
        },
        async handleJobChecking() {
            const statusPromise = this.getExecutionStatus();
            statusPromise.finally((respones) => {
                if (this.status && this.status != 'failed' && this.status != 'succeeded' && this.status != 'cancelled') {
                    setTimeout(() => {
                        this.handleJobChecking();
                    }, 2000)
                } else if (this.status == "failed") {
                    this.$store.dispatch("addToast", {
                        message: this.jobName+ " deploy failed. Please check the job",
                        type: "error",
                    });
                    this.jobName= null;
                    this.executionId= null;
                    this.deleteInProgress =false;
                    this.$store.dispatch("hideModal"); 
                    this.$store.dispatch("sandbox/fetchSandboxJobs");
                } else if (this.status == 'succeeded') {
                    try{
                        this.deleteSandboxJob(this.jobName);
                        this.$store.dispatch("addToast", {
                            message: this.jobName+" deleted successfully",
                            type: "success",
                        });
                        setTimeout(() => {
                            this.deleteHandlers.shift();
                            this.deleteHandler();
                        }, 2000)
                    }catch(error){
                        self.error = error.message;
                        this.$store.dispatch("addToast", {
                            message: self.error,
                            type: "error",
                        });
                    }
                }
            })
        },
        getExecutionStatus() {
            let self = this;
            return axios.get(this.baseEndpoint + `/jobs/${this.jobName}/executions/${this.executionId}?include_metrics=all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.accessToken}`,
                },
            }).then((response) => {
                if (response.data) 
                    this.status = response.data.status.toLowerCase();

            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.error)
                    this.error = "Deploy " +error.response.data.error;
                else this.error = "Deploy " +error.message;
            });
        },
    },
    computed:{
        baseEndpoint() {
            let endpoint = `${this.$store.state.lakeviewUrl}/v1/cids/${this.$store.state.currentClient.cid}/sandboxes/`;
            return endpoint + this.sandboxId
        },
    },
    beforeMount() {
        if(this.modalData[0] && ((this.modalData[0].kind == "AIO-HANDLER" || this.modalData[0].kind == "AIO-HANDLER-DBDS" || this.modalData[0].kind == "AIO-HANDLER-GRAPHDS")))       
            this.title = "handlers";
    }
};
</script>

<style>
</style>