<template>
    <div class="form-group" :class="[colField ? colField:'', inlineField?'inline-field row':'']" v-if="visible" :disabled="!editable">
        <label v-if="(type != 'checkbox' || options  || (type == 'checkbox' && inlineField)) && showLabel" :class="inlineField?'col-sm-4 col-form-label':'form-label'" ><span>{{ label }}&nbsp;</span><span v-if="toolTip" :class="[toolTipHtml?'tooltip-width':'', 'fa fa-circle-info text-muted']" :title="toolTip" data-bs-toggle="tooltip" :data-bs-html="toolTipHtml" data-bs-placement="right"></span><span class="text-danger" v-if="isRequired">&nbsp;*</span></label>
        
        <a v-if="url" :href="url" target="_blank" class="card-link link-primary" :title="toolTip" data-bs-toggle="tooltip" data-bs-placement="right"> <span class="fa fa-lock" ></span></a>
        
        <input v-if="type == 'number'" class="form-control" :class="classes"  v-model.number="inputVal" type="number" :disabled="!editable" :readonly="!editable" @keyup="perfValidation" @blur="perfValidation"/>

        <input v-if="type == 'text' || type == 'array'" class="form-control" :class="classes"  v-model="inputVal" type="text" :placeholder="placeholder" :disabled="!editable" :readonly="!editable" @keyup="perfValidation" @blur="perfValidation"/>
            
        <textarea v-if="type == 'textarea'" class="form-control" :class="classes"  v-model="inputVal" type="text" rows="3"  :disabled="!editable" :readonly="!editable" @keyup="perfValidation" @blur="perfValidation"/>

        <textarea v-if="type == 'object' && !parseObject && !inputType" class="form-control" :class="classes"  v-model="inputVal"  rows="3" type="text" :disabled="!editable" :readonly="!editable" @keyup="perfValidation" @blur="perfValidation"></textarea>

        <div v-if="type == 'object' && !parseObject && inputType == 'json'" class="form-control" :class="classes" >
            <CodemirrorEditor :editorId="`${name}-${Math.random()}`" v-model="inputVal"  mode="application/json"  :refreshOn="refreshField" :disabled="!editable" :readOnly="!editable"></CodemirrorEditor>
        </div>

        <div v-if="type == 'script'" class="form-control" :class="classes" >
            <CodemirrorEditor :editorId="`${name}-${Math.random()}`" v-model="inputVal"  mode="text/javascript"  :refreshOn="refreshField" :disabled="!editable" :readOnly="!editable"></CodemirrorEditor>
        </div>
    
        <div v-if="type == 'sql'" class="form-control" :class="classes" >
            <CodemirrorEditor :editorId="`${name}-${Math.random()}`" v-model="inputVal"  mode="sql"  :refreshOn="refreshField" :disabled="!editable" :readOnly="!editable"></CodemirrorEditor>
        </div>

        <div v-if="type == 'yaml'" class="form-control" :class="classes" >
            <CodemirrorEditor :editorId="`${name}-${Math.random()}`" v-model="inputVal"  mode="yaml"  :refreshOn="refreshField" :disabled="!editable" :readOnly="!editable" :performLint="name !== 'vars.yaml' && name !== 'vars'"></CodemirrorEditor>
        </div>

        <div v-if="type == 'go'" class="form-control" :class="classes" >
            <CodemirrorEditor :editorId="`${name}-${Math.random()}`" v-model="inputVal"  mode="go"  :refreshOn="refreshField" :disabled="!editable" :readOnly="!editable"></CodemirrorEditor>
        </div>
        
        <select v-if="type == 'select'" class="form-control" :class="classes" v-model="inputVal" :disabled="!editable" :readonly="!editable">
            <option
                v-for="option in options" 
                :key="option.index" 
                :value="option.value"
            >
                {{ option.label }}
            </option>
        </select>        
        
         <div v-if="type == 'checkbox' && options" :class="classes"  :disabled="!editable" :readonly="!editable" @keyup="perfValidation" @blur="perfValidation">
            <div class="form-check form-check-inline" v-for="option in options" :key="option.index" >
                <input :disabled="!editable" class="form-check-input multi" type="checkbox" v-model="inputVal" :id="option.index" :value="option.value"  true-value="1" false-value="0" >
                <label class="form-check-label" for="option.index">{{option.label}}</label>
            </div>            
        </div>

        <div v-if="type == 'checkbox' && !options" :class="classes"  :disabled="!editable" :readonly="!editable" @keyup="perfValidation" @blur="perfValidation">          
            <div :class="inlineField?'form-check form-check-inline':'form-check'">
                <input :disabled="!editable" class="form-check-input" id="checkbox" type="checkbox" v-model="inputVal" />
                <label v-if="!inlineField" class="form-check-label" ><span>{{ label }}</span><span v-if="toolTip" class="fa fa-circle-info text-muted" :title="toolTip" data-bs-toggle="tooltip" data-bs-placement="right"></span><span class="text-danger" v-if="isRequired">&nbsp;*</span></label>
            </div>
        </div>

        <div v-if="type == 'radio'"  :class="classes" :disabled="!editable" :readonly="!editable" @keyup="perfValidation" @blur="perfValidation">
            <div class="form-check form-check-inline" v-for="option in options" :key="option.index" >
                <input :name="name" :disabled="!editable" class="form-check-input" type="radio" v-model="inputVal" :id="option.label" :value="option.value"  >
                <label class="form-check-label" :for="option.label">{{option.label}}</label>
            </div>            
        </div>
        <div v-if="type == 'object' && parseObject"  class="row" :class="classes">            
            <div v-for="(value, name, index) in inputVal" :key="index" class="col-md-6">
                <div class="row form-group child-form-group">
                    <label class="col-sm-4 col-form-label" for="">{{this.toCapitalizedWords(name)}}</label>
                    <input class="form-control col-sm-8" type="text" v-model="inputVal[name]" :disabled="!editable" :readonly="!editable">
                </div>               
            </div>   
        </div>

        <div v-if="type == 'object' && inputType == 'file'"   :class="classes" style="padding-left:0px">
            <textarea v-if="type == 'object'" class="form-control"  v-model="inputVal"  rows="3" type="text" :disabled="!editable" :readonly="!editable" @keyup="perfValidation" @blur="perfValidation"></textarea>
            <div v-if="editable" class="input-group" style="margin-top:5px">
                <input type="file"  id="customFile" @change="onFileUpload">
            </div>
        </div>

        <div v-if="customComponent"  :class="classes" >
            <component :is="component" :visible="visible" :editable="editable" v-model="inputVal"></component>
        </div>

        <div v-if="type=='calendar'">
            <flat-pickr :config="inputConfig" class="form-control rounded" placeholder="Select date"
                name="date" label="label" v-model="inputVal"/>
        </div>
        <div v-if="type=='multiselect'" :disabled="!editable" :class="classes"  class="p-0">
            <MultiSelect 
                :name="name"
                :options="options"
                :placeholder="placeholder" 
                :editable="editable" 
                v-model="inputVal"
                :createOption="createOption"
                @multiSelectvalidation="perfValidation" 
            />
        </div>
        <div v-if="type=='customSingleSelect'" :disabled="!editable" :class="classes"  class="p-0">
            <MultiSelect 
                mode="single"
                :name="name"
                :options="options"
                :placeholder="placeholder" 
                :editable="editable" 
                v-model="inputVal"
                :createOption="createOption"
                @multiSelectvalidation="perfValidation" 
            />
        </div>

        <div v-if="type=='cron'" :disabled="!editable" :class="classes"  class="p-0">
            <cron-schedule :visible="visible" :editable="editable" v-model="inputVal" ></cron-schedule>            
        </div>
        <div v-if="type=='image'" :disabled="!editable" :class="classes"  class="p-0">
            <FileUploader :onFileChange="onFileChange" :editable="editable" :value="inputVal" :maxSize="maxSize" :format="format"/>        
        </div>
        <div class="invalid-feedback">
            {{error}}
        </div>

        <div v-if="helpText" class="form-text" v-html="helpText">
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";
import { Tooltip } from "bootstrap";
import formValidation from '../utils/formValidation';
import SubCondition from './inputs/SubCondition.vue';
import ContentThrottling from './inputs/ContentThrottling.vue';
import UsMultiStatePrivacy from './inputs/UsMultiStatePrivacy.vue'
import CronSchedule from "./inputs/CronSchedule.vue";
import JsonField from "./inputs/JsonField.vue";
import CodemirrorEditor from "./CodemirrorEditor.vue";
import MultiSelect from './MultiSelect.vue';
import flatPickr from 'vue-flatpickr-component';
import FileUploader from "./FileUploader.vue";
import 'flatpickr/dist/flatpickr.css';
import { text } from "body-parser";
import { DateTimeFormat } from "../utils/commonFunction";
export default {    
    components: { SubCondition, ContentThrottling, flatPickr, CodemirrorEditor, MultiSelect, CronSchedule, FileUploader, JsonField , UsMultiStatePrivacy},
    name: "InputField", 
    setup(props, {emit}) {
        let inputVal = ref("");
        let error = ref("");
        const { validateInput } = formValidation(); 
        const perfValidation = () => {  
            const validationRes = validateInput(props.name, props.label, inputVal.value, props.validate);
            const rulesRes = validationRes.rules;            
            const failedRule = rulesRes.find(rule => !rule.passed); 
            error.value = (failedRule && failedRule.message)?failedRule.message:'';
        };
        
        inputVal  = computed({
                get: () => { 
                    if (!props['modelValue'] && props['defaultValue'] !== undefined) {
                        return props['defaultValue']
                    } else if(!props['modelValue'] && props.type == "checkbox" && props.options) {
                        return [];
                    } else if(props.type == "object" && typeof props['modelValue'] === 'object' && !props['parseObject'] && props['modelValue'] !== null) {                        
                        return JSON.stringify(props['modelValue']);
                    } else {  
                        if(props.format){
                            switch(props.format){
                                case 'datetime':
                               return DateTimeFormat(props['modelValue'])
                            }
                        }                       
                        return props['modelValue'];
                    }                    
                },
                set: (val) => {                    
                    if (props.type == 'array') 
                        val = val.length? val.split(',') : val.split("");
                    if (props.type == 'multiselect') 
                        val = val? Object.values(val):[];
                    if (props.type == 'object' && props.inputType == 'json') {
                        try {
                                if (JSON.parse(val)) {
                                    val = JSON.parse(val);
                                }
                        } catch(e) {
                            console.log(e);
                        }
                        
                    }   
                    emit(`update:modelValue`, val,props['name']) ;
                    emit("change", val);
                } 
            
            });
            
        return { inputVal, error, perfValidation };
        
    },
    computed: {       
        classes() {
            let classes = []
            if(this.inlineField) {
                classes.push('col-sm-8')
            }
            if (this.type == 'select') {
                classes.push('form-select')
            }
            if (this.error) {
                classes.push('is-invalid')
            }
            return classes.join(' ')
        },
        isRequired() {
            if (this.validate && this.validate.length) {
                for (let i = 0; i < this.validate.length; i++) {
                    if(this.validate[i].type == "required")
                        return true;
                }
            }
            return false
        },
        refreshField() {
            if (this.refreshOn !== undefined)
                return this.refreshOn
            return !this.editable   
        }
    },
    watch: {
        editable() {
            // Resetting the error when the field is set to original state
            this.error = '';
        }

    },
    methods: {
        toCapitalizedWords(name) {
            var words = name.match(/[A-Za-z][a-z]*/g) || [];
            return words.map(this.capitalize).join(" ");
        },
        capitalize(word) {
            return word.charAt(0).toUpperCase() + word.substring(1);
        },
        onFileUpload(event) {
            let inputFiles = event.target.files;
            if(inputFiles && inputFiles.length) {
                const file = inputFiles[0];
                const reader = new FileReader();
                reader.onload = (res) => {
                    this.inputVal = res.target.result.replace(/[\n\r]+/g, '\n');
                };
                reader.readAsText(file);
            }
        },
        onFileChange(file){
            if (file.length > 0)
               this.inputVal = file[0]
            else
                this.inputVal=null
        }
    },
    mounted(){
        //need to reset input 'number' fields due to validation issues
        if(this.type === 'number'){ 
            let input = this.inputVal;
            this.inputVal = input;
        }
    },
    props: {
        type: {
            type: String,
            default: "text",
        }, 
        name: {
            type: String,
            default: "",
        },
        helpText: {
            type: String,
            default: "",
        },           
        label: {
            type: String,
            default: "Label",
        },
        placeholder: {
            type: String,
            default: "",
        },        
        editable: {
            type: Boolean,
            default: true
        },
        visible: {
            type: Boolean,
            default: true
        },
        inlineField: {
            type: Boolean,
            default: false
        },    
        parseObject: {
            type: Boolean,
            default: false
        },
        options: Array,
        refreshOn: Boolean,
        defaultValue: {
            type: [String, Array, Object, Number, Boolean],
            default: undefined,
        },
        modelValue: [String, Array, Object, Number, Boolean],
        validate: [String, Array],
        inputType: {
            type: String,
            default: null
        },
        toolTip: {
            type: String,
            default:''
        },
        customComponent: {
            type: Boolean,
            default: false
        },
        component: {
            type: String,
            default: null
        },
        inputConfig: {
            type: Object,
            default: null
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        url: {
            type: String,
            default:null
        },
        format: {
            type: String,
            default:null
        },
        createOption:{
            type:Boolean,
            default: true
        },
        maxSize:{
            type:Number,
            default: 0 //no limit
        },
        colField: {
            type: String,
            default:null
        },
        toolTipHtml:{
            type:Boolean,
            default: false
        }
            
        
    },
    emits:[
        "update:modelValue",
        'change'
    ]
};
</script>

<style>
[class*="col-"].form-group:last-child{
    border-bottom: 2px solid!important;
    border-color: #edf2f9BD!important;
}
.tooltip-width .tooltip-inner{
    max-width: 400px;
}
</style>