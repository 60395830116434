<template>
		<Multiselect
			v-if="mode=='single'"
			v-model="inputVal"
			:searchable="true"
			:create-option="createOption"
			:add-option-on="['enter', ',']"	
			:options="optionsList"
			class="multiselect-blue"
			:placeholder="placeholder"
			:disabled="!editable"
			ref="multiselect"
      />
		
      <Multiselect v-else
        @focusout="createOption?onMultiselectBlur:null"
        v-model="inputVal"
        mode="tags"
        :close-on-select="false"
        :searchable="true"
        :create-option="createOption"
		:add-option-on="['enter', ',']"	
		:options="optionsList"
        class="multiselect-blue"
		:disabled="!editable"
        ref="multiselect"
      />
  </template>
  
  <script>
    import Multiselect from '@vueform/multiselect'
    export default {
		components: {
			Multiselect,
		},
    data(){
    return {
      optionsList:[]
      }
    },
		name:"MultiSelect",
		computed: {
			inputVal:{
				get() {
					return this.modelValue;
				},
				set(newVal) {
					this.$emit("update:modelValue", newVal);
				}
			}
		}, 
		watch: {
			modelValue() {			
				this.triggerValidation();
			},
			options() {
				this.optionsList=this.options.length? this.options: this.modelValue;
			}
		}, 
		methods: {
			triggerValidation() {
				this.$emit("multiSelectvalidation");
			},
			onMultiselectBlur(event){
				const val = event.target.value;
				if(val) {
					this.optionsList = [...this.optionsList, val ];
					this.inputVal = [...this.inputVal, val ];
					//last line is needed for plugin to refresh the latest item
					this.$refs.multiselect.selectAll()
				}
			},
		} ,
		beforeMount(){
			let list=[]
			if(this.name ==="cids"){
				//Getting the users Client list 
				const clientList=this.$store.state.clients
				list=clientList.map(item=> item.cid)
				// In edit if other user have any other cids means need to add in options, then only it will be automatically selected
				list=[...list,...(this.modelValue ? this.modelValue : [])]
				//Removing Duplicate cid if present
				this.optionsList=[...new Set(list)]
			} else {
				this.optionsList=this.options.length? this.options: this.modelValue;
			}
		},
		props: {
			modelValue: [Array, String],
			editable: {
				type: Boolean,
				default: true,
			},
			name:{
				type: String,
				default: ""
			},
			options:{
				default:()=>[]
			},
			createOption:{
				type: Boolean,
				default: true
			},
			mode: {
				type: String,
				default: "tags"
			},
			placeholder: {
				type: String,
				default: ""
			}

		},
		emits:[
			"update:modelValue",
			'change',
			"multiSelectvalidation"
		]
    }
  </script>
  <style>
    @import '../../node_modules/@vueform/multiselect/themes/default.css';
    .multiselect-blue {
    --ms-tag-bg: #DBEAFE;
    --ms-tag-color: #2563EB;
    --ms-ring-color:#DBEAFE;
  }
   .multiselect.is-disabled {
		background-color: #EDF2F9;
	}
	.multiselect {
		border: 1px solid #D2DDEC;
	}
  </style>