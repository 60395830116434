<template>
    <div v-if="list.length>0" class="dropdown">
        <a href="#" class="dropdown-ellipses dropdown-toggle p-2" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-ellipsis-v"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
            <a v-for="btn in list" :key="btn.id" class="dropdown-item" @click="btn.onClick">
                {{ btn.name }}
            </a>
        </div>
    </div>
</template>
<script>
export default ({
    name: "ActionDropdown",
    props: {
        btnList: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    computed: {
        list() {
            //filter only visible button
            return this.btnList.filter((value) => value.visible)
        }
    }
})
</script>
<style scoped>
.dropdown-ellipses {
    color: #6E84A3 !important;
    font-size: 1.1625rem !important;
}

.dropdown-ellipses:hover {
    color: #000000 !important;
}
</style>