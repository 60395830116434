<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddHandlerRequest()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add Request
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="fields" v-model="handlerRequest" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="fields" v-model="handlerRequest" />
            </div>
        </div>
        </form>
    </div>   
</template>

<script>
//handlerFields
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/handlerRequestFields"
export default {
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            handlerRequest: {
                method: 'GET',
                headers: JSON.stringify({
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded"
                },4,2),
                payload: JSON.stringify({
                    "foo": "bar"
                },4,2)
            },
            fields: Fields
        }
    },
    name: "AddHandlerRequest",
    methods: {
        ...mapActions('aio', {
            createHandlerRequest: 'createHandlerRequest'
        }),
        async onAddHandlerRequest() {
            var response
            try {
                response = await this.createHandlerRequest(this.handlerRequest)
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }

            this.$store.dispatch('hideModal')
            await this.$store.dispatch('aio/fetchHandlerRequests', this.$store.state.aio.currentHandlerJob.name)
        }
    },
    beforeMount() {       
        let env ="";
        if(this.$store.state.environment === "dev")
            env ="-dev"
        else if (this.$store.state.environment === "pre_prod")
            env ="-preprod"

        let urlStart ="dbds"; 
        if(this.$store.state.aio.currentHandlerJob.kind == "AIO-HANDLER-GRAPHDS"){
            urlStart= "graphds"
        }        
        this.handlerRequest["domain"]=`https://${urlStart}-sandbox-${this.$store.state.currentClient.cid}.api${env}.aqfer.net`
        this.handlerRequest["path"]='/v2/entities/a~b~c'
    },


}
</script>

<style>

</style>