const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required" },
            { type: "duplicate", list:[] }
        ]
    },
    {
        name: "event_type",
        label: "Event Type",
        type: "select",
        editable: true,
        visible: true,
        options:[],
        validation: [
            { type: "required" }
        ]
    },
    {
        name: "initiator",
        label: "Initiator",
        type: "select",
        editable: true,
        visible: true,
        options:[]
    },
    {
        name: "tag_type",
        label: "Tag Type",
        type: "select",
        editable: true,
        visible: true,
        options:[
            { label: "a.gif", value: "a.gif" },
            { label: "c.gif", value: "c.gif" },
            { label: "r.gif", value: "r.gif" },

            { label: "s.js", value: "s.js" },
            { label: "c.js", value: "c.js" },
            { label: "cs.js", value: "cs.js" },

            { label: "t.dhj", value: "t.dhj" },
            { label: "tc.dhj", value: "tc.dhj" },
            { label: "ts.dhj", value: "ts.dhj" },
            { label: "tcs.dhj", value: "tcs.dhj" }
        ],
        validation: [
            { type: "required" }
        ]
    },
    {
        name: "tag",
        label: "Category Tags",
        type: "multiselect",
        editable: true,
        visible: true
    },
    {
        name: "logging_mode",
        label: "Logging Mode",
        type: "select",
        editable: true,
        visible: true,
        options:[
            { label: "A", value: "a" },
            { label: "B", value: "b" },
            { label: "C", value: "c" },
            { label: "D", value: "d" },
            { label: "E", value: "e" },
            { label: "F", value: "f" },
            { label: "G", value: "g" },
            { label: "H", value: "h" },
            { label: "I", value: "i" },
            { label: "J", value: "j" },
            { label: "K", value: "k" },
            { label: "L", value: "l" },
            { label: "M", value: "m" }
        ]
    },
    {
        name: "owner",
        label: "Owner",
        type: "text",
        editable: false,
        validation: [
            { type: "required" }
        ]
    },
    {
        name: "cid",
        label: "Client ID",
        type: "text",
        editable: false,
        validation: [
            { type: "required" }
        ]
    },
];

export default Fields;

