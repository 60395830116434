<template>
  <basic-header pretitle="aqfer Universal Tag" title="Tag Dashboards" />
  <div class="container-fluid" id="tag_dashboard">
    <div class="row">
        <div class="col-md-12">
            <div class="mb-2">Choose a Class</div>
                <button
                    style="margin-right:10px"
                    key="all"
                    @click="currentCls = null"
                    class="btn card-header-title "
                    :class="[!currentCls? 'active btn-primary' : 'btn-secondary']"
                    >
                    All
                </button>
                <button
                 style="margin-right:10px"
                    v-for="classItem in classItems"
                    :key="classItem.cls == 'NA' ? 'default' : classItem.cls"
                    @click="currentCls = classItem.cls"
                    class="btn card-header-title "
                    :class="[currentCls == classItem.cls ? 'active btn-primary' : 'btn-secondary']"
                >
                    {{ classItem.cls == "NA" ? "default" : classItem.cls }}
                </button>
            </div>
    </div>
    <div class="row"> 
        <div class="col-md-2">
            <input-field
            label="Trend"
            type="select"
            :options="trendOptions"
            v-model="trend"
        />
        </div>
        <div class="col-md-3" v-if="trend == 'Hourly'">
            <input-field label="Date(UTC)" type="calendar" v-model="date" :inputConfig="config" />
        </div>
        <div class="col-md-2" v-if="trend == 'Hourly' && tagTypesUnique.length">  
            <label class="form-label"> <span>Tags Type</span></label>          
            <select 
                class="form-select"
                label="Tags Type"
                v-model="tagType">                    
                <option
                    v-for="option in tagTypesUnique"
                    :key="option"
                    :value="option"
                >
                    {{ option }}
                </option>
            </select>        
        </div>
        <div class="col-md-3" v-if="trend == 'Hourly'&& countriesUnique.length" >
            <div class="form-group" >
                <label class="form-label"> <span>Country</span></label>
                <select 
                class="form-select"
                label="Country"
                v-model="country">                    
                    <option
                        v-for="option in countriesUnique"
                        :key="option"
                        :value="option"
                    >
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3" v-if="trend == 'Daily'">
            <input-field label="Start Date(UTC)" type="calendar" v-model="startDate" :inputConfig="config" />
        </div>
        <div class="col-md-3" v-if="trend == 'Daily'">
            <input-field label="End Date(UTC)" type="calendar" v-model="endDate" :inputConfig="config" />
        </div>
        
        <div class="col-md-2 ms-auto">
            <div class="form-group">
                <label class="form-label">&nbsp;</label>
                <div class="text-end" v-if="trend == 'Daily'">
                    <button class="btn btn-primary" @click="generateDashboard">
                        Generate</button
                    ><span>&nbsp;</span>                   
                </div>
            </div>
        </div>
    </div>    
    <div v-if="!graphLoading">
        <div class="row" v-if="trend == 'Hourly'">
            <div class="card  col-md-12">
                <div class="card-header">
                    <h4 class="card-header-title">
                        Hourly Chart
                    </h4>
                    <span class="text-secondary me-3">
                        Display current hour:
                    </span>
                    <div class="custom-control form-switch">
                        <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="showCurrentHour">
                    </div>

                </div>
                <div class="card-body">
                    <div :class="[(tagTypesUnique.length)?'hide':'']">
                        <p>{{errMsg}}</p>
                    </div>                    
                    <div class="chart-view" :class="[(!tagTypesUnique.length)?'hide':'']" >
                        <canvas  id="countChart" 
                                class="chart-canvas"                                
                        >
                        </canvas>
                    </div>                    
                </div>
            </div>
        </div>
        <div v-if="trend == 'Daily'">            
            <div class="row">
                <div class="card  col-md-12">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            Tag Type
                        </h4>
                        <div class="input-group float-end w-25">
                            <span class="input-group-text">Threshold</span>
                            <input type="number" class="form-control" aria-label="limit" id="thresholdTagType" v-model="threshold_tagType" step="1000"/>  
                        </div>   

                    </div>
                    <div class="card-body">
                        <div :class="[Object.keys(tempTtObj).length && !(dailyChartMsg['ttDaily'])?'hide':'']">
                            <p>{{dailyChartMsg['ttDaily']}}</p>
                        </div>
                        <div class="row">
                            <div class="chart-view col-md-8" >
                                <canvas  id="ttDaily"></canvas>
                            </div>
                            <div id="ttDaily-legend-container" class="col-md-4"></div>
                        </div>                        
                    </div>
                </div>
            </div>           
            <div class="row">
                <div class="card  col-md-12">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            Partner Domain
                        </h4>
                        <div class="input-group float-end w-25">
                            <span class="input-group-text">Threshold</span>
                            <input type="number" class="form-control" aria-label="limit" id="thresholdPartnerBeacon" v-model="threshold_partnerBeacon" step="1000"/>  
                        </div>  
                    </div>
                    <div class="card-body">
                        <div :class="[(Object.keys(tempPdObj).length && !dailyChartMsg['pdDaily'])?'hide':'']">
                            <p>{{dailyChartMsg['pdDaily']}}</p>
                        </div>
                        <div class="row"> 
                            <div class="chart-view col-md-8" >
                                <canvas  id="pdDaily"></canvas>
                            </div>
                            <div id="pdDaily-legend-container" class="col-md-4"></div>
                        </div>
                        
                    </div>
                </div>
            </div>            
            <div class="row">
                <div class="card col-md-12">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            Redirect Domain
                        </h4>
                        <div class="input-group float-end w-25">
                            <span class="input-group-text">Threshold</span>
                            <input type="number" class="form-control" aria-label="limit" id="thresholdRedirect" v-model="threshold_redirect" step="1000"/>  
                        </div>   

                    </div>
                    <div class="card-body">                       
                            <div :class="[( Object.keys(tempRdObj).length && !dailyChartMsg['rdDaily'])?'':'']">
                                <p>{{dailyChartMsg['rdDaily']}}</p>
                            </div>
                            <div class="row">
                                <div class="chart-view col-md-8">
                                    <canvas  id="rdDaily" ></canvas>
                                
                                </div>
                                <div id="rdDaily-legend-container" class="col-md-4"></div>
                            </div>       
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div v-else class="text-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    
    
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  {
      id: 'no-data-label',
      afterDraw: (chart, args, options) => {
        const title = chart.options.plugins.title.text;
        //chart.canvas.parentNode.style.height = '40vh';
        if (!chart.data.datasets.length) {
            // No data is present
            chart.canvas.parentNode.style.height = '128px';
            const current = chart.ctx;
            const width = chart.width;
            const height = chart.height
            chart.clear();

            current.save();
            current.textAlign = 'center';
            current.textBaseline = 'middle';
            current.font = "bold 12px 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'";            
            current.color = "white";
            current.fillText(title, width / 2, 18);
            current.fillText('No data found!', width / 2, height / 2);
            current.restore();
        }
    }
  }
);

const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.display = 'flex';
        listContainer.style.flexDirection = 'column';
        listContainer.style.margin = 0;
        listContainer.style.padding = 0;
        legendContainer.appendChild(listContainer);
    }
  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterDraw(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);
    if(!ul.firstChild) {
        // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            items.forEach(item => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.marginLeft = '10px';
            li.dataset.datasetIndex = item.datasetIndex;

            li.onclick = () => {
                const {type} = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                // Pie and doughnut charts only have a single dataset and visibility is per item
                chart.toggleDataVisibility(item.index);
                } else {
                chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            li.onmouseover = (event) => {
                chart.data.datasets.forEach((dataset, index, datasets) => {
                    datasets[index].pointRadius = index === item.datasetIndex ? 3: 0;
                    datasets[index].borderWidth = index === item.datasetIndex ? 5: 2;                               

                });
                chart.update();         
            };

            li.onmouseout = (event) => {
                chart.data.datasets.forEach((dataset, index, datasets) => {
                    datasets[index].borderWidth = 3;
                    datasets[index].pointRadius = 3;
                });
                chart.update();
            }  
            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.borderColor = item.strokeStyle;
            boxSpan.style.borderWidth = item.lineWidth + 'px';
            boxSpan.style.display = 'inline-block';
            boxSpan.style.height = '20px';
            boxSpan.style.marginRight = '10px';
            boxSpan.style.width = '40px';
            boxSpan.style.pointerEvents = 'none';

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = 0;
            textContainer.style.padding = 0;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
            textContainer.style.pointerEvents = 'none';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);

            ul.appendChild(li);
            
            });            
        }
    }    
};




import BasicHeader from '../../components/BasicHeader.vue'
import InputField from "../../components/InputField.vue";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import axios from 'axios';
import { mapActions, mapState} from "vuex";
dayjs.extend(customParseFormat)
export default {
    components: { BasicHeader, InputField },
    data() {
        return {   
            currentCls: null,         
            config: {
                wrap: true, 
                dateFormat: 'm-d-Y',
            },
            date: dayjs().format("MM/DD/YYYY"),
            trendOptions: [
                {label:"Hourly", value:"Hourly"},
                {label:"Daily", value: "Daily"}
            ],
            graphLoading: true,
            trend: "Hourly",            
            tagTypesUnique:[],
            countriesUnique: [],
            tagType: 'partner_beacon',
            country: "All",
            NO_OF_RETRY: 3,           
            startDate: dayjs().subtract(8, "day").format('MM/DD/YYYY'),
            endDate: dayjs().subtract(1, "day").format('MM/DD/YYYY'),
            tempTtObj: {},
            tempPdObj: {},
            tempRdObj: {},
            threshold_tagType: 1000,
            threshold_partnerBeacon: 1000,
            threshold_redirect: 1000,
            errMsg:'',
            dailyChartMsg: [],
            showCurrentHour: false

        };
    },
  
    beforeMount() {
        this.initiateClass();
        if(!this.currentCls)
            this.generateDashboard();

    },
    watch: {        
        currentCls() {
            this.generateDashboard();
        },
        trend() {
            this.generateDashboard();
        },
        date() {
            this.generateDashboard();
        },
        showCurrentHour(val) {
            if(this.country === "All" && Object.keys(this.tempObjT).length !== 0) //make sure objects are populated
                this.startDrawHourlyGraph("countChart",this.tempObjT, this.tempObjY, this.tempObjLWT, "All" );
            else
                this.startDrawHourlyGraph("countChart",this.dataHTodayCc, this.dataHYesterdayCc, this.dataHLastweekThisDayCc, this.country);
        },
        tagType() {
            if(this.country === "All" && Object.keys(this.tempObjT).length !== 0) //make sure objects are populated
                this.startDrawHourlyGraph("countChart",this.tempObjT, this.tempObjY, this.tempObjLWT, "All" );
            else
                this.startDrawHourlyGraph("countChart",this.dataHTodayCc, this.dataHYesterdayCc, this.dataHLastweekThisDayCc, this.country);
        },
        country(newCountry) {
            this.startDrawHourlyGraph("countChart",this.dataHTodayCc, this.dataHYesterdayCc, this.dataHLastweekThisDayCc, newCountry);
        },
        threshold_tagType() {
            this.startDrawDailyGraph("ttDaily", this.tempTtObj, this.dailyCountsLabels, this.threshold_tagType, "Tag Type");
        },
        threshold_partnerBeacon() {
            this.startDrawDailyGraph("pdDaily", this.tempPdObj, this.dailyCountsLabels, this.threshold_partnerBeacon, "Partner Domain");
        },
        threshold_redirect() {            
            this.startDrawDailyGraph("rdDaily", this.tempRdObj, this.dailyCountsLabels, this.threshold_redirect, "Redirect Domain");
        }

            
    },
    computed: {
     ...mapState({    
            cid: state => state.currentClient.cid,
            clientData: state => state.currentClient,         
            classItems: state => state.currentClient.classes.items||[]      
        }),
        thisDay() {
            return dayjs(this.date, 'MM-DD-YYYY').format('YYYYMMDD');
        },
        previousDay() {            
             return dayjs(this.date, 'MM-DD-YYYY').subtract(1, "day").format('YYYYMMDD');
        },
        lastweek_thisday() {
            return dayjs(this.date, 'MM-DD-YYYY').subtract(7, "day").format('YYYYMMDD');
        },
        fromDate() {
            return dayjs(this.startDate, 'MM-DD-YYYY').format('YYYYMMDD');   
        },
        toDate() {
            return dayjs(this.endDate, 'MM-DD-YYYY').format('YYYYMMDD');  
        }
    },
    methods: {   
        async generateDashboard() {
            this.graphLoading = true;
            let clsString = ''; 
            this.country = "All";          
            if(this.currentCls) {
                clsString = '/clss/' + this.currentCls;
            }

            if(this.trend == "Hourly") {  
                this.tempObjLWT= {};
                this.tempObjT= {};
                this.tempObjY= {};         
               
                this.UniqTtObj = {};
                this.UniqCcObj = {};
                this.dataDailyTTH = [];
                this.dataHLastweekThisDay = [];
                this.dataHToday = [];
                this.dataHYesterday = [];
                this.dataHLastweekThisDayCc =[];
                this.dataHTodayCc = [];
                this.dataHYesterdayCc = [];
                this.tagTypesUnique = [];
                this.countriesUnique = [];

                const dates = `${this.lastweek_thisday},${this.previousDay},${this.thisDay}`;
                let response;
                try {
                    response = await this.countsByDates(
                                clsString,
                                dates
                                )
                } catch(error) {
                    this.graphLoading = false;
                    let toastoptions = {
                        message: "Something went wrong while rendering hourly chart. Please contact support.",
                        type: "error"
                    }
                    this.$store.dispatch('addToast', toastoptions);
                    return;
                }  
                  
                this.graphLoading = false;
                if(!response.data || Object.keys(response.data).length === 0) {                    
                    this.errMsg = "No Data Found";
                } else {
                    let countsData = await response.data;                    
                
                    for (var hr in countsData) {                    
                        if (this.$store.state.environment !== "test" || hr.length > 8) {
                            switch (hr.substring(0, 8)) {
                            case this.lastweek_thisday:
                                this.transformData(hr.substr(-2), countsData[hr].tagType, this.tempObjLWT);
                                break;
                            case this.thisDay:
                                this.transformData(hr.substr(-2), countsData[hr].tagType, this.tempObjT);
                                break;
                            case this.previousDay:
                                this.transformData(hr.substr(-2), countsData[hr].tagType, this.tempObjY);
                                break;
                            default:                            
                                this.transformData(hr.substr(-2), countsData[hr].tagType, this.tempObjY);
                                break;
                            }
                        }                    
                    }

                    for (var k1 in this.tempObjT) {                    
                        this.UniqTtObj[k1] = 1;                    
                        this.dataHToday.push({
                            id: k1,
                            total: this.tempObjT[k1].total,
                            values: this.tempObjT[k1].values
                        });                    
                    }
                    for (var k2 in this.tempObjY) {                   
                        this.UniqTtObj[k2] = 1;                    
                        this.dataHYesterday.push({
                            id: k2,
                            total: this.tempObjY[k2].total,
                            values: this.tempObjY[k2].values
                        });                    
                    }
                    for (var k3 in this.tempObjLWT) {                    
                        this.UniqTtObj[k3] = 1;                    
                        this.dataHLastweekThisDay.push({
                            id: k3,
                            total: this.tempObjLWT[k3].total,
                            values: this.tempObjLWT[k3].values
                        });                    
                    } 

                    this.tagTypesUnique = [];
                    for (var key in this.UniqTtObj) {                        
                        this.tagTypesUnique.push(key);                        
                    }
                    
                    this.tagType = 'partner_beacon';
                    this.tagTypesUnique.sort();
                    if (this.tagTypesUnique.indexOf('partner_beacon') === -1) {
                        this.tagType = this.tagTypesUnique[0]
                    }
                    

                    this.startDrawHourlyGraph('countChart',this.tempObjT, this.tempObjY, this.tempObjLWT, this.country );

                } 

                this.getCounts(clsString, this.tempObjT, this.thisDay, this.NO_OF_RETRY);
                this.getCounts(clsString, this.tempObjY, this.previousDay, this.NO_OF_RETRY);
                this.getCounts(clsString, this.tempObjLWT, this.lastweek_thisday, this.NO_OF_RETRY);


            } else if(this.trend == "Daily") {
                this.dailyCountsLabels = [];
                const diffDays = this.dateDiffInDays(this.fromDate, this.toDate);
                if(diffDays > 95) {
                    this.errMsg = "Request Denied. Requested date range is more than 3 months.";
                    return;
                }

                let response;
                try {
                    response = await this.dailyCounts(
                                clsString,
                                this.fromDate,
                                this.toDate
                            )
                } catch(error) {
                    this.graphLoading = false;
                    let toastoptions = {
                        message: "Something went wrong while rendering daily chart. Please contact support.",
                        type: "error"
                    }
                    this.$store.dispatch('addToast', toastoptions);
                    return;
                }

                
                this.graphLoading = false;
                let dailyCountsData = await response.data;
                if (!dailyCountsData) {                    
                    return
                }
                this.tempTtObj = {};
                this.tempPdObj = {};
                this.tempRdObj = {};
                for (var day in dailyCountsData) {                    
                    this.dailyCountsLabels.push(dayjs(day).format('MMM-DD'));
                    this.transformData(day, dailyCountsData[day].tagType, this.tempTtObj);
                    this.transformData(day, dailyCountsData[day].partnerBeacon, this.tempPdObj);
                    this.transformData(day, dailyCountsData[day].redirect, this.tempRdObj);
                }

                this.startDrawDailyGraph('ttDaily', this.tempTtObj, this.dailyCountsLabels, this.threshold_tagType, "Tag Type");
                this.startDrawDailyGraph('pdDaily', this.tempPdObj, this.dailyCountsLabels, this.threshold_partnerBeacon, "Partner Domain");
                this.startDrawDailyGraph('rdDaily', this.tempRdObj, this.dailyCountsLabels, this.threshold_redirect, "Redirect Domain");
                
            }      

        },
        transformData(date, src, dst, extraSrc) {
            // Transform data in to a more d3 like data { id: id, values: [{}]}
            var counts = {};
            for (var c in src) {            
                var m = c.match(/^(([a-z]-[a-z0-9]*)|NA):(.*)$/);
                var c2 = m ? (m[2] ? (m[3] + ' (' + m[2] + ')') : m[3]) : c;
                if (!extraSrc) {
                    counts[c] = src[c]
                } else if (extraSrc[c]) {
                    counts = JSON.parse(JSON.stringify(extraSrc))
                    counts[c]["All"] = src[c];
                } else {
                    counts[c] = {};
                    counts[c]["All"] = src[c];
                }
                if (c2 in dst) {
                    dst[c2].values.push({
                        date: date,
                        count: counts[c]
                    });
                    dst[c2].total += src[c];
                } else {
                    dst[c2] = {
                        total: src[c],
                        values: [{
                        date: date,
                        count: counts[c]
                        }]
                    };
                }
            
            }
        },

        async getCounts(clsString, tempObjDef, day, retryCounts) {
            let self = this;
            await this.countsByDate(
                            clsString,
                            day
            ).then(function (response){
                if (response.status == -1) {
                    if (retryCounts > 0) {
                        retryCounts--
                        self.getCounts(clsString, tempObjDef, day, retryCounts)
                    }
                } else {
                    var tempObj = {};
                    if (!response.data) {
                        self.graphLoading = false;
                        return
                    }
                    var data = response.data;
                    for (var date in data) {                        
                        if (self.$store.state.environment !== "test" || date.length > 8) {
                            switch (date.substring(0, 8)) {
                                case day:
                                    self.transformData(date.substr(-2), data[date].tagType, tempObj, data[date].tagType_countryCode);
                                    break;
                                default:
                                    if (self.$store.state.environment === "test") {
                                        self.transformData(date.substr(-2), data[date].tagType, tempObjDef, data[date].tagType_countryCode);
                                    }
                                    break;
                            }
                        }

                        for (let cc in data[date].countryCode) {
                            if (cc.length < 15) {
                                self.UniqCcObj[cc] = 1;
                            }
                        }                        
                    }

                    switch (day) {
                        case self.thisDay:
                            self.dataHTodayCc = tempObj;
                            for (const k1 in tempObj) {                                
                                self.UniqTtObj[k1] = 1;          
                            }                  
                            break;
                        case self.previousDay:
                            self.dataHYesterdayCc = tempObj;
                            for (const k1 in tempObj) {                                
                                self.UniqTtObj[k1] = 1;     
                            }
                            break;
                        case self.lastweek_thisday:
                            self.dataHLastweekThisDayCc = tempObj;
                            for (var k1 in tempObj) {                                
                                self.UniqTtObj[k1] = 1;             
                            }
                            break;
                    }
                    
                    self.tagTypesUnique = [];
                    for (const key in self.UniqTtObj) {                        
                        self.tagTypesUnique.push(key);                        
                    }
                    self.countriesUnique=[];

                    for (const key in self.UniqCcObj) {                    
                        self.countriesUnique.push(key);
                    }
                    
                    self.countriesUnique.sort();
                    self.countriesUnique.unshift("All");
                    

                }
            })
        },
        

        startDrawHourlyGraph(elementId, thisDayData, previousDayData, prevWeekThisDayData, country ) {
            if(!thisDayData || !previousDayData ||  !prevWeekThisDayData)
                return;

            const countChart = document.getElementById(elementId);
            const chart = Chart.getChart(elementId);
            let currentDayData = JSON.parse(JSON.stringify(thisDayData))
            if(chart) {
                chart.destroy();
            }

            //remove most recent hour to not skew the graph (deleting last key in object)
            if(!this.showCurrentHour) {
                for (const key in currentDayData) {                    
                    currentDayData[key].values.pop();
                }
            }   
            
            const currentDayDataSetLength = currentDayData[this.tagType]?currentDayData[this.tagType].values.length:0;

            const labels = ["00","01","02","03","04","05","06", "07", "08", "09", "10", "11","12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
            const gData = {
                labels: labels,
                datasets: [{
                    label: 'Previous Week This Day',
                    backgroundColor: 'rgb(255, 99, 132)', 
                    borderColor: 'rgb(255, 99, 132)',
                    data: prevWeekThisDayData[this.tagType]? prevWeekThisDayData[this.tagType].values.map((obj) => { 
                        return {'x':obj.date, 'y':obj.count[country] || obj.count}
                    }) : 0,
                    pointStyle: 'circle',
                    pointRadius: 3
                    
                },{
                    label: 'Previous Day',
                    backgroundColor: 'rgb(12, 196, 2)',
                    borderColor: 'rgb(12, 196, 2)',
                    data: previousDayData[this.tagType]? previousDayData[this.tagType].values.map((obj) => { 
                        return {'x':obj.date, 'y':obj.count[country] ||obj.count}
                    }): 0,
                    pointStyle: 'circle',
                    pointRadius: 3
                },{
                    label: 'This Day',
                    backgroundColor: 'rgb(1, 94, 255)',
                    borderColor: 'rgb(1, 94, 255)',
                    data: currentDayData[this.tagType]? currentDayData[this.tagType].values.map((obj) => { 
                        return {'x':obj.date, 'y':obj.count[country] ||obj.count}
                    }): 0,
                    segment: { 
                        borderDash: ctx =>  ((currentDayDataSetLength - 1 === ctx.p1DataIndex) && this.showCurrentHour)?[3,3]: []  
                    },
                    animations: {
                        radius: {
                            duration: 1000,
                            easing: 'linear',                            
                            to: 3,
                            loop: true
                        }
                    }
                }                   

                ]
            };

            const options= {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        onHover: function (event, legendItem, legend) {     
                            legend.chart.data.datasets.forEach((dataset, index, datasets) => {
                                datasets[index].pointRadius = index === legendItem.datasetIndex ? 3: 0;
                                datasets[index].borderWidth = index === legendItem.datasetIndex ? 5: 2;                               

                            });
                            legend.chart.update();                            
                        },
                        onLeave: function(evt, legendItem, legend) {
                            legend.chart.data.datasets.forEach((dataset, index, datasets) => {
                                datasets[index].borderWidth = 3;
                                datasets[index].pointRadius = 3;

                            });
                            legend.chart.update();
                        }
                    },
                    
                    tooltip: {
                        usePointStyle: true,
                    }                    
                },
                elements: {
                    point: {
                        radius: (ctx) => (((ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.index)&& this.showCurrentHour) ? 6 : 3)
                    },                    
                },               
              
            }

            const config = {
                type: 'line',
                data: gData,
                options: options
            }

            if (countChart) {
                new Chart(countChart, config);
            }            

        },

        startDrawDailyGraph(elementId, countData, labels, threshold, name) {
            const self = this;
            this.dailyChartMsg[elementId] = null;
            let dailyCountData = countData;
            if(threshold !== 0) {
                dailyCountData = Object.fromEntries(Object.entries(countData).
                            filter(([key, val]) => {
                                return val.total > threshold; 
                            }));

            }
            
            const dailyChart = document.getElementById(elementId);
            const chart = Chart.getChart(elementId);

            const legendContainer = document.getElementById(`${elementId}-legend-container`);
            while (legendContainer.hasChildNodes()) {
                legendContainer.removeChild(legendContainer.firstChild);
            }
            if(chart) {
                chart.destroy();
            }            

            let datasets = [];
            const colors = this.poolColors(Object.keys(dailyCountData).length);
            let i =0;
            for (let prop in dailyCountData) {
                const color = colors[i];
                datasets.push({
                    label: prop,
                    backgroundColor: color,
                    borderColor: color,
                    data: dailyCountData[prop].values.map((obj) => {
                        return {'x': dayjs(obj.date).format('MMM-DD'), 'y': obj.count}
                    }),
                    pointStyle: 'circle',
                    pointRadius: 3
                });
                i++;
            }            

            const gData = {
                labels: labels,
                datasets: datasets
            };

            const options= {
                maintainAspectRatio: false,
                responsive: true,
                layout: {
                    padding: {
                        right: 30
                    }
                },
                parsing:false,
                normalized: true,
                animation: false,                
                plugins: {
                    htmlLegend: {
                        // ID of the container to put the legend in
                        containerID: `${elementId}-legend-container`
                    },                   
                    legend: {
                        display: false,                        
                    },                   
                    tooltip: {
                        usePointStyle: true,
                    }                   
                }
            }

            const config = {
                type: 'line',
                data: gData,
                options: options,
                plugins: [htmlLegendPlugin],
                backgroundColor: colors
            }            
           

            if (dailyChart) {
                new Chart(dailyChart, config);
            }                     

        },

        dateDiffInDays(fromDate, toDate) {
            let hours = dayjs(toDate).diff(dayjs(fromDate), 'hours');
            const diffDays = Math.floor(hours / 24);
            return diffDays;
        },

        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },   
        
        poolColors(a) {
            var pool = [];
            for(let i = 0; i < a; i++) {
                pool.push(this.getRandomColor());
            }
            return pool;

        },

        async countsByDates(clsString, dates) {
            return axios.get(
                `${this.$store.state.countsUrl}/v1/cids/${this.cid}${clsString}/dates/${dates}?hourly=true&skipCountry=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
        },

        async countsByDate(clsString, date) {
            return axios.get(
                `${this.$store.state.countsUrl}/v1/cids/${this.cid}${clsString}/dates/${date}?hourly=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                    timeout: 60000
                }
            ).then((response) => response )
            .catch((error) => {
                error.response.status = -1;
                return error.response; 
            });
        },

        async dailyCounts(clsString, fromDate, toDate) {
            return axios.get(
                `${this.$store.state.countsUrl}/v1/cids/${this.cid}${clsString}/from/${fromDate}/00/until/${toDate}/23?daily=true&skipCountry=true`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
        },
        
        initiateClass() { 
            if(this.$store.state.aut.currentClass && this.$store.state.aut.currentClass[this.cid])
                this.currentCls = this.$store.state.aut.currentClass[this.cid];   
        }   
        
    },
}
</script>

<style scoped>
#tag_dashboard .row {
    margin-bottom: 1.5rem;
}
.current-hr-input-div .form-check-input:not(:checked) {
    background-color: #fff
}
.hide{
    display:none;
}
.chart-view{ 
  height: 40vh;  
}
div[id$="-legend-container"]{
    height: 40vh;  
    overflow: scroll;
}

</style>