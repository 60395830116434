<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddEncryptedURL()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add Encrypted URL
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="fields" v-model="encryptedUrl" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="fields" v-model="encryptedUrl" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/encryptedUrlFields"
export default {
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            encryptedUrl: {},
            fields: Fields
        }
    },
    name: "AddEncryptedURL",
    methods: {
        ...mapActions('admin', {
            createEncryptedUrl: 'createEncryptedUrl'
        }),
        async onAddEncryptedURL() {
            try {
                await this.createEncryptedUrl(this.encryptedUrl)
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }
            this.$store.dispatch('hideModal')
            this.$store.dispatch('admin/fetchEncryptedUrlList')
        }
    }
};
</script>

<style>
</style>