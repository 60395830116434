const Fields = [
    {
        name: "name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Name Field is required" }
        ]
    },
    {
        name: "domain",
        label: "Domain",
        type: "text",
        editable: false
    },
    {
        name: "path",
        label: "Path",
        type: "text",
        editable: true,
        validation: [
            { type: "regex", pattern: "^/[a-zA-Z0-9]",errMsg: 'path may start with / followed by alphanumeric characters' }
        ]
    },
];

export default Fields;

