<template>
  <basic-header pretitle="aqfer Graph Manager" title="aGM Dashboards" />
  <div class="container-fluid">
    <dashboard-tabs inFeature="AGM" />
  </div>
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue'
import DashboardTabs from '../../components/DashboardTabs.vue'
export default {
  components: { BasicHeader, DashboardTabs },

}
</script>

<style></style>