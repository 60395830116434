<template>
    <div class="container-fluid">
        <nav-header :navItems="[
            {
                name: 'Configuration',
                link: {
                    name: 'aIO Handler Configuration',
                    params: {
                        sandboxId: `${selectedSandbox}`,
                        jobId: `${$route.params.jobId}`,
                    },
                },
            },
            {
                name: 'Requests',
                link: {
                    name: 'aIO Handler Requests',
                    params: {
                        sandboxId: `${selectedSandbox}`,
                        jobId: `${$route.params.jobId}`,
                    },
                },
            },]" pretitle="Handler" :breadcrumbTitle="true">
            <template v-slot:breadcrumb-title>
                <nav aria-label="breadcrumb" class="header-title">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a @click="closeClick" href="#" class="h1">Handlers</a></li>
                        <li class="breadcrumb-item active h1" aria-current="page">{{ $route.params.jobId }}</li>
                    </ol>
                </nav>
            </template>

        </nav-header>
        <div>
            <router-view v-if="this.$store.state.aio.currentHandlerJob" :job="this.$store.state.aio.currentHandlerJob" :editMode="editMode"></router-view>
            <div v-else class="text-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import NavHeader from "../../components/NavHeader.vue";
import { mapActions } from "vuex";
export default {
    components: { NavHeader },
    data() {
        return {
            selectedSandbox: this.$route.params.sandboxId,
            editMode: false,
        };
    },
    methods: {
        ...mapActions({
            readSandboxJob: "sandbox/readSandboxJob",
            deleteSandboxJob: "sandbox/deleteSandboxJob",
            showModal: "showModal",
        }),
        closeClick() {
            this.$router.push({
                name: "aIO Handler Sandbox",
                params: {
                    sandboxId: `${this.$route.params.sandboxId}`,
                },
            });
        },
    },
    beforeMount() {
        this.$store.dispatch("aio/setCurrentHandlerJobId", this.$route.params.jobId);
    },

}
</script>

<style></style>