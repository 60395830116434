const Fields = [
    {
        name: "url",
        label: "URL",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "URL is required" }
        ]
    },
    {
        name: "status",
        label: "Status",
        type: "select",
        editable: true,
        options:[
            { label: "ACTIVE ", value: "ACTIVE" },
            { label: "INACTIVE ", value: "INACTIVE" }
        ],
        validation: [
            { type: "required", errMsg: "Status is required" }
        ]
    },
    {
        name: "tag",
        label: "Tag",
        type: "text",
        editable: true
    },
    {
        name: "metadata",
        label: "Metadata",
        type: "object",
        editable: true
    },
    {
        name: "created_at",
        label: "Created At",
        type: "text",
        editable: false,
        visible: false,
        format:"datetime"
    },
    {
        name: "created_by",
        label: "Created By",
        type: "text",
        editable: false,
        visible: false
    },
    {
        name: "updated_at",
        label: "Updated At",
        type: "text",
        editable: false,
        visible: false,
        format:"datetime"
    },
    {
        name: "updated_by",
        label: "Updated By",
        type: "text",
        editable: false,
        visible: false
    }
];

export default Fields;