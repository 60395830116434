const Fields = [
    {
        name: "Name",
        label: "Name",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Name is required" },
            {type: "regex", pattern:"^[a-zA-Z0-9_]+$",
            errMsg:"Query name may contain only alphabets or numbers"}
        ]
    },
    {
        name: "Description",
        label: "Description",
        type: "text",
        editable: true,
        validation: [
            { type: "required", errMsg: "Description is required" }
        ]
        
    },{
        name: "QueryString",
        label: "Query String",
        type: "sql",
        editable: true,
        validation: [
        { type: "required", errMsg: "QueryString is required" }
        ]
    },
    {
        name: "Internal",
        label: "Internal",
        type: "checkbox",
        editable: true,
        visible:false,
    }
];
 
export  default Fields;