<template>
     <div v-if="error" class="text-danger">
                    {{error}}
    </div>
    <Folder 
        :tableId="$route.params.id"
        :data="filesList" baseUrl='' :loading="loading" name='File Browser Folder' identifier='folderName' icon='folder' /> 
</template>
<script>
import Folder from "../../components/Folder.vue";
import { mapActions } from "vuex";
export default {
    components: { Folder },
    name: "FolderView",
    data() {
        return {
            filesList: [],
            loading: false,
            error:null
        };
    },
    computed:{
        folderPath(){
           return this.$store.state.amdp.folderPath
        }
    },
    watch: {
        '$route.params.id':{ 
            handler: function (to, from) {
                let path =this.folderPath
                let refresh =false;
               if ((( !from && !to ) ||(from !==to))) {  
                    if (path == "" || path.endsWith("@")){
                        let credential=this.$store.state.amdp.selectedCredential
                        //setting credential in path
                        path ="s3://"+(credential?this.$store.state.currentClient.cid+'.'+credential+'@':'')+this.$route.params.bucket+"/"+(this.$route.params.id?this.$route.params.id+"/":"")
                        refresh= true
                    }
                    else if(this.$route.params.id){
                        path =path+this.$route.params.id +"/"
                        refresh= true
                    }
                    if(refresh){
                        this.$store.dispatch('amdp/setFolderPath',path.replace("slash/","/").replace("///","//")); 
                        this.filesList=[]
                        this.fetchFileBrowserList(this.folderPath)
                    }
                    
                }
            },
            deep: true,
            immediate: true
        },
        '$route.query':{
            handler: function (to, from) {
                if(to.refresh){
                    this.filesList=[]
                    this.fetchFileBrowserList(this.folderPath)
                }
            },
            deep: true,
            immediate: true
        },
        filesList() {
            this.fileList = this.filesList.map((file) => {
                file['folderName'] = file.name.replace("/", '')
            });
        } 

    },
    methods: {
        ...mapActions({
            getFileBrowserList: "amdp/getFileBrowserList",
        }),
        async fetchFileBrowserList(folderPath) {
            this.error =null
            this.filesList=[]
            this.loading = true 
            let id =this.$route.params.id?this.$route.params.id:this.$route.params.bucket
            let data = this.$route.params.data? JSON.parse(this.$route.params.data) :this.$route.params.data;
            if( this.$store.state.datatableCache[id]&& !this.$route.query.refresh && (!data || data.is_dir=="true")){
                let cache=this.$store.state.datatableCache[id]
                if(cache["tableData"]){
                    this.filesList=cache["tableData"]
                }
                this.loading = false
            }else if ((folderPath && folderPath!="s3://")&& (!data || data.is_dir=="true")) {
                let response;
                try {
                    response = await this.getFileBrowserList({folderName: folderPath, sandboxName:this.$route.params.sandboxId });
                } catch (error) {
                    this.filesList=[]
                    this.loading = false
                    if (error.response.data.error) this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
                this.filesList = await response.data? response.data :[];
                this.loading=false
            } else if(folderPath && folderPath!="s3://"){
                this.$router.push({ 
                    name: 'File Browser View',
                    path: this.$route.params.id,
                    params: { 
                        id: this.$route.params.id,
                        data: this.$route.params.data
                    } 
                });

            }

        }
        
    }
};
</script>

<style></style>