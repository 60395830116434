<template>
    <basic-header
        pretitle="Administration"
        title="Clients"
        buttonText="Add Client"
        @btnClick="showModal({ component: 'addClient' })"
    />
    <div class="container-fluid">
        <div class="card">
            <datatable
                tableId="clientsTable"
                :columns="columns"
                uniqueField="cid"
                :data="$store.state.admin.clientList"
                :deletable="true"
                @deleteRows="showDeleteClients"
                :compact="true"
                :select="true"
                :selectCheckboxes="true"
                :detailColumn="{baseUrl:'clients/', identifier:'cid'}"
            />
        </div>
    </div>
</template>

<script>
import BasicHeader from "../../components/BasicHeader.vue";
import Datatable from "../../components/Datatable.vue";
import { mapActions } from "vuex";

export default {
    name: "Clients",
    components: { BasicHeader, Datatable },
    data() {
        return {
            columns: [
                { data: "cid" },
                { data: "name" },
                { data: "enabled" },
                { data: "version" },
            ],
        };
    },
    beforeMount() {
        this.$store.dispatch("admin/fetchClientList");
    },
    methods: {
        showDeleteClients(rows) {
            this.showModal({ component: "deleteClients", data: rows });
        },
        ...mapActions({
            showModal: "showModal",
        }),
    },
};
</script>

<style>
</style>