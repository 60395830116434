<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddClass()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                Add Class
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="fields" v-model="clientClass" :refreshOn="modalShown"/>
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="fields" v-model="clientClass" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/autClassCreateFields"
export default {
    components: { formFields, submitButton },
    props: {       
        modalShown: Boolean
    },
    data(){
        return {
            error: "",
            clientClass: {},
            fields: Fields
        }
    },
    name: "AddClass",
    beforeMount() {
        let self = this;
        if (!self.$store.getters.userRoles.isAqferAdmin) {
            this.fields = this.fields.map((field) => {
                if (field.name == "log" || field.name == "uaParseCountryList" || field.name == "skipFileTypes") {
                    field.visible=false
                }
                return field
            });
        }
    },
    methods: {
        ...mapActions('aut', {
            createClass: 'createAutClientClass'
        }),
        async onAddClass() {
            try {
                await this.createClass(this.clientClass)
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }

            this.$store.dispatch('hideModal');            
            this.$store.dispatch('setCurrentClient',this.$store.state.currentClient.cid);
            this.$store.dispatch("aut/fetchAutClassData", this.clientClass.cls);
        }
    }
};
</script>

<style>
</style>