<template>
    <div class="modal-card card">
        <form @submit.prevent="onEditProductEntitlement()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Product Entitlement
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="card-body">
                <InputField label="Product" type="text" v-model="product.sku" :editable="false" />

                <InputField label="Entitlement Name" type="select" v-model="entitlement" :editable="true"
                    :options="entitlementOptions" :validate="[{ type: 'required', errMsg: 'select entitlement' }]" />

                <InputField label="Quantity" type="number" v-model="quantity" :editable="true" :visible="fieldVisble"
                    :validate="[{ type: 'required', errMsg: 'quantity is required' }]" />
                <InputField label="Effective Date" type="calendar" v-model="effective_date" :editable="true" 
                    :inputConfig="config" :validate="[{ type: 'required', errMsg: 'effective date is required' }]"/>
            </div>
            <div class="card-footer">
                <div class="text-end">
                    <button class="btn btn-primary" :disabled="btnDisable" type="submit">Update</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import InputField from "../InputField.vue";
import { mapActions } from 'vuex';
export default {
    components: { InputField },
    name: "EditProductEntitlement",
    data() {
        return {
            entitlement: null,
            entitlementOptions: [],
            entitlementList:[],
            quantity: 1,
            snapshot:{},
            effective_date:"",
            config: {
                wrap: true, 
                dateFormat: 'Y-m-d',
            },
        }
    },
    props: {
        modalData: {
            type: [Object, Array, String],
        },
    },
    computed: {
        product() {
            return this.modalData.product ? this.modalData.product : { name: '' }
        },
        btnDisable(){
            return !(this.entitlement && this.quantity>0 && this.effective_date!="")
        },
        fieldVisble(){
            return this.entitlement ? true: false
        }
    },
    watch:{
        entitlement(newVal){
            let index =this.entitlementList.findIndex((item)=>item.entitlement_id === newVal)
            if(index !==-1){
                this.snapshot =this.entitlementList[index]
                this.quantity= this.entitlementList[index].quantity;
                this.effective_date = this.entitlementList[index].effective_date;
            }
        }
    },
    beforeMount() {
        this.entitlementList = this.modalData.list;
        if(this.modalData.list){
            this.entitlementOptions = this.modalData.list.map((item) => {
                    return { label: item.name, value: item.entitlement_id }
            })
        }
    },
    methods: {
        ...mapActions('admin', {
            updateProductEntitlement: 'updateProductEntitlement'
        }),
        async onEditProductEntitlement() {
            let toastoptions;
            let data = {
                id: this.snapshot.id,
                product_id:this.product.product_id,
                entitlement_id: this.entitlement,
                effective_date: this.effective_date,
                quantity: this.quantity
            }
            try {
                await this.updateProductEntitlement(data)
                toastoptions = {
                    message: "Product Entitlement updated successfully.",
                    type: "success",
                };
            } catch (error) {
                toastoptions = {
                    message: "Something went wrong when adding the tag. Please contact support.",
                    type: "error"
                }
                return
            }
            this.$store.dispatch('addToast', toastoptions);
            this.$store.dispatch('hideModal')
            this.$store.dispatch('admin/getProductEntitlementList',"product="+this.product.product_id);
        }
    }
};
</script>

<style></style>