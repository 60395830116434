<template>
   <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li v-for="item,index in breadcrumbItems" :key="index" class="breadcrumb-item" >
                <template v-if="index < breadcrumbItems.length-1">
                <a  @click="routeTo(item.route)" href="#">{{ item.name }}</a>
            </template>
            <template v-else>
                {{ item.name }}
            </template>
            </li>
        </ol>
    </nav>
</template>
<script>
export default{
    name: "Breadcrumbs",
    props:{
        breadcrumbItems:{
            type: Array,
            default: function(){
                return []
            }
        }
    },
    methods:{
        routeTo(route){
            //Removing other breadcrumbs based on clicked item
            let breadcrumbs=this.$store.state.breadcrumbs;
            let index = breadcrumbs.findIndex(item=> (item.route.path == route.path && item.route.params == route.params))
            //For getting breadcrumb path
            let newBreadCrumb= breadcrumbs.slice(0, index+1);
            this.$store.dispatch("setBreadcrumbs",newBreadCrumb);
            //for setting folder path - index is less than 1 so path value will add again inn folderview page
             newBreadCrumb= breadcrumbs.slice(0, index);
            newBreadCrumb=newBreadCrumb.map(function(item,index){
                    return item["name"];
            })
            newBreadCrumb=newBreadCrumb.join("/")+(newBreadCrumb.length>0?"/":"")
            let credential=this.$store.state.amdp.selectedCredential
            //setting credential in path
            let path ="s3://"+(credential?this.$store.state.currentClient.cid+'.'+credential+'@':'')
            this.$store.dispatch('amdp/setFolderPath',path+newBreadCrumb)
            this.$router.push(route);
        }
    },
}
</script>
<style scoped>
.breadcrumb-item::before {
    align-self: center;
    color: #d2ddec;
    font-family: Feather;
    font-size: 1.0rem;
}
.breadcrumb-item a {
    color: #2c7be5 !important;
}
.breadcrumb-item {
    color: #95AAC9 !important;
}
.breadcrumb-item a:hover {
    color: #1657af !important;
}
</style>