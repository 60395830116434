<template>
	<div class="container-fluid">
		<nav-header
			:navItems="[
				{
					name: 'Configuration',
					link: {
						name: 'IDR Job Configuration',
						params: {
							sandboxId: `${selectedSandbox}`,
							jobId: `${$route.params.jobId}`,
						},
					},
				}				
			]"
			pretitle="IDR Job"
			:breadcrumbTitle="true"
			@nav-close-clicked="closeNav"
		>
			<template v-slot:breadcrumb-title>
				<nav aria-label="breadcrumb" class="header-title">
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<a @click="closeClick" href="#" class="h1">{{
								selectedSandbox
							}}</a>
						</li>
						<li
							class="breadcrumb-item active h1"
							aria-current="page"
						>
							{{ $route.params.jobId }}
						</li>
					</ol>
				</nav>
			</template>
			<template v-slot:header-button-content>
				<div class="custom-btn-group">
					<span v-if="editMode">
						<button
							@click.prevent="onSaveClick()"
							class="btn btn-success"
							:disabled="isButtonDisabled"
						>
							<i
								v-if="isButtonDisabled"
								class="fa fa-circle-exclamation"
								title="Please fix lint errors"
								data-bs-toggle="tooltip"
								data-bs-placement="left"
							></i>
							Save
						</button>
						<button
							@click.prevent="onSaveAndRunClick()"
							class="btn btn-success"
							:disabled="isButtonDisabled"
						>
							<i
								v-if="isButtonDisabled"
								class="fa fa-circle-exclamation"
								title="Please fix lint errors"
								data-bs-toggle="tooltip"
								data-bs-placement="left"
							></i>
							Save & Run
						</button>
						<button
							@click.prevent="onDeleteClick()"
							class="btn btn-danger"
						>
							Delete
						</button>
						<button
							@click.prevent="onCancelClick()"
							class="btn btn-secondary"
						>
							Cancel
						</button>
					</span>

					<span v-else-if="!editMode && !hideEditBtn">
						<button
							type="button"
							@click.prevent="onEditClick()"
							class="btn btn-primary"
						>
							Edit
						</button>
					</span>

					<div
						class="btn-group"
						role="group"
						aria-label="Button group with nested dropdown"
					>
						<span class="d-inline-block" tabindex="0">
							<button
								v-if="!editMode && !hideRunJobBtn"
								@click="btnClick"
								class="btn btn-primary w-100"
								:disabled="isButtonDisabled"
							>
								<div
									v-if="runClicked"
									class="spinner-border spinner-border-sm"
									role="status"
								>
									<span class="visually-hidden"
										>Loading...</span
									>
								</div>
								<div v-else>
									<slot></slot>
									<i
										v-if="isButtonDisabled"
										class="fa fa-circle-exclamation"
										title="Please fix lint errors"
										data-bs-toggle="tooltip"
										data-bs-placement="right"
									></i>
									Run Job
								</div>
							</button>
						</span>
						<button
							v-if="
								lastRunId || this.lastRunDetails[this.jobName]
							"
							type="button"
							@click="getLastStatus"
							class="btn btn-outline-primary w-25"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							title="Get Last Run Status"
						>
							<i
								class="fa fa-info-circle fa-lg"
								aria-hidden="true"
							></i>
						</button>
					</div>

					<button
						v-if="showCancelJobBtn"
						class="btn btn-secondary"
						@click="setCancelJob"
					>
						<div
							v-if="runClicked"
							class="spinner-border spinner-border-sm"
							role="status"
						>
							<span class="visually-hidden">Loading...</span>
						</div>
						<div v-else><slot></slot>Cancel Job</div>
					</button>
					&nbsp;
					<button @click="closeClick" class="btn btn-secondary lift">
						<i class="fa fa-arrow-left me-1"></i> Back to job list
					</button>
				</div>
			</template>
		</nav-header>

		<div>
			<router-view
				v-if="job"
				:editMode="editMode"
				:isSandbox="true"
				:job="job"
				:runJobTrigger="runJobTrigger"
				:saveJobTrigger="saveJobTrigger"
				:saveAndJobTrigger="saveAndJobTrigger"
				:cancelJobTrigger="cancelJobTrigger"
				:snapshot="snapshot"
				v-on:setRunClick="setRunClick"
				v-on:onLintFailure="setRunJobBtnState"
				v-on:triggerEditMode="setEditMode"
				v-on:hideEdit="setHideEdit"
				v-on:showCancelJob="setShowCancelJob"
				v-on:setSnapshot="setSnapshot"
			></router-view>
		</div>
	</div>
</template>

<script>
	import NavHeader from "../../components/NavHeader.vue";
	import { mapState, mapActions } from "vuex";
	export default {
		name: "SandboxJobDetail",
		components: { NavHeader },
		data() {
			return {
				runJobTrigger: false,
				saveJobTrigger: false,
				cancelJobTrigger: false,
				saveAndJobTrigger: false,
				job: null,
				jobName: this.$route.params.jobId,
				selectedSandbox: this.$route.params.sandboxId,
				snapshot: null,
				error: "",
				successMessage: "",
				runClicked: false,
				isButtonDisabled: false,
				editMode: false,
				cancelJob: false,
				hideEditBtn: false,
				hideRunJobBtn: false,
				showCancelJobBtn: false,
				showSaveConfigBtn: false,
				sandboxJobs: this.$store.state.sandbox.sandboxJobs,
				navigate: this.$router,
				lastRunId: null,
			};
		},
		computed: {
			...mapState({
				latestSuccessfulJobRun: (state) =>
					state.sandbox.latestSuccessfulJobRun,
				lastRunDetails: (state) => state.agm.jobLastRunDetails,
			}),
		},
		beforeMount() {
			this.fetchSandboxJob();
		},
		mounted() {
			this.getLastDeployId();
		},
		methods: {
			...mapActions({
				readSandboxJob: "sandbox/readSandboxJob",
				deleteSandboxJob: "sandbox/deleteSandboxJob",
				fetchSandboxJobRuns: "sandbox/fetchSandboxJobRuns",
				showModal: "showModal",
			}),
			async fetchSandboxJob() {
				let self = this;
				let response;
				try {
					response = await this.readSandboxJob(
						this.$route.params.jobId
					);
					this.job = await response.data;
					this.snapshot = JSON.parse(JSON.stringify(this.job)); // deep clone
					//self.$store.dispatch("sandbox/fetchSandboxJobRuns", {jobName: self.job.name, limit:this.$store.state.sandbox.executionsListLimit});
				} catch (error) {
					if (error.response.data.error)
						this.error = error.response.data.error;
					else this.error = error.message;
				}
			},

			setEditMode(mode) {
				this.editMode = mode;
				this.successMessage = null;
				this.error = null;
			},
			redirect() {
				this.navigate.push({
					name: "IDR",
				});
			},
			onDeleteClick() {
				const self = this;
				this.showModal({
					component: "confirm",
					data: {
						title: "Delete Sandbox",
						message: `Are you sure you want to delete ${this.job.name}?`,
						async confirm() {
							try {
								const response = await self.deleteSandboxJob(
									self.job.name
								);
								self.$store.dispatch("hideModal");
								self.$router.push({
									name: "IDR",
									params: {
										sandboxId: `${self.$route.params.sandboxId}`,
									},
								});
								return response;
							} catch (error) {
								if (error.response && error.response.data.error)
									self.error = error.response.data.error;
								else self.error = error.message;
								return;
							}
						},
					},
				});
			},
			async getLastDeployId() {
				this.lastRunId = this.lastRunDetails[`${this.jobName}-${this.selectedSandbox}`]
					? this.lastRunDetails[`${this.jobName}-${this.selectedSandbox}`].id
					: null;
				if (!this.lastRunId) {
					try {
						let response = await this.fetchSandboxJobRuns({
							jobName: this.jobName,
							limit: 1,
						});
						this.lastRunId =
							response.data && response.data.length
								? response.data[0].id
								: null;
					} catch (error) {
						this.lastRunId = null;
					}
				}
				return this.lastRunId;
			},
			async getLastStatus() {
				const lastRunId = await this.getLastDeployId();
				this.showModal({
					component: "ShowHandlerExecution",
					data: {
						jobName: this.jobName,
						sandboxId: this.selectedSandbox,
						executionId: lastRunId,
					},
				});
			},
			onEditClick() {
				this.setEditMode(true);
			},
			onCancelClick() {
				this.job = { ...this.snapshot };
				this.setEditMode(false);
			},
			btnClick() {
				this.runClicked = true;
				this.runJobTrigger = !this.runJobTrigger;
			},
			copyCurlClick() {
				this.copyCurl = !this.copyCurl;
			},
			onSaveClick() {
				this.successMessage = null;
				this.error = null;
				this.saveJobTrigger = !this.saveJobTrigger;
			},
			onSaveAndRunClick() {
				this.saveAndJobTrigger = !this.saveAndJobTrigger;
			},
			setRunJobBtnState(btnState) {
				this.isButtonDisabled = btnState;
			},
			setRunClick(runClick) {
				this.runClicked = runClick;
			},

			setCancelJob() {
				this.cancelJobTrigger = !this.cancelJobTrigger;
			},
			setHideEdit(hideEdit) {
				this.hideEditBtn = hideEdit;
			},
			setHideRunJob(hideRunJob) {
				this.hideRunJobBtn = hideRunJob;
			},
			setShowCancelJob(showCancelJob) {
				this.showCancelJobBtn = showCancelJob;
			},
			setSnapshot(job) {
				this.snapshot = job;
				this.onCancelClick();
				this.fetchSandboxJob();
			},
			closeClick() {
				this.$router.push({
					name: "IDR",
					params: {
						sandboxId: `${this.$route.params.sandboxId}`,
					},
				});
			},
			closeNav(navName) {
				this.$router.push({
					name: "Sandbox Job Run History",
					params: {
						sandboxId: `${this.$route.params.sandboxId}`,
						jobId: `${this.$route.params.jobId}`,
					},
				});
			},
		},
	};
</script>

<style scoped>
	.custom-btn-group {
		display: flex;
		align-items: center;
	}
	.btn {
		margin-right: 12px;
	}
</style>
