const Fields = [
    {
        name: "tenant_id",
        label: "Tenant Id",
        type: ["const","input"],
        optional: true,
        editable: true        
    },
    {
        name: "extract",
        label: "Extract",
        type: "array",
        editable: true,
        arrayItems: [
            {
                name: "entity_type",
                label: "Entity Type",
                type: ["const","input"],
                editable: true,
            },
            {
                name: "entity_domain",
                label: "Entity Domain",
                type: ["const","input","compound"],
                editable: true,
            },{
                name: "entity_id",
                label: "Entity ID",
                type: ["const","input"],
                invalid_values: ["None", 'NA', ''],
                editable: true,
                autOptional:true,
                autHide:true
            },{
                name: "operation",
                label: "Operation",
                type: ["const","input"],
                editable: true,
                autOptional:true
            },{
                name: "neighbors",
                label: "Neighbors",
                type: "array",
                editable: true,
                arrayItems:[
                    {
                        name: "neighbor_type",
                        label: "Neighbor Type",
                        type: ["const","input"],
                        editable: true,
                    },
                    {
                        name: "neighbor_domain",
                        label: "Neighbor Domain",
                        type: ["const","input","compound"],
                        editable: true,
                    },{
                        name: "neighbor_id",
                        label: "Neighbor ID",
                        type: ["const","input"],
                        editable: true,
                        autOptional:true,
                        autHide:true
                    },{
                        name: "confidence",
                        label: "Confidence",
                        type: ["const","input"],
                        editable: true,
                        optional: true,
                    },{
                        name: "magnitude",
                        label: "Magnitude",
                        type: ["const","input"],
                        editable: true,                        
                        optional: true,
                    },{
                        name: "attributes",
                        label: "Attributes",
                        type: ["map"],
                        editable: true,                        
                        optional: true,
                    }
                ]
            },{
                name: "attributes",
                label: "Attributes",
                type: "array",
                editable: true,
                arrayItems:[
                    {
                        name: "name",
                        label: "Name",
                        type: ["const","input"],
                        editable: true,
                    },{
                        name: "value",
                        label: "Value",
                        type: ["const","input"],
                        editable: true,
                    },{
                        name: "confidence",
                        label: "Confidence",
                        type: ["const","input"],
                        editable: true,
                        optional: true,
                    },{
                        name: "magnitude",
                        label: "Magnitude",
                        type: ["const","input"],
                        editable: true,
                        optional: true,
                    },{
                        name: "data_type",
                        label: "Data Type",
                        type: ["const","input"],
                        editable: true,
                        optional: true,
                    }

                ]
            }
        ]        
    },

];
export default Fields;