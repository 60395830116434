<template>
	<div class="px-3" style="height: 70vh; overflow-y: scroll">
		<yaml-form-builder
			:fields="fields"
			v-model="inputVal"
			:editMode="editMode"
		></yaml-form-builder>
	</div>
</template>

<script>
	import YAML from "yaml";
	import YamlFormBuilder from "./YamlFormBuilder.vue";
	import Fields from "../utils/field-constants/idrEdgeExtractYaml";
	export default {
		data() {
			return {
				fields: Fields,
			};
		},
		components: { YamlFormBuilder },
		computed: {
			inputVal: {
				get() {
					return YAML.parse(this.modelValue);
				},
				set(val) {
					this.$emit("update:modelValue", YAML.stringify(val));
				},
			},
		},
		props: {
			modelValue: [Object, String],
			editMode: {
				type: Boolean,
				default: true,
			},
		},
		emits: ["update:modelValue", "change"],
	};
</script>

<style></style>
