<template>
	<div class="modal-card card">
		<form @submit.prevent="onAddHandler()">
			<div class="card-header">
				<!-- Title -->
				<h4 class="card-header-title">Add Handler</h4>
				<!-- Close -->
				<button
					type="button"
					class="btn-close"
					data-bs-dismiss="modal"
					aria-label="Close"
				></button>
			</div>
			<div class="card-body overflow-visible">
				<form-fields :fields="fields" v-model="handler" />
				<JobTemplateSelection  v-on:setCurrentTemplate="setCurrentTemplate" :jobTemplates="jobTemplates" v-model="dynamicFields"></JobTemplateSelection>
				<div v-if="error" class="text-danger">
					{{ error }}
				</div>
			</div>
			<div class="card-footer">
				<div class="text-end">
					<submit-button
						:fields="fields"
						v-model="handler"
						:loading="loading"
					/>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	//handlerFields
	import formFields from "../FormFields.vue";
	import submitButton from "../SubmitButton.vue";
	import { mapActions, mapState } from "vuex";
	import getSandboxJobFields from "../../utils/field-constants/sandboxJobFields";
	import JobTemplateSelection from '../JobTemplateSelection.vue'
	import macros from '../../utils/macros';
	export default {
		components: { formFields, submitButton ,JobTemplateSelection},
		data() {
			return {
				error: "",
				handler: {},
				fields: [],
				loading: false,
				templateName:null,
				currentTemplate: null,
				dynamicFields: {}
			};
		},
		name: "AddHandler",
		created() {
			this.fields = getSandboxJobFields();
			this.initMethod();
		},
		computed: {
			...mapState({
				jobKinds: (state) => state.amdp.jobKinds,
			}),
			jobTemplates() {
				if(this.handler.kind) {
					return this.$store.state.admin.jobTemplates.filter((template) => {
						return template.type == this.handler.kind.toLowerCase()

					});
				}
				return [];
				
			},
		},		
		props: {
			modalData: {
				type: [Object, Array, String],
				default: "",
			},
		},
		methods: {
			...mapActions({
				createSandboxJob: "sandbox/createSandboxJob",
				fetchJobKinds: "amdp/fetchJobKinds",
			}),
			setCurrentTemplate(val) {
				this.currentTemplate = val;
			},
			async onAddHandler() {
				this.loading = true;
				if (this.currentTemplate) {					
					let system_files =  JSON.parse(
						this.currentTemplate?.system_files
					);
                    let user_files =  JSON.parse(
						this.currentTemplate?.user_files
					);
					this.handler.system_files = this.dynamicFields?macros.replaceDynamicFields(system_files, this.dynamicFields):system_files;
					this.handler.user_files = this.dynamicFields?macros.replaceDynamicFields(user_files, this.dynamicFields):user_files;				
				} else {
					let system_files = [];
					let jobKindDetailsArr = this.jobKinds?.filter(
						(jobKind) =>
							jobKind.name == this.handler.kind?.toLowerCase()
					);
					let requiredFiles = jobKindDetailsArr[0]?.required_files;
					requiredFiles.map((file) => {
						system_files.push({
							content: " ",
							relative_path: file,
						});
					});
					this.handler.system_files = system_files;
					this.handler.user_files = [
						{ content: "", relative_path: "handler.go" },
					];
				}
				try {
					await this.createSandboxJob(this.handler);
				} catch (error) {
					this.loading = false;
					if (error.response.data.error)
						this.error = error.response.data.error;
					else this.error = error.message;

					return;
				}
				this.loading = false;
				this.$store.dispatch("hideModal");

				this.$router.push({
					name: "aIO Handler Configuration",
					params: {
						sandboxId: `${this.$store.state.sandbox.currentSandbox}`,
						jobId: `${this.handler.name}`,
					},
				});
			},			
			
			initMethod() {
				let self = this;
				const currentSandboxObj =
					this.$store.state.sandbox.sandboxes.filter(
						(sandbox) =>
							sandbox.name ===
							self.$store.state.sandbox.currentSandbox
					);				

				this.fields = this.fields.map((field) => {
					if (field.name == "kind") {						
							field.options = [
								{
									label: "AIO-HANDLER-DBDS",
									value: "AIO-HANDLER-DBDS",
								},
								{
									label: "AIO-HANDLER-GRAPHDS",
									value: "AIO-HANDLER-GRAPHDS",
								},
							];
						
					}
					return field;
				});
			},
		},		
		beforeMount() {
			this.$store.dispatch("admin/fetchJobTemplates");
			if (!this.jobKinds.length) {
				this.fetchJobKinds();
			}
			
		},
	};
</script>

<style></style>
