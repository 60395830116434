<template>
    <div class="modal-card card">
        <form @submit.prevent="onCreateSandboxJob()">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">
                {{ title }}
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body overflow-visible">
            <form-fields :fields="jobFields" v-model="job" />                  
            <JobTemplateSelection  v-on:setCurrentTemplate="setCurrentTemplate" :jobTemplates="jobTemplates" v-model="dynamicFields"></JobTemplateSelection>
            <div v-if="error" class="text-danger">
                {{error}}
            </div>

        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="jobFields" v-model="job"  :loading="loading" />
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import JobTemplateSelection from '../JobTemplateSelection.vue'
import { mapActions, mapState } from 'vuex'
import getSandboxJobFields from "../../utils/field-constants/sandboxJobFields"
import macros from '../../utils/macros';
export default {
    components: { formFields, submitButton , JobTemplateSelection},
    data(){        
        return {
            error: "",
            job: {},            
            jobFields: [],
            loading:false,
            currentTemplate: null,
            title:"Create Job",
            dynamicFields: {}
        }
    },
    name: "AddSandboxJob",  
    created(){        
        this.jobFields = getSandboxJobFields();  
        this.initMethod();      
    }, 
    
    props: {
        modalData: {
            type: [Object, Array, String],
            default: ""
        }
    },
    computed: {
        ...mapState({
            jobKinds: (state) => state.amdp.jobKinds,
        }),
        jobTemplates() {
            if(this.job && this.job.kind) {
                return this.$store.state.admin.jobTemplates.filter(
                    (template) =>
                        template.type == this.job.kind.toLowerCase()
                );
            } else {
                return null;
            }            
        },
        sandboxExcludeList() {
            return this.$store.state.sandbox.sandboxExcludeList.amdp;
        }
    }, 
    beforeMount() {
        this.$store.dispatch("admin/fetchJobTemplates");
        if (!this.jobKinds.length) {
				this.fetchJobKinds();
			}
        if(this.modalData.kind)
            this.job.kind = this.modalData.kind;

    },
    methods: {
        ...mapActions("sandbox", {
            createSandboxJob: "createSandboxJob",
            fetchJobKinds: "amdp/fetchJobKinds"
        }),
        setCurrentTemplate(val) {
            this.currentTemplate = val;
        },
        async onCreateSandboxJob() {  
            this.loading = true; 
            let self = this;
            if (this.currentTemplate) {
                    let system_files =  JSON.parse(
						this.currentTemplate?.system_files
					);
                    let user_files =  JSON.parse(
						this.currentTemplate?.user_files
					);
                    
                    this.job.system_files = this.dynamicFields?macros.replaceDynamicFields(system_files, this.dynamicFields):system_files;
					this.job.user_files = this.dynamicFields?macros.replaceDynamicFields(user_files, this.dynamicFields):user_files;				
				} else {
					let system_files = [];
					let jobKindDetailsArr = this.jobKinds?.filter((jobKind) =>	jobKind.name == this.job.kind?.toLowerCase());
					let requiredFiles = jobKindDetailsArr.length?jobKindDetailsArr[0].required_files:["config.yaml"]
					requiredFiles.map((file) => {
						system_files.push({
							content: " ",
							relative_path: file,
						});
					});
					this.job.system_files = system_files;					
				}          
            try {
                await this.createSandboxJob(this.job)
            } catch (error) {
                this.loading = false;                     
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message
                
                return
            }
            this.loading = false;     
            this.$store.dispatch('hideModal'); 
            await this.$store.dispatch("sandbox/fetchSandboxJobs");
            this.$router.push({
                    name: "Sandbox Job Configuration",
                    params: {
                        sandboxId: `${this.$store.state.sandbox.currentSandbox}`,
                        jobId: `${this.job.name}`
                    },
                }); 
           
            
        },       
        initMethod() {
			let self = this;
            const currentSandboxObj = this.$store.state.sandbox.sandboxes.filter((sandbox => sandbox.name === self.$store.state.sandbox.currentSandbox));            
			let jobTypeOptions = [];
			if(currentSandboxObj[0] && currentSandboxObj[0].enabled_job_kinds && currentSandboxObj[0].enabled_job_kinds.length) {
                const filteredJobKinds = currentSandboxObj[0].enabled_job_kinds.filter((jobKind) => !this.sandboxExcludeList.includes(jobKind.toUpperCase()));
				filteredJobKinds.map((jobKind) => {
                    if(jobKind === "*") {
                        jobTypeOptions = [
                            { label: "Connector", value: "CONNECTOR" },
                            { label: "Collator", value: "COLLATOR" },
                            { label: "Mapper-v2", value: "MAPPER-V2" },
                            { label: "Transform", value: "TRANSFORM" }
                        ];
                    } else {
                        jobTypeOptions.push({
                            label: jobKind,
                            value: jobKind.toUpperCase(),
                        });	

                    }				
					
				});
			}

			this.jobFields = this.jobFields.map((field) => {
				if (field.name == "kind") {
					field.options = jobTypeOptions;
				} 
				return field;
			});
		}
    }
};
</script>

<style>
</style>