<template>
    <div class="container-fluid">
        <nav-header
            :navItems="[
                {
                    name: 'Overview',
                    link: {
                        name: 'Promotion Overview',
                        params: {
                            sandboxId: `${selectedSandbox}`,
                            promotionId: `${$route.params.promotionId}`,
                        },
                    },
                },
                {
                    name: 'Source Files',
                    link: {
                        name: 'Promotion Configuration',
                        params: {
                            sandboxId: `${selectedSandbox}`,
                            promotionId: `${$route.params.promotionId}`,
                        },
                    },
                },
                {
                    name: 'Test History',
                    link: {
                        name: 'Promotion Run History',
                        params: {
                            sandboxId: `${selectedSandbox}`,
                            promotionId: `${$route.params.promotionId}`,
                        },
                    },
                },
                {
                    name:  `${$route.params.executionId}`,
                    link: {
                        name: 'Promotion Execution Details',
                        params: {
                            sandboxId: `${selectedSandbox}`,
                            jobId: ` `,
                            promotionId: `${$route.params.promotionId}`,
                            executionId: ` `

                        },
                    },
                    closeTab: true,
                    disable: true,
                },
                
            ]"
            pretitle="Promotion"
            :breadcrumbTitle="true"
            @nav-close-clicked="closeNav">
            <template v-slot:breadcrumb-title>
                <nav aria-label="breadcrumb" class="header-title">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a @click="closeClick" href="#" class="h1">{{
                                selectedSandbox
                            }}</a>
                        </li>
                        <li class="breadcrumb-item active h1" aria-current="page">
                            {{ $route.params.promotionId }}
                        </li>
                    </ol>
                </nav>
            </template>
            <template v-slot:header-button-content>
                <span v-if="isAccessByUserRole(promotionAdminRoles) && promotion && promotion.status=='SUBMITTED'" class="header-action-btns">
                    <button  @click.prevent="onApproveClick()" class="btn btn-primary">
                    <div
                        v-if="promoteClicked"
                        class="spinner-border spinner-border-sm"
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Accept</button>
                </span>      
                <span v-if="editMode" class="header-action-btns">
                    <button
                        @click.prevent="onSaveClick()"
                        class="btn btn-success">
                        <div
                            v-if="saveClicked"
                            class="spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        
                        Save</button
                    >&nbsp;				
                    <button
                        @click.prevent="onCancelClick()"
                        class="btn btn-secondary"
                    >
                        <i class="fa fa-close"></i></button
                    >&nbsp;
                </span>                  
                <span v-else-if="isAccessByUserRole(promotionAdminRoles) && !editMode && promotion && promotion.status=='IN_PROGRESS'" class="header-action-btns">
                    <button
                    v-if="promotion && promotion.status!=='SUCCEEDED'"
                        type="button"
                        @click.prevent="onEditClick()"
                        class="btn btn-primary"
                    >
                        Edit
                    </button>              
                    <button v-show="promotion && promotion.status=='IN_PROGRESS' && (showCompleteBtnByTest && showCompleteBtn) " @click.prevent="onCompleteClick()" class="btn btn-primary">
                        <div
                            v-if="promoteClicked"
                            class="spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Complete</button>
                    <button v-show="!hideTestBtn && promotion && promotion.status=='IN_PROGRESS' && !showCancelJobBtn" @click.prevent="onRunClick()" class="btn btn-primary">
                        <div
                            v-if="runClicked"
                            class="spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>
                            Test</button>
                    <button  v-if="showCancelJobBtn" @click.prevent="setCancelJob" class="btn btn-primary">
                            Cancel Job</button>                
                </span>
                <span v-if="isAccessByUserRole(promotionAdminRoles) && !editMode && promotion && (promotion.status=='SUBMITTED' || promotion.status=='IN_PROGRESS')" class="header-action-btns">
                    <button  @click.prevent="onPromoteCancelClick()" class="btn btn-danger">
                        <div
                            v-if="cancelClicked"
                            class="spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    Cancel</button>
                </span>
                
                <button @click="closeClick" class="btn btn-secondary lift">
                    <i class="fa fa-arrow-left me-1"></i> Back to promotion list
                </button>
            </template>
        </nav-header>
        <div>
            <router-view v-if ="promotion" 
                :promotion="promotion" 
                :editMode="editMode" 
                :savePromotionTrigger="savePromotionTrigger" 
                :testPromotionTrigger="testPromotionTrigger"
                :getConfigDiffTrigger="getConfigDiffTrigger"
                :cancelJobTrigger="cancelJobTrigger"
                v-on:triggerEditMode="setEditMode"
                v-on:setRunClick="setRunClick"
                v-on:showCancelJob="setShowCancelJob"
                v-on:getPromotionRunList="onGetPromotionRunList"
                v-on:hideTestBtn = "setHideTestBtn"
                v-on:showCompleteBtn = "setShowCompleteBtn"
                v-on:readPromotionDetail = "readPromotionDetail"
                :isPromotion= "true">
            </router-view>
        </div> 
    </div>    
</template>

<script>
import NavHeader from "../../components/NavHeader.vue";
import axios from 'axios';
import { mapActions } from 'vuex';
export default {
    components: { NavHeader },
    data() {
        return {
            selectedSandbox: this.$route.params.sandboxId,
            promotion:null,
            snapshot: null,
            promotionId: this.$route.params.promotionId,
            editMode: false,
            promoteClicked: false,
            cancelClicked: false,
            runClicked: false,
            saveClicked: false,
            promotions: this.$store.state.sandbox.sandboxPromotions,
            savePromotionTrigger:false,
            testPromotionTrigger: false,
            cancelJobTrigger: false,
            showCancelJobBtn: false,
            getConfigDiffTrigger:false,
            promotionAdminRoles:[1,3],
            hideTestBtn: false,
            showCompleteBtn: false
        }
    },
    methods: {
        ...mapActions({            
            showModal: "showModal",
            acceptPromotion: "sandbox/acceptPromotion",
            cancelPromotion: "sandbox/cancelPromotion",
            completePromotion: "sandbox/completePromotion",
        }),
        async readPromotionDetail(prefetchExecutions) {
            let self = this;   
            let response;            
           
            await axios.get(`${self.$store.state.lakeviewUrl}/v1/cids/${self.$store.state.currentClient.cid}/promotions/${self.promotionId}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    }
            }).then((response)=> {   
                self.promotion = response.data;            
                self.snapshot = JSON.parse(JSON.stringify(self.promotion)); 
                if(prefetchExecutions)
                    self.$store.dispatch("sandbox/fetchPromotionExecutions", {promotionId: self.promotionId, limit: self.$store.state.sandbox.testHistoryListLimit});               
            }).catch((error) => {
                this.loading=false
                if (error.response?.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;                
            });           
        },        
        async onApproveClick() { 
            let self = this; 
            this.promoteClicked = true; 
            this.acceptPromotion({promotionId:this.promotionId, sandboxId: this.$route.params.sandboxId})
            .then((data) => {
                self.afterPromotionUpdate();
            }).catch((error) => {
                self.promoteClicked = false;
                if (error.response && error.response.data && error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;

                let toastoptions = {
                    message: self.error || "Something went wrong while accepting promotion. Please contact support.",
                    type: "error"
                }
                self.$store.dispatch('addToast', toastoptions);
                return;
            })
        },
        async onCompleteClick() {          
            let self = this; 
            this.promoteClicked = true; 
            this.completePromotion({promotionId:this.promotionId, sandboxId: this.$route.params.sandboxId})
            .then((data) => {
                self.afterPromotionUpdate();
            }).catch((error) => {
                self.promoteClicked = false;
                if (error.response && error.response.data && error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;

                let toastoptions = {
                    message: self.error || "Something went wrong while completing promotion. Please contact support.",
                    type: "error"
                }
                self.$store.dispatch('addToast', toastoptions);
                return;
            })
        },
        onPromoteCancelClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Cancel Promotion",
                    message: `Are you sure you want to cancel promotion ${self.promotionId}?`,
                    async confirm() {
                        try {
                            self.cancelClicked = true;
                            await self.cancelPromotion({promotionId:self.promotionId, sandboxId: self.$route.params.sandboxId})
                            self.afterPromotionUpdate();
                        } catch (error) {
                            self.cancelClicked = true;
                            if (error?.response?.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;

                            let toastoptions = {
                                message: self.error || "Something went wrong while cancelling promotion. Please contact support.",
                                type: "error"
                            }
                            self.$store.dispatch('addToast', toastoptions);
                            return;
                        }
                    },
                },
            });           
        },
        afterPromotionUpdate() {
            this.readPromotionDetail(false);                
            this.$store.dispatch('hideModal');                
            this.promoteClicked = false;
            this.getConfigDiffTrigger = !self.getConfigDiffTrigger;
        },           
        closeNav(navName) {
            this.$router.push({
                name: "Promotion Run History",
                params: {
                    sandboxId: `${this.$route.params.sandboxId}`,
                    promotionId: `${this.$route.params.promotionId}`,
                },
            });           
        },
        closeClick() {
            this.$router.push({
                name: "Promotions",
                params: {
                    sandboxId: `${this.$route.params.sandboxId}`,
                },
            });
        },
        onEditClick() {
            this.setEditMode(true);
        },
        setEditMode(mode) {
            this.saveClicked= false;
            this.editMode = mode;           
        },
        onCancelClick() {
            this.promotion = { ...this.snapshot };
            this.setEditMode(false);
        },
        onSaveClick() {
            this.saveClicked = true;
            this.savePromotionTrigger = !this.savePromotionTrigger;
        },
        onRunClick() {
            this.runClicked = true;
            this.showCancelJobBtn = true;
            this.testPromotionTrigger = !this.testPromotionTrigger;
        },
        onCancelJobClick() {
            this.showCancelJob = true;
            this.testPromotionCancel = !this.testPromotionCancel;
        },
        setCancelJob() {
            this.cancelJobTrigger = !this.cancelJobTrigger;
        },
        setShowCancelJob(showCancelJob) {
            this.showCancelJobBtn = showCancelJob;
        },
        setRunClick(runClick) {
            this.runClicked = runClick;
        },
        isAccessByUserRole(roles) {
            return this.$store.getters.userRoles.isAccessByRole(roles);
        },
        onGetPromotionRunList() {
            this.$store.dispatch("sandbox/fetchPromotionExecutions", { promotionId: this.promotionId });
        },
        setHideTestBtn(bool){
            this.hideTestBtn = bool;
        },
        setShowCompleteBtn(bool) {
            this.showCompleteBtn = bool;
        }
    },
    computed: {
        showCompleteBtnByTest() { 
            if(this.promotion.new_job) {
                if(!this.$store.state.sandbox.promotionExecutions.length) 
                return false;
            else
                return (this.$store.state.sandbox.promotionExecutions[0].status === "SUCCEEDED");          

            }else {
                return true;
            }            
        }
    },
    async beforeMount() {
        this.readPromotionDetail(true);
    }    
}
</script>

<style scoped>
.header-action-btns .btn{
    margin-right: 0.5em !important;
}

</style>