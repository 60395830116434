<template>
  <loader v-if="$store.state.loading" />
  <router-view></router-view>
</template>

<script>
import Loader from './components/Loader.vue'

export default {
  name: 'App',
  components: {
    Loader
  }
}
</script>

<style lang="scss">
//Dashkit theme
@import './theme/scss/theme.scss';

//Codemirror
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/material.css';
@import '~codemirror/theme/mdn-like.css';
@import '~codemirror/theme/eclipse.css';
@import '~codemirror/addon/lint/lint.css';
@import '~codemirror/addon/dialog/dialog.css';

</style>
