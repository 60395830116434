<template>
    <div v-for="(item, key, index) in inputVal" :key="index" class="input-group">
        <div class="input-group-prepend">
            <label class="col-form-label text-capitalize">{{ key }}:</label>
        </div>
        <input type="text" class="form-control" :value="inputVal[key] || 'null'" :disabled="!editable"
            :readonly="!editable" />
    </div>
</template>
  
<script>
export default {
    computed: {
        inputVal() {
            return this.modelValue ? this.modelValue : {};
        }
    },
    props: {
        modelValue: {
            type: Object,
            default: () => { },
        },
        editable: {
            type: Boolean,
            default: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["update:modelValue", "change"],
};
</script>
  
<style></style>
  