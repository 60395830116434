<template>
    <detail-header
        pretitle="aqfer Universal Tag"
        :title="$route.params.hostName"
        :editable="false"
        :editMode="editMode"
        :customMode="true"
        customModeText="Add Domain Record"
        :customModeRoles="btnRoles"
        @customClick="onAddDomainEntree"
        @closeClick="$router.push({name: 'Configure Hosts'})"
    />

    <div class="container-fluid">         
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <datatable
                        tableId="configureList"
                        :columns="columns"
                        uniqueField="name"
                        :data="cNameList"
                        :compact="true"
                        :deletable="canEdit"
                        :showLoading="loading"
                        @deleteRows="DeleteDomainEntries"
                        :selectCheckboxes="canEdit"
                    />
                </div>
            </div>
        </div>
    </div>   
</template>

<script>
import DetailHeader from '../../components/DetailHeader.vue';
import Datatable from "../../components/Datatable.vue";
import { mapActions, mapState } from "vuex";
export default {
    components: { Datatable, DetailHeader },
    data() {
        return {
            columns: [
                { data: "name", title: "CName" },
                { data: "status" },
                { data:"recordType", title:"Type" }
            ],
            edgeHostName: this.$route.params.hostName,
            editMode: false,
            btnRoles: [ 1,3 ],
            loading: true
        };
    },
    computed: {
     ...mapState({ 
            cid: state => state.currentClient.cid,
            edgeHostObj: state => state.aut.currentEdgeHostNameObj
        }), 
        cNameList() {
            const currentEdgeHostName = this.$store.state.aut.currentEdgeHostNameObj;
            return currentEdgeHostName.domains?currentEdgeHostName.domains.items:null;
        },
        canEdit() {
            return this.$store.getters.userRoles.isClientAdmin || this.$store.getters.userRoles.isAqferAdmin;
        },   
    }, 
   
    methods: {
        ...mapActions({
            showModal: "showModal",
            getDomainListObject: "aut/getDomainListObject"
        }),
        onAddDomainEntree() {
            this.showModal({ component: 'addEdgeHost', data: JSON.parse(JSON.stringify(this.edgeHostObj) )});
        },
        DeleteDomainEntries(rows) {
            const deleteRowNameArr = rows.map(row => {
                return row.name;
            });
            const filteredDomains = this.edgeHostObj.domains.items.filter(item => {
                return !deleteRowNameArr.includes(item.name);
            });

            const edgeHostObjCopy = JSON.parse(JSON.stringify(this.edgeHostObj));

            edgeHostObjCopy.domains.items = filteredDomains;
            this.showModal({ component: "deleteDomainRecords", data: {edgeHostObj: edgeHostObjCopy, rows: rows }});
        }
        
    },
    async beforeMount() {
        this.loading = true;     
        await this.$store.dispatch("aut/getDomainListObject", this.edgeHostName);    
        this.loading = false;    
    }   
}
</script>

<style>

</style>