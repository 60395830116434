<template>
  <detail-header
        pretitle="Public Tag Templates"
        :title="tagTemplate ? tagTemplate.name : '&nbsp;'"
        :editable="false"
        :customMode="true"
        customModeText="Copy To My Library"
        :editMode="editMode"
        @customClick="onCopyToMyLibrary"      
        @closeClick="$router.push(`/${cid}/aut/browse-tag-templates`)"
    />
    <tag-template v-if="tagTemplate" :editMode="editMode" v-model="tagTemplate" ></tag-template>
</template>

<script>
import DetailHeader from "../../components/DetailHeader.vue";
import TagTemplate from "../../components/aut/TagTemplate.vue";
import { mapActions, mapState } from "vuex";
export default {
    components: { DetailHeader,TagTemplate },
    data() {
        return {
            editMode: false,
            snapshot: null,
            tagTemplate: null,
            error: ""
        }

    },
    beforeMount() {
        this.fetchTagTemplate();        
    },
    computed: {
        ...mapState({ 
            cid: state => state.currentClient.cid,
        })       
    },
    watch: {
        cid(val) {
            this.$router.push(`/${val}/aut/browse-tag-templates`);
        },
    },
    methods: {
        ...mapActions({            
            readPublicTagTemplate: "aut/readPublicTagTemplate",           
            showModal: "showModal",
        }),       
        onCopyToMyLibrary() {
            this.showModal({component:"CopyTemplateToMyLibrary", data: this.tagTemplate});
        },  
        async fetchTagTemplate() {
            let response={};
            if(!this.$route.params.data){
                try {
                    response = await this.readPublicTagTemplate(this.$route.params.id);
                } catch (error) {
                    if (error.response.data.error)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
            }else{
                response["data"] = JSON.parse(this.$route.params.data)
            }
            response.data.tag = response.data.tag ?response.data.tag.split(","):[]
            this.tagTemplate = await response.data;
            this.snapshot = { ...this.tagTemplate };
        }
    }

}
</script>

<style>

</style>