<template>
  <input
    class="form-control mr-sm-2"
    type="text"
    v-model="searchQuery"
    placeholder="Search"
    v-if="list && list.length > 5"
  />
  <ul class="list-group" v-if="list && list.length">
    <a
      v-for="(listItem, index) in paginatedData"
      :key="listItem.index"
      :id="`${listItem[listItemKey]}-${listItemKey}`"
      class="list-group-item d-flex d-flex justify-content-start flex-row align-items-center"
      :class="[
        activeListGroupItem == `${listItem[listItemKey]}` ? 'active' : '',
      ]"
      @click="selectBtnclick(listItem)"
      @contextmenu.stop.prevent="toggleActionContextMenu($event, listItem)"
      @mouseover="hoverListItem = `${listItem[listItemKey]}`"
      @mouseleave="resetActiveGroupActionListItem()"
    >
      <span
        :class="{
          invisible: !(activeListGroupItem == `${listItem[listItemKey]}`),
        }"
        class="pe-2"
        style="color: grey"
      >
        <span class="icon"> <i class="fa fa-regular fa-eye"></i></span
      ></span>
      <span
        class="d-flex flex-column"
        v-if="listItemTitle"
        v-html="listItemTitle(listItem)"
      ></span>
      <span v-else class="mr-auto">{{
        listItem[listItemKey] || listItem
      }}</span>
      <span v-show="editMode && orderList" class="ms-auto">
        <span
          class="btn-link"
          :class="index==paginatedData.length-1?'disabled':''"
          @click.stop.prevent="
            reOrderList(
              listItem,
              (currentPage - 1) * itemsPerPage + index,
              (currentPage - 1) * itemsPerPage + index + 1
            )
          "
        >
          <span class="icon">
            <i class="fa fa-arrow-down"></i>
          </span>
        </span>
        <span
          class="btn-link"
          :class="index==0?'disabled':''"
          @click.stop.prevent="
            reOrderList(
              listItem,
              (currentPage - 1) * itemsPerPage + index,
              (currentPage - 1) * itemsPerPage + index - 1
            )
          "
        >
          <span class="icon">
            <i class="fa fa-arrow-up" ></i>
          </span>
        </span>
      </span>     
      <span v-show="editMode"
            v-if="!actionDropdown"
            @click.stop.prevent="deleteBtnClick(listItem)"
            class="btn"
            
      >
      <span class="icon"><i class="fa fa-regular fa-trash-can"></i></span>
       </span>      
      <div
        v-show="editMode"
        v-if="btnList.length > 0 && actionDropdown"
        :class="[!contextMenuOn ? 'dropdown' : '', 'actionDropdown']"
      >
        <a
          href="#"
          class="icon"
          role="button"
          @click.stop.prevent="onClickActionDropdown($event, listItem)"
          aria-haspopup="true"
          :aria-expanded="activeListGroupItem == `${listItem[listItemKey]}`"
        >
          <i class="fa fa-ellipsis-v"></i>
        </a>
        <ul
          v-if="activeGroupActionListItem == `${listItem[listItemKey]}` && hoverListItem == `${listItem[listItemKey]}`"
          :class="[
            activeGroupActionListItem == `${listItem[listItemKey]}`
              ? 'show'
              : '',
            'collapse dropdown-menu ',
          ]"
          :style="[
            contextMenuOn
              ? { top: dropdownTop + 'px', left: dropdownLeft + 'px' }
              : {},
          ]"
        >
          <a
            v-for="btn in btnList"
            :key="btn.id"
            class="dropdown-item"
            :class="[(list[0][listItemKey] == `${listItem[listItemKey]}`)?'list-item-1':(list[list.length-1][listItemKey] == `${listItem[listItemKey]}`?'list-item-last':''),`${btn.class}`]"
            @click.stop.prevent="
              btn.onClick(listItem, (currentPage - 1) * itemsPerPage + index)
            "
          >
            {{ btn.name }}
          </a>
        </ul>
      </div>
    </a>
  </ul>

  <div class="row" v-if="list && list.length >= 5">
    <div class="col-sm-12 col-md-12 mb-3 d-flex justify-content-center">
      <label>
        Show
        <select
          class="form-select form-select-sm"
          v-model.number="itemsPerPage"
          style="display: inline-block; width: auto"
        >
          <option
            v-for="option in itemsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>

          <option key="all" :value="list.length">All</option>
        </select>
        entries
      </label>
    </div>
    <div class="col-sm-12 col-md-12 d-flex justify-content-center">
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li class="page-item" :class="currentPage === 1 ? 'disabled' : ''">
            <a class="page-link" href="#" @click="prevPage"
              ><span aria-hidden="true">&laquo;</span></a
            >
          </li>
          <li
            v-for="page in pages"
            :key="page"
            class="page-item"
            :class="currentPage === page ? 'active' : ''"
          >
            <a class="page-link" @click="setPage(page)">{{ page }}</a>
          </li>
          <li
            class="page-item"
            :class="currentPage === totalPages ? 'disabled' : ''"
          >
            <a class="page-link" @click="nextPage">
              <span aria-hidden="true">&raquo;</span></a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      maxVisiblePages: 4,
      searchQuery: "",
      itemsPerPageOptions: [5, 10, 25, 50],
      expandDropdown: false,
      dropDownToggle: false,
      activeGroupActionListItem: null,
      hoverListItem: null,
      dropdownTop: 0,
      dropdownLeft: 0,
      contextMenuOn: true,
    };
  },
  computed: {
    btnList() {
      return [
        {
          class: "move-to-top",
          name: "Move to top",
          visible: this.editMode,
          onClick: (listItem, currentIndex) =>
            this.moveToTopOfList(listItem, currentIndex),
        },
        {
          class: "move-to-bottom",
          name: "Move to bottom",
          visible: this.editMode,
          onClick: (listItem, currentIndex) =>
            this.moveToBottomOfList(listItem, currentIndex),
        },
        {
          class: "delete",
          name: "Delete",
          visible: this.editMode,
          onClick: (listItem) =>
            this.deleteBtnClick(listItem)
        },
      ];
    },
    itemsPerPage: {
      get() {
        const itemsPerPage = this.$store.state.paginationCache[this.id]
          ? this.$store.state.paginationCache[this.id].itemsPerPage
          : 5;
        return itemsPerPage ? itemsPerPage : 5;
      },
      set(val) {
        const paginationCache = this.$store.state.paginationCache;
        paginationCache[this.id] = { itemsPerPage: val };
        this.$store.dispatch("setPaginationCache", paginationCache);
      },
    },
    activeListGroupItem: {
      get() {
        let currentIndex = this.list.findIndex(
          (listItem) => listItem[this.listItemKey] == this.activeListItem
        );
        currentIndex = currentIndex > -1 ? currentIndex + 1 : 1;
        this.setPage(Math.ceil(currentIndex / this.itemsPerPage));
        return this.activeListItem;
      },
      set(val) {},
    },
    filteredList() {
      let self = this;
      if (this.searchQuery) {
        return this.list.filter((item) => {
          if (self.listItemTitle)
            return self
              .listItemTitle(item)
              .toLowerCase()
              .includes(self.searchQuery.toLowerCase());
          else if (self.listItemKey)
            return item[self.listItemKey]
              .toLowerCase()
              .includes(self.searchQuery.toLowerCase());
          else
            return item.toLowerCase().includes(self.searchQuery.toLowerCase());
        });
      } else {
        return this.list;
      }
    },
    totalPages() {
      return Math.ceil(this.filteredList.length / this.itemsPerPage);
    },
    paginatedData() {
      if (this.currentPage > this.totalPages) this.setPage(this.totalPages);

      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.filteredList.slice(start, end);
    },
    pages() {
      let start = this.currentPage - Math.floor(this.maxVisiblePages / 2);
      start = Math.max(start, 1);
      let end = start + this.maxVisiblePages - 1;
      end = Math.min(end, this.totalPages);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  props: {
    id: {
      type: String,
      default: "list-view",
    },
    list: [Array],
    editMode: Boolean,
    title: String,
    activeListItem: String,
    listItemKey: {
      type: String,
      default: null,
    },
    orderList: {
      type: Boolean,
      default: false,
    },
    listItemTitle: [String, Function],
    actionDropdown: {
        type: Boolean,
        default: false
    }
  },
  methods: {
    deleteBtnClick(listItem) {
      this.$emit("deleteBtnClick", listItem);
    },
    selectBtnclick(listItem) {
      this.activeListGroupItem = this.listItemKey
        ? listItem[this.listItemKey]
        : listItem;
      this.$emit("selectBtnclick", listItem);
    },
    reOrderList(listItem, currentIndex, newIndex) {
      this.activeListGroupItem = this.listItemKey
        ? listItem[this.listItemKey]
        : listItem;
      this.$emit("reOrderList", listItem, currentIndex, newIndex);
      this.resetActiveGroupActionListItem();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setPage(n) {
      this.currentPage = n ? n : 1;
    },
    toggleActionContextMenu(event, listItem) {
      this.contextMenuOn = true;
      let bodyOffsets = event.currentTarget.getBoundingClientRect();
      this.dropdownTop = event.clientY - bodyOffsets.top;
      this.dropdownLeft = event.clientX - bodyOffsets.left;
      this.toggleActionDropdown(listItem);
    },
    onClickActionDropdown(event, listItem) {
      this.contextMenuOn = false;
      this.dropdownTop = 0;
      this.dropdownLeft = 0;
      this.toggleActionDropdown(listItem);
    },

    toggleActionDropdown(listItem) {
      this.activeGroupActionListItem = listItem[this.listItemKey];
    },
    moveToTopOfList(listItem, currentIndex) {
      this.reOrderList(listItem, currentIndex, 0);
      this.resetActiveGroupActionListItem();
    },
    moveToBottomOfList(listItem, currentIndex) {
      this.reOrderList(listItem, currentIndex, this.list.length);
      this.resetActiveGroupActionListItem();
    },
    resetActiveGroupActionListItem() {
      this.activeGroupActionListItem = null;
    },
  },
  emits: ["deleteBtnClick", "selectBtnclick", "reOrderList"],
};
</script>

<style scoped>
.actionDropdown .dropdown-menu {
  position: absolute;
}
.list-group-item {
  background-color: #edf2f9;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d2ddec !important;
  cursor: pointer;
}
.list-group-item.active {
  z-index: 2;
  color: #283e59;
  background-color: #ffffff;
  cursor: default;
}
.list-group {
  padding-bottom: 0.5rem;
}
.mr-auto {
  margin-right: auto !important;
}
.list-group i.fa:hover {
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0 0 2px #888;
  background-color: #ffffff;
  cursor: pointer;
}
.list-group-item .list-item-1.move-to-top {
  display: none;
}
.list-group-item .list-item-last.move-to-bottom {
  display: none;
}

.btn-link.disabled {
    pointer-events: none !important;
}

</style>

