<template>
    <div class="modal-card card">
        <div class="card-header">
            <!-- Title -->
            <h4 class="card-header-title">Import Named Query</h4>
            <!-- Close -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <InputField type="object" v-model="query"  label="Paste the Query JSON"
                        placeholder="Enter the query json"  inputType="json" :editable="true"/>
                </div>
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
            </div>
        </div>
        <div class="card-footer text-end">
            <button data-bs-dismiss="modal" class="btn btn-secondary" aria-label="Close">
                Close</button>&nbsp;
            <button class="btn btn-primary" :disabled="btnDisable" @click="onProceed()">
                <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div v-else>
                    Proceed
                </div>
            </button>&nbsp;
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import InputField from '../InputField.vue';
import { isDuplicate } from '../../utils/commonFunction';
export default {
    data() {
        return {
            loading: false,
            query:{ "Name":"","Description": "","QueryString": "","Params": []},
            btnDisable:true,
            error:null,
            parsedQuery:{}
        }
    },
    components: { InputField },
    props: {
        modalData: {
            type: Object
        },
    },
    watch:{
        query(newVal){
            try {
                let parsedQuery=JSON.parse(JSON.stringify(newVal));
                const regex= new RegExp("^[a-zA-Z0-9_]+$");
                if(parsedQuery.Name && parsedQuery.Name!="" && 
                    parsedQuery.Description && parsedQuery.Description!="" && 
                    parsedQuery.QueryString && parsedQuery.QueryString!=""){
                        if(isDuplicate(parsedQuery.Name, this.$store.state.amdp.queries, "Name", null)) {
                            this.error ="Already '" + parsedQuery.Name + "' Query name exits.";
                            this.btnDisable=true;
                        }else if(!(regex).test(parsedQuery.Name)){  
                            this.error ="Query name may contain only alphabets or numbers.";
                            this.btnDisable=true;
                        }else{
                            this.btnDisable=false;  
                            this.error= null; 
                            this.parsedQuery = parsedQuery
                        }
                }
                else{
                    this.error ="JSON must contain Name, Description, QueryString";
                    this.btnDisable=true;
                }
                
            } catch (e) {
                this.btnDisable= true;
            }
        }
    },
    methods: {
        ...mapActions({
            showModal: "showModal"
        }),
        onProceed(){
            this.$store.dispatch('hideModal');  
            //opening the import query in edit page in id import_new, there add operation is performed 
            this.$router.push({ name: 'Named Query', params: {id:'import_new', data: JSON.stringify(this.query)}} );
        }
    }

}
</script>

<style></style>