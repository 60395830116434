<template>
    <div class="modal-card card">
        <form @submit.prevent="onDetachSandboxCredentials()">
            <div class="card-header">
                <h4 class="card-header-title"> Detach Sandbox Credentials</h4>
                <button 
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    >
                </button>
            </div>
            <div class="card-body">
                <p> Are you sure you want to detach the following Sandbox credentials ?</p>
                <ul class="list-group list-group-sm">
                    <li v-for="item in modalData.rows" :key="item.index" class="list-group-item">
                        {{ item.client_credential_name }}
                    </li>
                </ul>
            </div>
            <div class="card-footer text-end">
                <button class="btn btn-danger">
                    <div
                        v-if="deleteInProgress"
                        class="spinner-border spinner-border-sm">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div v-else>
                        <slot>Detach</slot>
                    </div>
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default{
    name:"DetachSandboxCredentials",
    data(){
        return{
            error:null,
            deleteInProgress: false,
            sandboxName: this.modalData.sandboxName,
            credNames: this.modalData.credNames
        }
    },
    props:{
        modalData: {
            type:[Object, Array, String],
        }
    },
    methods: {
        ...mapActions({
            upsertSandboxCredential: "amdp/upsertSandboxCredential",
            getSandboxCredentials: "amdp/getSandboxCredentials"
        }),
        async onDetachSandboxCredentials(){
            this.deleteInProgress = true;
           let toastoptions;
            try{
                await this.upsertSandboxCredential({sandboxName: this.sandboxName, payload:this.credNames})
                toastoptions = {
                    message: "Sandbox Credentials detached successfully.",
                    type: "success"
                }
            } catch( error ){
                this.error = error.message;
                toastoptions = {
                    message: "Something went wrong when detaching sandbox credential. Please contact support."
                }
            } finally {
                this.deleteInProgress = false;
            }
            this.$store.dispatch("hideModal");
            this.$store.dispatch('addToast', toastoptions);
            this.getSandboxCredentials(this.sandboxName);
        }
    }
}
</script>
<style>
</style>