<template>
    <div class="row mt-4" v-show="chartData.length">
        <template v-for="(item) in chartData" :key="item.index" >
            <div class="col-md-6" v-if="Object.keys(item.chartData).length">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-header-title">{{ item.description }}</h2>
                    </div>
                    <div class="card-body">
                        <div>
                            <canvas :ref="`chart${type + item.name}`" :id="`chart${type + item.name}`" class="chart-canvas"
                                width="843" height="400" style="
                                            display: block;
                                            box-sizing: border-box;
                                            height: 400px;
                                            width: 843px;
                                        ">
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>

</template>
<script>
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    Utils
} from 'chart.js';


Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

export default {
    props: {
        monthlyData: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            charts: [],
        }
    },
    watch: {
        monthlyData(value) {
            this.charts.map((mychart)=>{if(mychart) mychart.destroy();})
            this.$nextTick(() => {
                this.drawCharts()
            });
        }
    },
    computed: {
        chartData () {

            //convert monthly data into chart edible data sets
            let baseEnts = {}
            Object.entries(this.monthlyData).forEach(([key, value], index) => {
                for (const ent of value) {
                    if (!baseEnts[ent.name]) {
                        baseEnts[ent.name] = ent
                        baseEnts[ent.name]['chartData'] = {};
                    }

                    if (ent.sub_entitlements) {
                        for (const subEnt of ent.sub_entitlements) {
                            if(!baseEnts[ent.name]['chartData'][subEnt.name]) {
                                baseEnts[ent.name]['chartData'][subEnt.name] = Array(Object.keys(this.monthlyData).length).fill({"key": key, "value": 0, "usage": 0})
                            }
                            baseEnts[ent.name]['chartData'][subEnt.name][index] = {"key":key, "value": subEnt.consumption, "usage": subEnt.usage}
                        }
                    }
                }
            });

            //convert to array
            var entitlements = []
            for (var key in baseEnts) {
                entitlements.push(baseEnts[key])
            }

            return entitlements
        }
    },
    methods: {
        drawCharts() {
            const horizontalLine = {
                id: 'horizontalLine',
                beforeDraw(chart, args, options) {
                    const ctx = chart.ctx;
                    const yScale = chart.scales['y'];
                    const yValue = options.limit;

                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(chart.chartArea.left, yScale.getPixelForValue(yValue));
                    ctx.lineTo(chart.chartArea.right, yScale.getPixelForValue(yValue));
                    ctx.strokeStyle = options.borderColor;
                    ctx.lineWidth = options.borderWidth;
                    ctx.setLineDash(options.borderDash || []);
                    ctx.lineDashOffset = options.borderDashOffset;
                    ctx.stroke();
                    ctx.restore();
                }
            };

            if (this.chartData.length) {
                for (const usageData of this.chartData) {
                    let datasets = []
                    if (usageData.chartData) {
                        for (const [subkey, subvalue] of Object.entries(usageData.chartData)) {
                            datasets.push({
                                label: subkey,
                                data: subvalue,
                                parsing: {
                                    xAxisKey: 'key',
                                    yAxisKey: 'value'
                                },
                            })
                            
                        }
                    }
                    //Entitlement POOLED CHART
                    const pooledChart = this.$refs['chart' + this.type + usageData.name]
                    const pooledLabels = Object.keys(this.monthlyData)
                    const pooledData = {
                        labels: pooledLabels,
                        datasets: datasets
                    };
                    const pooledConfig = {
                        type: 'bar',
                        data: pooledData,
                        options: {
                            plugins: {
                                title: {
                                    display: false,
                                    text: 'Usage'
                                },
                                tooltip: {
                                    callbacks: {
                                        afterLabel: function(context) {
                                            return "Count: " + context.raw.usage.toLocaleString();
                                        }
                                    }
                                },
                                horizontalLine: {
                                    borderColor: 'red',
                                    borderWidth: 2,
                                    borderDash: [5, 5],
                                    borderDashOffset: 2,
                                    limit: usageData.quantity,
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true
                                }
                            }
                        },
                        plugins: [horizontalLine]
                    };
                    
                    if (pooledChart) {
                        this.charts.push(new Chart(pooledChart, pooledConfig));
                    }
                }

            }
        },

    },
    beforeUnmount(){
        this.charts.forEach(chart => {
            if (chart && typeof chart.destroy === 'function') {
                chart.destroy();
            }
        });
    }
}
</script>
<style></style>