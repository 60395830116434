<template>
   <div class="card">  
        <datatable             
            :tableId="`${sandboxId}-pipelines`" 
            :columns="columns"
            :formatColumn="formatColumn"
            :data="$store.state.sandbox.pipelines"  
            :compact="true" 
            :orderColumn="orderColumn"           
            :detailColumn="{renderBaseUrl: (data) => {
                                                    return `pipelines/${data.name}`;                                                   
                                                },}"
        />
    </div>
</template>

<script>
import Datatable from "../../../components/Datatable.vue";
import { mapActions } from "vuex";
export default {
    components: { Datatable },
    data() {
        return {
            sandboxId: this.$route.params.sandboxId,
            columns: [
                { data: "name"},
                { data: "created_at"},
                { data: "updated_at"}
            ],
            orderColumn: [2, "desc"],
            formatColumn:[
                {
                    index:2,
                    type:"datetime"
                },
                {
                    index:3,
                    type:"datetime"
                }                
            ],
        }
    },
    props: ["selectedSandbox"],
    beforeMount() {   
        this.fetchPipelines(this.sandboxId);
    },    
    methods: {
      ...mapActions({            
            fetchPipelines: "sandbox/fetchPipelines",         
        }),
    }


}
</script>

<style>

</style>