<template>
    <div class="modal-card card">
      <form @submit.prevent="onCreateJobTemplateGroup()">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">Create Job Template Group</h4>
          <!-- Close -->
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="card-body">
          <FormFields :fields="fields" v-model="jobTemplateGroup" />
          <div v-if="error" class="text-danger">
            {{ error }}
          </div>
        </div>
        <div class="card-footer">
          <div class="text-end">
            <SubmitButton :fields="fields" v-model="jobTemplateGroup" :loading="loading" />
          </div>
        </div>
      </form>
    </div>
  </template>
  <script>
  import FormFields from "../FormFields.vue";
  import SubmitButton from "../SubmitButton.vue";
  import { mapActions } from "vuex";
  import Fields from "../../utils/field-constants/jobTemplateGroupFields";

  export default {
    components: { FormFields, SubmitButton },
    data() {
      return {
        error: "",
        jobTemplateGroup: {},
        loading: false,
        defaultFile: "",
        requiredFiles: [],
        fields: Fields
      };
    },
    name: "AddJobTemplateGroup",
    props: {
      modalData: {
        type: [Object, Array, String],
        default: "",
      },
    },
    methods: {
      ...mapActions({
        createJobTemplateGroup: "admin/createJobTemplateGroup",
      }),
      async onCreateJobTemplateGroup() {
        this.loading = true;
        let response = {};
        try {
          let data = JSON.parse(JSON.stringify(this.jobTemplateGroup))
          data.tag = data.tag? data.tag.toString():""
          response = await this.createJobTemplateGroup(data);
        } catch (error) {
          this.loading = false;
          if (error.response.data.error) this.error = error.response.data.error;
          else this.error = error.message;
  
          return;
        }
        this.loading = false;
        this.$store.dispatch("hideModal");
        if (response.data) {
          this.$router.push({
            name: "Job Template Group",
            params: {
              id: `${response.data.id}`,
            },
          });
        }
      }
    },
  };
  </script>
  
  <style></style>
  